import React, { useState, useContext, useEffect, Fragment } from 'react';
import {
    EuiBasicTable, formatDate, EuiTitle, EuiSpacer, EuiProgress, EuiCallOut, EuiLink,
    EuiSelect, EuiFormRow, EuiFlexGroup, EuiFlexItem, EuiText, EuiCheckbox, htmlIdGenerator,
    EuiModalFooter, EuiModalBody, EuiModal, EuiModalHeader, EuiModalHeaderTitle, EuiButton, EuiButtonEmpty, EuiComboBox, EuiFieldText, EuiGlobalToastList, EuiToolTip, EuiTextArea, EuiButtonIcon, EuiPopover, EuiWrappingPopover, EuiEmptyPrompt, EuiImage, EuiBadge
} from '@elastic/eui';
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import useAxios from 'axios-hooks';
import AccountContext from './AccountContext';
// import NurtureImage1 from '../Assets/nurture-email-screenshots-1.gif'
// import NurtureImage2 from '../Assets/nurture-email-screenshots-2.gif'
// import NurtureImage3 from '../Assets/nurture-email-screenshots-3.gif'
import NurtureImage1 from '../Assets/nurturev2_step1.gif'
import NurtureImage2 from '../Assets/nurturev2_step2.gif'
import NurtureImage3 from '../Assets/nurturev2_step3.gif'
import NurtureImage4 from '../Assets/nurture-email-screenshots-4.gif'
import NurtureImage5 from '../Assets/nurture-email-screenshots-5.gif'
import NurtureImage6 from '../Assets/nurture-email-screenshots-6.gif'
import NurtureImage7 from '../Assets/nurture-email-screenshots-7.gif'
import NurtureImage8 from '../Assets/nurture-email-screenshots-8.gif'

const nurtureScreenshots = [NurtureImage1, NurtureImage2, NurtureImage3, NurtureImage4, NurtureImage5, NurtureImage6, NurtureImage7, NurtureImage8]

export default function Nurture() {
    let navigate = useNavigate();
    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const accountContext = useContext(AccountContext)
    const [brandId, setBrandId] = useState(searchParams.get('brandId') || (accountContext?.account?.brands || [])[0]?.brandId || null);
    const onChangeBrand = (e) => {
        setBrandId(e.target.value);
    };

    // Get nurture
    const [{ data: nurtureResults, loading, error }, getNurture] = useAxios(
        {
            url: '/data/nurture',
            method: 'GET'
        },
        { manual: true }
    );


    useEffect(() => {
        setSearchParams({
            brandId
        })

        getNurture({
            params: {
                accountId: params.accountId,
                brandId
            }
        });
    }, [brandId]);

    return (
        <React.Fragment>
            <EuiTitle size="m">
                <h1>Nurture</h1>
            </EuiTitle>
            {
                nurtureResults && nurtureResults.active !== true &&
                <Fragment>
                    <EuiSpacer />
                    <EuiFlexItem grow={false}>
                        <EuiBadge color='#ff0000' style={{ textAlign: "center", color: "white", fontSize: "14px" }}>CRITICAL - all handleit emails will be discarded until the Nurture campaign is made active.</EuiBadge>
                    </EuiFlexItem>
                </Fragment>
            }
            <EuiSpacer />
            <EuiFlexGroup alignItems='center'>
                <EuiFlexItem grow={false}>
                    <EuiFormRow label="Brand">
                        <EuiSelect
                            options={(accountContext?.account?.brands || []).map(b => {
                                return { value: b.brandId, text: b.brandName, disabled: !b.featureLeads }
                            })}
                            value={brandId}
                            onChange={(e) => onChangeBrand(e)}
                        />
                    </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <EuiLink href='https://www.swiftrev.com/guides/nurture'>How do I use this page?</EuiLink>
                </EuiFlexItem>
            </EuiFlexGroup>
            <EuiFlexGroup alignItems='stretch'>
                <EuiFlexItem grow={3}>
                    <div className="sr-alert-light" style={{ height: "100px" }}>
                        <EuiText size='m'><strong>Press Release Nurturing</strong></EuiText>
                        <EuiText size='xs'>Creates awareness for the Press Release platform. Managed by SwiftRev.</EuiText>
                    </div>
                </EuiFlexItem>
                <EuiFlexItem grow={5} >
                    <div className="sr-alert-light" style={{ height: "100px" }}>
                        <EuiText size='m'><strong>Brand-Specific Media Product Nurturing</strong></EuiText>
                        <EuiText size='xs'>Customized to each brand to sell existing media products. <EuiLink onClick={() => { navigate(`/accounts/${accountContext.account.accountId}/brands/${brandId}/settings`) }}>Modify settings here.</EuiLink></EuiText>
                    </div>
                </EuiFlexItem>
            </EuiFlexGroup>
            <EuiFlexGroup alignItems='stretch'>
                <EuiFlexItem grow={3}>
                    <EuiFlexGroup alignItems='stretch'>
                        {(nurtureResults?.nurture || []).slice(0, 3).map((nurtureStepDetails, nurtureStep) => {
                            return (
                                <EuiFlexItem >
                                    <EuiImage style={{ height: 250 }} className='sr-img-light' allowFullScreen src={nurtureScreenshots[nurtureStep]}></EuiImage>
                                    <EuiSpacer size='s' />
                                    <EuiText size='xs' textAlign='center'>Step {nurtureStep + 1}</EuiText>
                                    <EuiText style={{ height: "2rem" }} size='xs' textAlign='center'><strong>{nurtureStepDetails.name}</strong></EuiText>
                                    <EuiSpacer size='s' />
                                    <div className='dot' style={{ backgroundColor: "#1A4B60", color: "white" }}>
                                        {nurtureStepDetails.leadCount || '0'}
                                    </div>
                                    <EuiSpacer size='s' />
                                    <EuiText size='xs' textAlign='center'>Contacts</EuiText>
                                    <EuiSpacer size='s' />
                                    <EuiLink style={{ height: "2rem" }} onClick={() => { navigate(`/accounts/${accountContext.account.accountId}/leads?brandId=${brandId}&nurtureStep=${nurtureStep + 1}`) }}><EuiText size='xs' textAlign='center'>View Qualified Leads</EuiText></EuiLink>
                                    <EuiText size='xs' textAlign='center'>{`+${nurtureStepDetails.delay} days`}</EuiText>
                                </EuiFlexItem>
                            )
                        })}
                    </EuiFlexGroup>
                </EuiFlexItem>
                <EuiFlexItem grow={5}>
                    <EuiFlexGroup alignItems='stretch'>
                        {(nurtureResults?.nurture || []).slice(3).map((nurtureStepDetails, nurtureStep) => {
                            return (
                                <EuiFlexItem >
                                    <EuiImage style={{ height: 250 }} className='sr-img-light' allowFullScreen src={nurtureScreenshots[nurtureStep + 3]}></EuiImage>
                                    <EuiSpacer size='s' />
                                    <EuiText size='xs' textAlign='center'>Step {nurtureStep + 4}</EuiText>
                                    <EuiText style={{ height: "2rem" }} size='xs' textAlign='center'><strong>{nurtureStepDetails.name}</strong></EuiText>
                                    <EuiSpacer size='s' />
                                    <div className='dot' style={{ backgroundColor: "#1A4B60", color: "white" }}>
                                        {nurtureStepDetails.leadCount || '0'}
                                    </div>
                                    <EuiSpacer size='s' />
                                    <EuiText size='xs' textAlign='center'>Contacts</EuiText>
                                    <EuiSpacer size='s' />
                                    <EuiLink style={{ height: "2rem" }} onClick={() => { navigate(`/accounts/${accountContext.account.accountId}/leads?brandId=${brandId}&nurtureStep=${nurtureStep + 4}`) }}><EuiText size='xs' textAlign='center'>View Qualified Leads</EuiText></EuiLink>
                                    <EuiText size='xs' textAlign='center'>{`+${nurtureStepDetails.delay} days`}</EuiText>
                                    {nurtureStepDetails.skip === true &&
                                        <React.Fragment>
                                            <EuiSpacer size='s' />
                                            <EuiBadge color='#ff0000' style={{ textAlign: "center", color: "white" }}>Skipped</EuiBadge>
                                        </React.Fragment>
                                    }
                                </EuiFlexItem>
                            )
                        })}
                    </EuiFlexGroup>
                </EuiFlexItem>
            </EuiFlexGroup>
        </React.Fragment>
    )
}