import { useContext, Fragment } from 'react';
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiButtonEmpty,
  EuiHorizontalRule,
  EuiEmptyPrompt,
  EuiTitle,
  EuiText,
  useEuiTheme,
} from '@elastic/eui';
import { Routes, Route, Navigate } from "react-router-dom";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useUser } from '@clerk/clerk-react';
import Error from './Error';
import UnderConstruction from "./UnderConstruction"
import NotFound from './NotFound';
import BrandUsers from './BrandUsers';
import BrandMarketing from './BrandMarketing';
import BrandWidgets from './BrandWidgets';
import BrandSettings from './BrandSettings';
import AccountContext from './AccountContext';
import BrandDiscountCodes from './BrandDiscountCodes';

export default function BrandDetails(props) {
  let euiTheme = useEuiTheme().euiTheme;
  let navigate = useNavigate();
  let location = useLocation();
  let params = useParams();
  const userApi = useUser();
  const accountContext = useContext(AccountContext);
  const pathPrefix = `/accounts/${params.accountId}/brands/${params.brandId}/`

  // Custom tab like button styles
  let unSelectedTabStyle = {
    "color": euiTheme.colors.text
  }
  let selectedTabStyle = {
    "backgroundColor": euiTheme.colors.lightestShade,
    "color": euiTheme.colors.link
  }

  return (
    <Fragment>
      <EuiFlexGroup responsive={false} wrap gutterSize="s" alignItems="center">
        {(userApi?.user?.publicMetadata?.platformAdmin === 'yes' || accountContext?.account?.isAccountAdmin || props?.brand?.editors?.indexOf(userApi?.user?.id) !== -1) &&
          <EuiFlexItem grow={false}>
            <EuiButtonEmpty
              style={location.pathname.startsWith(pathPrefix + 'editors') ? selectedTabStyle : unSelectedTabStyle}
              onClick={() => { navigate('editors') }}>
              Editors
            </EuiButtonEmpty>
          </EuiFlexItem>
        }
        <EuiFlexItem grow={false}>
          <EuiButtonEmpty
            style={location.pathname.startsWith(pathPrefix + 'sales') ? selectedTabStyle : unSelectedTabStyle}
            onClick={() => { navigate('sales') }}>
            Sales
          </EuiButtonEmpty>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButtonEmpty
            style={location.pathname.startsWith(pathPrefix + 'marketing') ? selectedTabStyle : unSelectedTabStyle}
            onClick={() => { navigate('marketing') }}>
            Marketing
          </EuiButtonEmpty>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButtonEmpty
            style={location.pathname.startsWith(pathPrefix + 'widgets') ? selectedTabStyle : unSelectedTabStyle}
            onClick={() => { navigate('widgets') }}>
            Widgets
          </EuiButtonEmpty>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButtonEmpty
            style={location.pathname.startsWith(pathPrefix + 'settings') ? selectedTabStyle : unSelectedTabStyle}
            onClick={() => { navigate('settings') }}>
            Settings
          </EuiButtonEmpty>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButtonEmpty
            style={location.pathname.startsWith(pathPrefix + 'discount-codes') ? selectedTabStyle : unSelectedTabStyle}
            onClick={() => { navigate('discount-codes') }}>
            Discount Codes
          </EuiButtonEmpty>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiHorizontalRule />
      <Routes>
        <Route index element={<Navigate to="editors" replace={true} />} />
        <Route path="editors" element={
          <BrandUsers refetch={props.refetch} brand={props?.brand} users={props?.brand?.editorDetails} role="editor" />
        } />
        <Route path="sales" element={
          <BrandUsers refetch={props.refetch} brand={props?.brand} users={props?.brand?.salesDetails} role="sales" />
        } />
        <Route path="marketing" element={
          <BrandMarketing refetch={props.refetch} accountId={props?.brand?.accountId} brandId={props?.brand?.brandId} brandEcommUrl={props?.brand?.ecommUrl} brandMarketing={props?.brand?.marketing} />
        } />
        <Route path="widgets" element={
          <BrandWidgets refetch={props.refetch} brand={props?.brand} />
        } />
        <Route path="settings" element={
          <BrandSettings refetch={props.refetch} brand={props?.brand} />
        } />
        {
          props?.brand?.featureDiscountCodes === true &&
          <Route path="discount-codes" element={<BrandDiscountCodes refetch={props.refetch} brand={props?.brand} />} />
        }
        {
          props?.brand?.featureDiscountCodes !== true &&
          <Route path="discount-codes" element={
            <EuiEmptyPrompt
              title={(<EuiTitle size='m'><span>{'Discount Codes Feature Inactive'}</span></EuiTitle>)}
              body={(<EuiText>{'You do not have access to this feature. Contact your admin to unlock it.'}</EuiText>)}
            >
            </EuiEmptyPrompt>
          } />
        }
        <Route path="error" element={<Error />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Fragment>
  );
};