import { Fragment, useState, useContext } from 'react';
import {
  EuiBasicTable,
  EuiAvatar,
  EuiSpacer,
  EuiButton,
  EuiFlexItem,
  EuiFlexGroup,
  EuiGlobalToastList,
  EuiButtonIcon,
  copyToClipboard,
  htmlIdGenerator,
  EuiText, EuiToolTip
} from '@elastic/eui';
import { UserAdd } from './UserAdd';
import AccountContext from './AccountContext';
import useAxios from 'axios-hooks';

export default function BrandUsers(props) {

  const accountContext = useContext(AccountContext);

  const handleitEmail = `HandleIt - ${props?.brand?.isk}<handleit.${accountContext?.account?.accountId}.${props?.brand?.brandId}@${process.env.REACT_APP_HANDLEIT_DOMAIN}>`
  const handleitMailTo = `mailto:${handleitEmail}`

  const [isModalVisible, setIsModalVisible] = useState(false);
  const closeModal = () => setIsModalVisible(false);
  const showModal = () => setIsModalVisible(true);

  const [
    { data, loading: addingUser, error: errorAddingUser },
    executePut
  ] = useAxios(
    {
      url: '/data/users',
      method: 'POST'
    },
    { manual: true }
  )

  const [
    { data: removeUserResults, loading: removingUser, error: errorRemovingUser },
    executeRemoveUser
  ] = useAxios(
    {
      url: '/data/brands/users',
      method: 'DELETE'
    },
    { manual: true }
  )

  function addUser({ email, firstName, lastName }) {
    closeModal()
    executePut({
      data: {
        accountId: accountContext.account.accountId,
        brandId: props?.brand?.brandId,
        email,
        firstName,
        lastName,
        role: props.role
      }
    })
      .then(() => {
        addToast({ title: "User added!", color: 'success' })
        accountContext.refetchAccount()
      })
      .catch((e) => {
        if (e?.response?.data?.error) addToast({ title: e.response.data.error, color: 'danger' })
        else addToast({ title: "Error adding user", color: 'danger' })
      })
      .finally(() => {
      })
  }

  function cancelAddUser() {
    closeModal();
  }

  const deleteUser = (user) => {
    executeRemoveUser({
      data: {
        userId: user.userId,
        accountId: accountContext.account.accountId,
        brandId: props?.brand?.brandId,
      }
    })
      .then(() => {
        addToast({ title: "User removed", color: 'success' })
        accountContext.refetchAccount()
      })
      .catch((e) => {
        addToast({ title: "Error removing user", color: 'danger' })
      })
      .finally(() => {
      })
  };

  const [toasts, setToasts] = useState([]);
  const genToastId = htmlIdGenerator('toast');
  const addToast = (toast) => {
    toast.id = genToastId();
    setToasts(toasts.concat(toast));
  };
  const removeToast = removedToast => {
    setToasts(toasts.filter(toast => toast.id !== removedToast.id));
  };

  const userColumns = [
    {
      field: 'profileImageUrl',
      name: '',
      dataType: 'string',
      width: "75px",
      render: (profileImageUrl, item) => {
        return (
          <EuiAvatar
            size="m"
            name={item.firstName + ' ' + item.lastName}
            imageUrl={profileImageUrl}
          />)
      }
    },
    {
      field: 'email',
      name: 'Email',
      dataType: 'string'
    },
    {
      field: 'emailAliases',
      name: 'Email Aliases (from your profile)',
      dataType: 'string', render: (emailAliases, item) => {
        return (
          <div style={{ display: "block" }}>
            {emailAliases && emailAliases.map(e => {
              return (<div><EuiText size='s' color='subdued'>{e}</EuiText></div>)
            })
            }
          </div>
        )
      }
    },
    {
      name: 'Actions',
      actions: [{
        name: 'Remove',
        description: 'Remove this user',
        icon: 'trash',
        color: 'danger',
        type: 'icon',
        onClick: deleteUser,
        isPrimary: true,
        'data-test-subj': 'action-delete',
        enabled: () => !removingUser && !addingUser
      }],
    }
  ]
  const [isTextCopied, setTextCopied] = useState(false);
  const onClick = (text) => {
    copyToClipboard(text);
    setTextCopied(true);
  };
  const onBlur = () => {
    setTextCopied(false);
  };

  return (
    <Fragment>
      <EuiGlobalToastList
        toasts={toasts}
        dismissToast={removeToast}
        toastLifeTimeMs={3000}
      />
      <EuiFlexGroup>
        <EuiFlexItem grow={false}>
          <EuiButton size="s" isLoading={addingUser} disabled={isModalVisible} fill onClick={showModal}>Invite User</EuiButton>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText><strong>Important:</strong> To process HandleIts for this brand, all editors should forward emails to: <a target='_blank' href={handleitMailTo} rel="noreferrer">HandleIt - {props?.brand?.isk}</a>
            <EuiToolTip
              content={isTextCopied ? 'Email copied to clipboard' : 'Copy email to clipboard'}
            >
              <EuiButtonIcon
                iconSize='s'
                aria-label="Copy email to clipboard"
                color="text"
                iconType="copy"
                onClick={() => onClick(handleitEmail)}
                onBlur={onBlur}
              />
            </EuiToolTip>
          </EuiText>
          <EuiText>It's especially important for editors assigned to 2+ brands.</EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />
      <EuiBasicTable
        tableCaption="List of brand users"
        items={props?.users || []}
        rowHeader="sk"
        columns={userColumns}
        tableLayout="auto"
      />
      {isModalVisible && <UserAdd done={addUser} cancel={cancelAddUser} />}
    </Fragment>
  );
};