import { EuiButtonIcon, EuiBasicTable, formatDate, EuiPagination, EuiFlexGroup, EuiFlexItem } from '@elastic/eui';
import useAxios from 'axios-hooks';
import fileDownload from 'js-file-download';
import { Fragment, useEffect, useState } from "react";

export default function HandleItSummary({ handleitIds }) {
    const pageSize = 5
    const pageCount = Math.ceil((handleitIds?.length || 0) / pageSize)
    const [activePage, setActivePage] = useState(0);

    // Download handleit email
    const [{ loading: downloadingEmail, error: errorDownloadingEmail }, execDownloadEmail] = useAxios(
        {
            url: '/data/handleit-email',
            method: 'GET'
        },
        { manual: true }
    );
    const downloadHandleIt = async (handleit) => {
        const res = await execDownloadEmail({
            params: {
                messageId: handleit.messageId
            }
        });
        fileDownload(res.data, `${handleit.subject}.eml`);
    }

    const [{ data: dataHandleIts, loading: loadingHandleIts, error: errorHandleIts }, getHandleIts] = useAxios(
        {
            url: '/data/handleit-summary',
            method: 'POST'
        },
        { manual: true }
    );

    useEffect(() => {
        if (handleitIds && handleitIds?.length > 0) {
            getHandleIts({
                data: {
                    handleitIds: handleitIds?.slice(pageSize * activePage, (pageSize * activePage) + pageSize)
                }
            })
        }
    }, [activePage, handleitIds, getHandleIts, pageSize]);


    const columns = [
        {
            field: 'messaegId',
            name: 'Download',
            width: '50px',
            render: (messaegId, h) => {
                return (
                    <EuiButtonIcon disabled={downloadingEmail} aria-label='download email' size='s' iconType="download" onClick={() => { downloadHandleIt(h) }}></EuiButtonIcon>
                )
            }
        },
        {
            field: 'ts',
            name: 'Forward Date',
            dataType: 'date',
            render: (date) => formatDate(date, 'MM/DD/YYYY'),
            width: "115px"
        },
        {
            field: 'subject',
            name: 'Subject'
        }
    ];

    return (
        <Fragment>
            < EuiBasicTable
                items={dataHandleIts?.handleits || []}
                loading={loadingHandleIts}
                error={errorHandleIts}
                columns={columns}
                tableLayout="auto"
                noItemsMessage="No HandleIts"
            />
            {pageCount > 1 &&
                <EuiFlexGroup justifyContent="spaceAround">
                    <EuiFlexItem grow={false}>
                        <EuiPagination
                            aria-label="Paging for HandleIts"
                            pageCount={pageCount}
                            activePage={activePage}
                            onPageClick={(activePage) => setActivePage(activePage)}
                        />
                    </EuiFlexItem>
                </EuiFlexGroup>
            }
        </Fragment>
    )
}