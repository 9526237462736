import React, { useState, useEffect, Fragment } from 'react';
import {
    EuiBasicTable, EuiTitle, EuiSpacer, EuiProgress, EuiLink,
    EuiFlexGroup, EuiFlexItem, EuiText,
    EuiButtonEmpty, EuiToolTip, EuiEmptyPrompt,
    EuiBadge,
    EuiFlyout,
    EuiFlyoutBody,
    EuiFlyoutHeader,
    EuiHorizontalRule,
    EuiTextColor,
    EuiButton,
    EuiFormRow,
    EuiSelect
} from '@elastic/eui';
import { useParams, useSearchParams } from "react-router-dom";
import useAxios from 'axios-hooks';
import LogoGoogle from '../Assets/logo-google.png'
import LogoLinkedIn from '../Assets/logo-linkedin.png'
import ComanyLeadDetails from './CompanyLeadDetails';
import axios from "axios"

export default function CompanyLeads({ brandId }) {
    const params = useParams();
    const [featureLeads, setFeatureLeads] = useState(false);
    const [leadResults, setLeadResults] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [nextToken, setNextToken] = useState(null);
    const [lastToken, setLastToken] = useState(null);

    // Get leads
    const [{ data: dataLeads, loading, error }, getLeads] = useAxios(
        {
            url: '/data/company-leads',
            method: 'POST'
        },
        { manual: true }
    );


    // Claim lead
    const [{ data: dataClaimLead, loading: loadingClaimLead, error: errorClaimLead }, execClaimLead] = useAxios(
        {
            url: '/data/claim-company-lead',
            method: 'POST'
        },
        { manual: true }
    );

    const columns = [
        {
            field: 'company',
            name: 'Company',
            width: "300px",
            render: (company, lead) => {
                return (
                    <Fragment>
                        <div style={{ textAlign: "center", marginRight: "10px", width: "64px", height: "64px", backgroundColor: "white", display: "flex" }}>
                            <img onError={(e) => { e.currentTarget.style.display = 'none' }} style={{ maxHeight: "64px", maxWidth: "64px", margin: "auto", display: "block" }} src={`https://logo.clearbit.com/${lead.domain}`} alt={lead.domain} />
                        </div>
                        <div style={{ width: "225px" }}>
                            <div><EuiLink target='_blank' onClick={() => { setModalLeadDetails({ lead }); setModalScrollTo(null) }}>{company?.companyName}</EuiLink></div>
                            <div><EuiText size='xs'>{`${company?.employeesRange || '-'} Employees`}</EuiText></div>
                            <div><EuiText size='xs'>{company?.citystate || '-'}</EuiText></div>
                            <div><EuiText size='xs'>{company?.country || '-'}</EuiText></div>
                        </div>
                    </Fragment>
                )
            }
        },
        {
            field: 'company',
            name: '',
            width: "125px",
            render: (company, lead) => {
                return (
                    <EuiFlexGroup style={{ width: "150px" }} direction="column" alignItems='flexStart' gutterSize="xs">
                        {lead?.tags?.multiBrand &&
                            <EuiFlexItem grow={false} key='multi_brand'>
                                <EuiToolTip position="top" content="This Company has contacted 2+ brands and is an ideal upsell target.">
                                    <EuiBadge onClickAriaLabel='Multi-Brand' color='#969696'><EuiTextColor color='white'>Multi-Brand</EuiTextColor></EuiBadge>
                                </EuiToolTip>
                            </EuiFlexItem>
                        }
                        {lead?.tags?.largeCompany &&
                            <EuiFlexItem grow={false} key='large_company'>
                                <EuiToolTip position="top" content="This company has more than 1,000 employees and likely has a higher marketing budget.">
                                    <EuiBadge onClickAriaLabel='Large Company' color='#606161'><EuiTextColor color='white'>Large Company</EuiTextColor></EuiBadge>
                                </EuiToolTip>
                            </EuiFlexItem>
                        }
                        {lead?.tags?.recentEngagement &&
                            <EuiFlexItem grow={false} key='recent_engagement'>
                                <EuiToolTip position="top" content="This company has engaged with your brand in the past 7 days. Reach out now.">
                                    <EuiBadge onClickAriaLabel='Recent Engagement' color='#000000'><EuiTextColor color='white'>Recent Engagement</EuiTextColor></EuiBadge>
                                </EuiToolTip>
                            </EuiFlexItem>
                        }
                        {lead?.tags?.provenBuyer &&
                            <EuiFlexItem grow={false} key='proven_buyer'>
                                <EuiToolTip position="top" content="This company has already spent money with your brand through a paid press release. Engage them now to increase share of wallet by selling your existing media products.">
                                    <EuiBadge onClickAriaLabel='Proven Buyer' color='#732A86'><EuiTextColor color='white'>Proven Buyer</EuiTextColor></EuiBadge>
                                </EuiToolTip>
                            </EuiFlexItem>
                        }
                    </EuiFlexGroup>
                )
            }
        },
        {
            field: 'companyInterestLevel',
            name: 'Company Interest Level',
            width: "150px",
            render: (companyInterestLevel, lead) => {
                return (
                    <div style={{ width: "150px" }}>
                        <div><EuiText><strong>{lead.companyInterestLevelTag}</strong></EuiText></div>
                        <EuiFlexGroup gutterSize="s" alignItems='center' >
                            <EuiFlexItem grow={false}>
                                <EuiText><EuiLink onClick={() => { setModalLeadDetails({ lead }); setModalScrollTo('companyinterest') }}>{(companyInterestLevel || 0).toString() || '0'}</EuiLink></EuiText>
                            </EuiFlexItem>
                            <EuiFlexItem grow={true}>
                                <EuiProgress style={{ width: "100px" }} size='l' value={companyInterestLevel || 0} max={100}></EuiProgress>
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    </div>
                )
            }
        },
        {
            field: 'handleitCount',
            name: 'HandleIts',
            render: (handleitCount, lead) => {
                return (
                    <div>
                        <div><EuiText><strong>{handleitCount}</strong></EuiText></div>
                        <div>
                            <EuiText size='xs'><EuiLink onClick={() => { setModalLeadDetails({ lead }); setModalScrollTo("handleits") }}>View Details</EuiLink></EuiText>
                        </div>
                    </div>
                )
            }
        },
        {
            field: 'paidTotalValue',
            name: 'Paid PR Spend',
            dataType: 'string',
            render: (paidTotalValue, lead) => {
                return (
                    <div>
                        <div>
                            <EuiText><strong>${paidTotalValue?.toLocaleString() || 0}</strong></EuiText>
                        </div>
                        <div>
                            <EuiText size='xs'><EuiLink onClick={() => { setModalLeadDetails({ lead }); setModalScrollTo('prs') }}>{lead.paidCount} Paid PRs</EuiLink></EuiText>
                        </div>
                    </div>
                )
            }
        },
        {
            field: 'lastEngagementDays',
            name: 'Last Engagement',
            dataType: 'string',
            render: (lastEngagementDays) => {
                return (
                    <div>
                        <div><EuiText><strong>{lastEngagementDays || '-'}</strong></EuiText></div>
                        <div>
                            <EuiText size='xs'>Days Ago</EuiText>
                        </div>
                    </div>
                )
            }
        },
        {
            field: 'contacts',
            name: 'Contacts',
            render: (contacts, lead) => {
                return (
                    <div>
                        <div><EuiButtonEmpty size='s' onClick={() => { setModalLeadDetails({ lead }); setModalScrollTo('contacts') }}>{lead?.contacts?.length || 0} Contacts</EuiButtonEmpty></div>
                        <div>
                            <EuiButtonEmpty size='xs' target='_blank' href={`https://www.linkedin.com/search/results/people/?currentCompany=%5B%22${lead?.company?.linkedInId}%22%5D&origin=FACETED_SEARCH&sid=K8&titleFreeText=marketing`} ><img style={{ height: '16px' }} src={LogoLinkedIn} /></EuiButtonEmpty>
                            <EuiButtonEmpty size='xs' target='_blank' href={`https://www.google.com/search?q=${lead?.company?.companyName}+%22marketing%22+site%3Awww.linkedin.com%2Fin%2F`}><img style={{ height: '16px' }} src={LogoGoogle} /></EuiButtonEmpty>
                        </div>
                    </div>
                )
            }
        },
        {
            field: 'claimedBy',
            name: 'Claimed By',
            width: "100px",
            render: (claimedBy, lead) => {
                return (
                    < div style={{ width: "100%", minWidth: "175px" }}>
                        <div style={{ padding: "0 8px 0 8px" }}><EuiText textAlign='left' size='xs'>{claimedBy || ''}</EuiText></div>
                        <div style={{ textAlign: "left" }}>
                            {!claimedBy && <EuiButton size='xs' onClick={() => { claimLead(lead.companyDomain) }}>Claim</EuiButton>}
                            {claimedBy && <EuiButtonEmpty size='xs' onClick={() => { claimLead(lead.companyDomain) }}>Claim</EuiButtonEmpty>}
                        </div>
                    </div>
                )
            }
        }
    ];

    const [sortProp, setSortProp] = useState("interest-level");
    const onChangeSortProp = (e) => {
        setSortProp(e.target.value);
    };

    const [modalLeadDetails, setModalLeadDetails] = useState(null);
    const [modalScrollTo, setModalScrollTo] = useState('overview');

    useEffect(() => {
        setLastToken(null)
        setNextToken(null)
        setLeadResults([])
        getLeads({
            data: {
                accountId: params.accountId,
                brandId,
                sort: sortProp
            }
        });
    }, [brandId, sortProp, dataClaimLead]);

    useEffect(() => {
        if (dataLeads) {
            setFeatureLeads(dataLeads.featureLeads)
            if (lastToken === null) setLeadResults([...dataLeads.leads])
            else setLeadResults([...leadResults, ...dataLeads.leads])
            setNextToken(dataLeads.nextToken)
        }
    }, [dataLeads]);


    const companyDomain = searchParams.get('company')
    useEffect(() => {
        if (modalLeadDetails?.lead?.companyDomain || companyDomain) setSearchParams({ company: modalLeadDetails?.lead?.companyDomain || companyDomain })
        else setSearchParams({})
    }, [modalLeadDetails]);


    // Get lead
    const [{ data: dataLead, loading: loadingLead, error: errorLead }, getLead] = useAxios(
        {
            url: '/data/company-leads',
            method: 'POST'
        },
        { manual: true }
    );
    useEffect(() => {
        if (dataLead?.leads[0]) {
            setModalLeadDetails({ lead: dataLead.leads[0] })
        }
    }, [dataLead])

    useEffect(() => {
        if (companyDomain) {
            getLead({
                data: {
                    accountId: params.accountId,
                    brandId,
                    companyDomain
                }
            });
        }
    }, []);

    function loadMore() {
        if (nextToken?.pk !== lastToken?.pk || nextToken?.sk !== lastToken?.sk) {
            getLeads({
                data: {
                    accountId: params.accountId,
                    brandId,
                    sort: sortProp,
                    nextToken
                }
            });
            setLastToken(nextToken)
        }
    }

    function claimLead(claimedCompanyDomain) {
        execClaimLead({
            data: {
                accountId: params.accountId,
                brandId,
                companyDomain: claimedCompanyDomain
            }
        });
    }

    return (
        <React.Fragment>
            <EuiFlexGroup>
                <EuiFlexItem grow={false}>
                    <EuiFormRow label="Sort By">
                        <EuiSelect
                            compressed
                            hasNoInitialSelection={true}
                            options={[
                                { value: "interest-level", text: "Interest Level" },
                                { value: "last-engagement", text: "Last Engagement" },
                                { value: "paid-pr-value", text: "Paid PR Spend" }
                            ]}
                            value={sortProp}
                            onChange={(e) => onChangeSortProp(e)}
                        />
                    </EuiFormRow>
                </EuiFlexItem>
            </EuiFlexGroup>
            <EuiSpacer />
            <EuiBasicTable
                responsiveBreakpoint="m"
                tableCaption="Leads"
                items={leadResults || []}
                columns={columns}
                tableLayout="auto"
                loading={loading}
                noItemsMessage={
                    <EuiEmptyPrompt
                        title={(<EuiTitle size='m'><span>{loading === true ? 'Loading Leads...' : (featureLeads === true ? 'No leads yet!' : 'Leads Feature Inactive')}</span></EuiTitle>)}
                        body={(<EuiText>{loading === true ? '' : (featureLeads === true ? 'The editors can help. Ask them to start forwarding "HandleIt\'s" - the press releases they don\'t intend to cover.' : 'You do not have access to this feature. Contact your admin to unlock it.')}</EuiText>)}
                    >
                    </EuiEmptyPrompt>
                }
            />
            {nextToken &&
                <EuiFlexGroup justifyContent="spaceAround">
                    <EuiFlexItem grow={false}>
                        <EuiButtonEmpty disabled={loading} onClick={() => loadMore()}>Load More...</EuiButtonEmpty>
                    </EuiFlexItem>
                </EuiFlexGroup>
            }
            {(modalLeadDetails) &&
                <EuiFlyout size="60%" onClose={() => { setModalLeadDetails(null); setSearchParams({}) }}>
                    <EuiFlyoutHeader>
                        <EuiFlexGroup>
                            <EuiFlexItem grow={false}>
                                <img onError={(e) => { e.currentTarget.style.display = 'none' }} style={{ maxHeight: "64px", maxWidth: "64px", margin: "auto", display: "block" }} src={`https://logo.clearbit.com/${modalLeadDetails?.lead?.companyDomain}`} alt={modalLeadDetails?.lead?.companyDomain} />
                            </EuiFlexItem>
                            <EuiFlexItem>
                                <EuiTitle><h2>{modalLeadDetails?.lead?.company?.companyName}</h2></EuiTitle>
                                {/* <EuiText color='subdued'>Claimed by {modalLeadDetails?.lead?.claimedBy}</EuiText> */}
                                <EuiText color='subdued'>Last Engagement {modalLeadDetails?.lead?.lastEngagementDays || '-'} days ago</EuiText>
                            </EuiFlexItem>
                        </EuiFlexGroup>
                        <EuiSpacer size='m' />
                        <EuiFlexGroup gutterSize="s">
                            <EuiFlexItem grow={false}><EuiButtonEmpty onClick={() => { setModalScrollTo('overview') }} size="s">Company Overview</EuiButtonEmpty></EuiFlexItem>
                            <EuiFlexItem grow={false}><EuiButtonEmpty onClick={() => { setModalScrollTo('companyinterest') }} size="s">Company Interest Level</EuiButtonEmpty></EuiFlexItem>
                            <EuiFlexItem grow={false}><EuiButtonEmpty onClick={() => { setModalScrollTo('brands') }} size="s">Brands Engaged</EuiButtonEmpty></EuiFlexItem>
                            <EuiFlexItem grow={false}><EuiButtonEmpty onClick={() => { setModalScrollTo('handleits') }} size="s">HandleIts</EuiButtonEmpty></EuiFlexItem>
                            <EuiFlexItem grow={false}><EuiButtonEmpty onClick={() => { setModalScrollTo('prs') }} size="s">Paid PR Spend</EuiButtonEmpty></EuiFlexItem>
                            <EuiFlexItem grow={false}><EuiButtonEmpty onClick={() => { setModalScrollTo('contacts') }} size="s">Contacts</EuiButtonEmpty></EuiFlexItem>
                        </EuiFlexGroup>
                        <EuiHorizontalRule margin='s' />
                    </EuiFlyoutHeader>
                    <EuiFlyoutBody>
                        <ComanyLeadDetails scrollTo={modalScrollTo} companyLead={modalLeadDetails?.lead} />
                    </EuiFlyoutBody>
                </EuiFlyout>
            }
        </React.Fragment>
    );
};