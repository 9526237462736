import { EuiPage, EuiPageBody, EuiPageSidebar, EuiPanel } from '@elastic/eui';
import { Fragment, useEffect } from 'react';
import { Navigate, Route, Routes, useParams, useNavigate } from "react-router-dom";
import AccountContext from './AccountContext';
import useAxios from "axios-hooks";
import { useUser } from '@clerk/clerk-react';
import SideNav from './SideNav';
import AdminSettings from './AccountSettings'
import PressReleases from './PressReleases'
import BrandsContainer from './BrandsContainer';
import Loading from './Loading';
import PressRelease from './PressRelease';
import Leads from './Leads';
import Nurture from './Nurture';
import Analytics from './Analytics';
import LeadsV2 from './LeadsV2';
export default function AccountContainer() {
  const nav = useNavigate();
  const params = useParams();
  const userApi = useUser();

  const [{ data: account, loading: accountLoading, error: accountError }, refetchAccount] = useAxios({
    method: 'get',
    url: '/data/account-summary',
    params: {
      accountId: params.accountId
    },
  });

  useEffect(() => {
    // Check to make sure this user is on this account
    if (account?.status === 'inactive' && userApi.user?.publicMetadata?.platformAdmin !== 'yes') {
      nav(`../account-inactive`)
    }
  }, [account, nav]);

  useEffect(() => {
    // Check to make sure this user is on this account
    if (userApi.user?.publicMetadata?.account_id && userApi.user?.publicMetadata?.account_id !== params.accountId) {
      nav(`../${userApi.user?.publicMetadata?.account_id}`)
    }
  }, []);

  return (
    <Fragment>
      {account && !accountLoading && !accountError &&
        <AccountContext.Provider value={{ account, accountLoading, accountError, refetchAccount }}>
          <div className='full-height'>
            <div style={{ width: "175px", position: 'fixed', backgroundColor: "#f7f8fc", height: "100%", padding: "64px 16px 16px 16px" }}>
              <SideNav />
            </div>
            <div style={{ marginLeft: "175px", backgroundColor: "white", minHeight: "100%", padding: "64px 16px 16px 16px" }} >
              <Routes>
                <Route path="leads">
                  <Route path=':brandId?/:tab?' element={<LeadsV2 accountId={params.accountId} />}></Route>
                </Route>
                {/* <Route path="leads" element={<Leads />} /> */}
                <Route path="nurture" element={<Nurture />} />
                <Route path="press-releases" element={<PressReleases />} />
                <Route path="press-releases/:brandId/:prSlug" element={<PressRelease />} />
                <Route path='brands/*' element={<BrandsContainer />} />
                <Route path="analytics/*" element={<Analytics />} />
                <Route path="settings/*" element={<AdminSettings />} />
                {/* <Route path="*" element={<Navigate to="press-releases" />} /> */}
              </Routes>
            </div>
          </div>
        </AccountContext.Provider>
      }
      {accountLoading && <Loading message="Loading Account ..." />}
    </Fragment>
  );
};