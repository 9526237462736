import { EuiFlexGroup, EuiFlexItem, EuiImage, EuiSkeletonRectangle, EuiText } from "@elastic/eui";
import { Fragment, useEffect } from "react";
import useAxios from 'axios-hooks';

export default function BrandsGallery({ accountId, brandIds }) {
    const [{ data: dataBrands, loading: loadingBrands, error: errorBrands }, getBrands] = useAxios(
        {
            url: '/data/brand-summary',
            method: 'POST'
        },
        { manual: true }
    );

    useEffect(() => {
        if (brandIds && brandIds?.length > 0) {
            getBrands({ data: { accountId, brandIds } })
        }
    }, []);

    return (
        <EuiFlexGroup alignItems="flexEnd">
            {!loadingBrands &&
                (dataBrands?.brands || []).map(brand => {
                    return (
                        <EuiFlexItem key={brand.brandId} grow={false}>
                            <div style={{ textAlign: "center" }}>
                                <div style={{ height: "64px", minWidth: "96px", display: "flex", alignContent: "center", justifyContent: "center" }}>
                                    <img alt="" style={{ maxHeight: "64px", maxWidth: "96px", alignSelf: "center" }} src={brand.logo}></img>
                                </div>
                                <EuiText size="xs">{brand.brandName}</EuiText>
                            </div>
                        </EuiFlexItem>
                    )
                })
            }
            {loadingBrands &&
                <Fragment>
                    {(brandIds || []).map(() => {
                        return (<EuiFlexItem key='1' grow={false}><EuiSkeletonRectangle width={96} height={96} /></EuiFlexItem>)
                    })}
                </Fragment>
            }
        </EuiFlexGroup>
    )
}