import React from 'react'; import {
    EuiEmptyPrompt,
    EuiImage,
    EuiButton,
    EuiButtonEmpty,
} from '@elastic/eui';
import { useNavigate } from "react-router-dom";
import logo from '../Assets/logo-swiftrev-small-black.png';

export default function NotFound() {
    let navigate = useNavigate();
    return (
        <EuiEmptyPrompt
            icon={<EuiImage size={200} alt="SwiftRev" url={logo} />}
            color="subdued"
            title={<h2>Page not found</h2>}
            layout="vertical"
            body={
                <p>
                    Sorry, we can&apos;t find the page you&apos;re looking for. It might
                    have been removed or renamed, or maybe it never existed.
                </p>
            }
            actions={[
                <EuiButton color="primary" fill onClick={() => { navigate('/') }}>
                    Go home
                </EuiButton>,
                <EuiButtonEmpty iconType="arrowLeft" flush="both" onClick={() => { navigate(-1) }}>
                    Go back
                </EuiButtonEmpty>,
            ]}
        />
    );
}