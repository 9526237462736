import { Fragment, useEffect, useState, useRef } from 'react';
import {
  EuiForm,
  EuiDescribedFormGroup,
  EuiFormRow,
  EuiFilePicker,
  EuiColorPicker,
  useColorPickerState,
  EuiTextArea,
  EuiFieldNumber,
  EuiComboBox,
  EuiImage,
  EuiFlexGroup,
  EuiFlexItem,
  EuiButton,
  EuiSpacer,
  EuiBottomBar,
  EuiLink,
  EuiText
} from '@elastic/eui';
import useAxios from 'axios-hooks';

const isValid = (value) => {
  // Only allow alpha numeric characters and spaces.
  // return value.match(/^[a-zA-Z0-9 ]+$/) !== null;
  return true;
};

export default function BrandMarketing(props) {
  const optionsIndustries = (props.brandMarketing.industries || []).map(i => {
    if (typeof i === 'string') return { label: i }
    return i;
  })
  const optionsJobs = (props.brandMarketing.jobs || []).map(j => {
    if (typeof j === 'string') return { label: j }
    return j;
  })
  const divisionOptions = (props.brandMarketing.divisions || []).map(d => {
    return {
      label: d.name,
      value: d.id
    }
  })

  const [hasChanges, setHasChanges] = useState(false);

  // Brand description
  const [brandDescription, setBrandDescription] = useState(props?.brandMarketing?.description);
  const onChangeBrandDescription = (e) => {
    setBrandDescription(e.target.value);
  };

  // Brand audience
  const [brandAudience, setBrandAudience] = useState(props?.brandMarketing?.audience || 0);
  const onChangeBrandAudience = (e) => {
    setBrandAudience(parseInt(e.target.value));
  };

  // Brand color
  const [brandColor, setBrandColor, errors] = useColorPickerState(props?.brandMarketing?.brandColor || '#000000');

  // Brand Industries
  const [selectedIndustries, setSelectedIndustries] = useState(optionsIndustries);
  const [isInvalidIndustries, setInvalidIndustries] = useState(false);
  const onCreateIndustry = (searchValue) => {
    if (!isValid(searchValue)) {
      // Return false to explicitly reject the user's input.
      return false;
    }
    const newOption = {
      label: searchValue,
    };
    // Select the option.
    setSelectedIndustries([...selectedIndustries, newOption]);
  };
  const onSearchIndustriesChange = (searchValue) => {
    if (!searchValue) {
      setInvalidIndustries(false);

      return;
    }
    setInvalidIndustries(!isValid(searchValue));
  };
  const onIndustriesChange = (selectedOptions) => {
    setSelectedIndustries(selectedOptions);
    setInvalidIndustries(false);
  };

  // Brand Job Functions
  const [selectedJobs, setSelectedJobs] = useState(optionsJobs);
  const [isInvalidJobs, setInvalidJobs] = useState(false);
  const onCreateJob = (searchValue) => {
    if (!isValid(searchValue)) {
      // Return false to explicitly reject the user's input.
      return false;
    }
    const newOption = {
      label: searchValue,
    };
    // Select the option.
    setSelectedJobs([...selectedJobs, newOption]);
  };
  const onSearchJobsChange = (searchValue) => {
    if (!searchValue) {
      setInvalidJobs(false);

      return;
    }
    setInvalidJobs(!isValid(searchValue));
  };
  const onJobsChange = (selectedOptions) => {
    setSelectedJobs(selectedOptions);
    setInvalidJobs(false);
  };

  // Brand division
  const [selectedDivision, setSelectedDivision] = useState(props?.brandMarketing?.division ? [{
    label: props?.brandMarketing?.division.name,
    value: props?.brandMarketing?.division.id
  }] : []);

  // Brand logo
  const filePickerRef = useRef();
  const [brandLogo, setBrandLogo] = useState(props?.brandMarketing?.logo);
  const [brandLogoError, setBrandLogoError] = useState(props?.brandMarketing?.logo ? null : "Logo required");
  function readFileAsync(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => { resolve(reader.result); };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    })
  }
  async function loadLogo(file) {
    let newLogo = await readFileAsync(file)
    setBrandLogo(newLogo)
    filePickerRef.current.removeFiles()
    setBrandLogoError(null)
  }
  const onChangeLogo = (files) => {
    if (files && files.length > 0 && files[0]) {
      if (files[0].size > 200000) {
        console.log(`File too large ${files[0].size}`)
        setBrandLogoError("File too large. Max size 200kb.")
      }
      else {
        loadLogo(files[0]);
      }
    }
    else setBrandLogoError(brandLogo != null ? null : 'Logo required');
  }

  // Validate all brand marketing components and check for changes
  const [isValidBrandMarketing, setIsValidBrandMarketing] = useState(false);
  useEffect(() => {
    setIsValidBrandMarketing(
      brandLogo != null &&
      brandColor != null &&
      !!brandAudience &&
      !!brandDescription &&
      selectedIndustries.length > 0 &&
      selectedJobs.length > 0
    );

    setHasChanges(
      brandLogo !== props.brandMarketing.logo ||
      brandColor !== props.brandMarketing.brandColor ||
      brandAudience !== props.brandMarketing.audience ||
      brandDescription !== props.brandMarketing.description ||
      (selectedDivision[0]?.value || null) !== (props.brandMarketing.divisionId || null) ||
      selectedIndustries.map(i => i.label).toString() !== (props.brandMarketing.industries || []).toString() ||
      selectedJobs.map(j => j.label).toString() !== (props.brandMarketing.jobs || []).toString()
    );
  }, [
    brandLogo,
    brandColor,
    brandAudience,
    brandDescription,
    selectedDivision,
    selectedIndustries,
    selectedJobs
  ]);

  // Save brand marketing updates
  const [
    { data: updateBrandMarketingResults, loading: updatingBrandMarketing, error: errorUpdatingBrandMarketing }, executeUpdateBrandMarketing
  ] = useAxios({
    url: '/data/brands/update-marketing',
    method: 'POST'
  }, {
    manual: true
  })

  async function updateBrandMarketing() {
    await executeUpdateBrandMarketing({
      data: {
        "accountId": props.accountId,
        "brandId": props.brandId,
        "marketing": {
          "audience": brandAudience,
          "brandColor": brandColor,
          "description": brandDescription,
          "divisionId": selectedDivision[0]?.value || null,
          "industries": selectedIndustries.map(i => i.label),
          "jobs": selectedJobs.map(j => j.label),
          "logo": brandLogo
        }
      }
    })
    props.refetch()
  }

  return (
    <Fragment>
      <EuiForm component="form" disabled={updatingBrandMarketing}>
        <EuiFormRow hasEmptyLabelSpace>
          <EuiLink target="_blank" href={props.brandEcommUrl}>View Marketing Page</EuiLink>
        </EuiFormRow>
        <EuiSpacer />
        <EuiDescribedFormGroup
          ratio="third"
          title={<h2>Promotional</h2>}
          description={
            <Fragment>
              This is used when cross-promoting properties during the checkout process.
            </Fragment>
          }
        >
          <EuiFormRow fullWidth isInvalid={!brandDescription}
            label="Property Description"
            helpText={"Max characters 200"}
          >
            <EuiTextArea fullWidth isInvalid={!brandDescription} style={{ height: "125px" }} resize="none" maxLength="200"
              placeholder="Focus on primary segment and area of expertise. Brief is best."
              value={brandDescription} onChange={(e) => onChangeBrandDescription(e)}
            />
          </EuiFormRow>
          <EuiFormRow fullWidth isInvalid={!brandAudience}
            label="Monthly Audience Reach"
            helpText={<EuiText size='xs'>See our <EuiLink target="_blank" href="https://www.swiftrev.com/guides/how-is-the-monthly-audience-reach-calculated/">recommended calculation method</EuiLink></EuiText>}>
            <EuiFieldNumber fullWidth min={0} isInvalid={!brandAudience}
              value={brandAudience} onChange={(e) => onChangeBrandAudience(e)}
            />
          </EuiFormRow>
          <EuiFormRow fullWidth
            label="Industries Served" helpText="Select top 3 industries served by the brand"
            isInvalid={isInvalidIndustries || selectedIndustries.length === 0}>
            <EuiComboBox fullWidth
              noSuggestions
              placeholder="Add an industry"
              selectedOptions={selectedIndustries}
              onCreateOption={onCreateIndustry}
              onChange={onIndustriesChange}
              onSearchChange={onSearchIndustriesChange}
              isInvalid={isInvalidIndustries || selectedIndustries.length === 0}>
            </EuiComboBox>
          </EuiFormRow>
          <EuiFormRow fullWidth
            label="Relevant Job Functions" helpText="Select top 3 job functions relevant to the brand"
            isInvalid={isInvalidJobs || selectedJobs.length === 0}>
            <EuiComboBox fullWidth
              noSuggestions
              placeholder="Add a job category"
              selectedOptions={selectedJobs}
              onCreateOption={onCreateJob}
              onChange={onJobsChange}
              onSearchChange={onSearchJobsChange}
              isInvalid={isInvalidJobs || selectedJobs.length === 0}>
            </EuiComboBox>
          </EuiFormRow>
          <EuiFormRow fullWidth label="Division" helpText="Brands in the same division will be cross promoted in checkout">
            <EuiComboBox fullWidth
              placeholder="Select a division"
              singleSelection={{ asPlainText: true }}
              options={divisionOptions || []}
              selectedOptions={selectedDivision}
              onChange={(selection) => { setSelectedDivision(selection) }}
            />
          </EuiFormRow>
        </EuiDescribedFormGroup>
        <EuiDescribedFormGroup
          ratio="third"
          title={<h2>Branding</h2>}
          description={
            <Fragment>
              Customize the look and feel of your ecommerce experience and widgets
            </Fragment>
          }>
          <EuiFormRow
            fullWidth
            label="Primary Brand Color"
            isInvalid={!!errors} error={errors}>
            <EuiColorPicker fullWidth
              onChange={setBrandColor}
              color={brandColor}
              isInvalid={!!errors}
            />
          </EuiFormRow>
          <EuiFormRow fullWidth label="Logo Image" helpText="Recommended height of at least 200px. Max file size is 200Kb."
            isInvalid={!!brandLogoError} error={brandLogoError} >
            <EuiFlexGroup alignItems='center'>
              <EuiFlexItem>
                <div style={{ border: "1px solid lightgray", height: "150px", width: "200px", display: "grid", alignContent: "center", justifyContent: "center" }}>
                  <EuiImage alt='No Logo' src={brandLogo} />
                </div>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiFilePicker ref={filePickerRef} fullWidth onChange={onChangeLogo} initialPromptText="Click or drop file to update logo" />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFormRow>
        </EuiDescribedFormGroup>
      </EuiForm>
      {isValidBrandMarketing && hasChanges && <EuiBottomBar>
        <EuiFlexGroup justifyContent="flexEnd">
          <EuiFlexItem grow={false}>
            <EuiButton disabled={updatingBrandMarketing} onClick={() => props.refetch()} color="ghost" size="s" iconType="save">
              Discard Changes
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton isLoading={updatingBrandMarketing} color="primary" fill size="s" iconType="save"
              onClick={() => { updateBrandMarketing() }}>
              Save Changes
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiBottomBar>}
    </Fragment>
  );
};