import React from 'react'; import {
    EuiEmptyPrompt,
    EuiImage,
    EuiButton,
    EuiButtonEmpty,
} from '@elastic/eui';
import { useNavigate } from "react-router-dom";
import logo from '../Assets/logo-swiftrev-small-black.png';

export default function AccountInactive() {
    return (
        <EuiEmptyPrompt
            icon={<EuiImage size={200} alt="SwiftRev" url={logo} />}
            color="subdued"
            title={<h2>Account Inactive</h2>}
            layout="vertical"
            body={
                <p>
                    Sorry, your account is no longer active.
                </p>
            }
        />
    );
}