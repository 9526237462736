import React, { Fragment } from 'react';
import {
    EuiBasicTable, EuiTitle, EuiSpacer, EuiProgress, EuiLink,
    EuiFlexGroup, EuiFlexItem, EuiText,
    EuiButtonIcon
} from '@elastic/eui';


const colsLeadScoreDetails = [
    {
        field: 'leadScore',
        name: 'SwiftRev Lead Score',
        width: "150px",
        render: (leadScore) => {
            return (
                <EuiFlexGroup component="span" gutterSize="s" alignItems='center'>
                    <EuiFlexItem grow={false}>
                        <EuiText>{leadScore?.toString() || '0'}</EuiText>
                    </EuiFlexItem>
                    <EuiFlexItem grow={true}>
                        <EuiProgress size='l' value={leadScore || 0} max={100}></EuiProgress>
                    </EuiFlexItem>
                </EuiFlexGroup>
            )
        }
    },
    {
        field: 'handleitCount',
        name: 'HandleIts',
        width: "115px",
        dataType: 'number'
    },
    {
        field: 'leadScoreDetails.opensScore',
        name: '# Email Opens',
        width: "115px",
        dataType: 'number',
        render: (opensScore) => {
            return (opensScore || 0) / 2
        }
    },
    {
        field: 'leadScoreDetails.clicksScore',
        name: '# Email Clicks',
        width: "115px",
        dataType: 'number',
        render: (clicksScore) => {
            return (clicksScore || 0) / 5
        }
    },
]

export default function IndividualLeadScoreDetails(props) {

    return (
        <Fragment>
            <EuiFlexGroup>
                <EuiFlexItem grow={false}>
                    <EuiTitle size='m'><span>Lead Score Details</span></EuiTitle>
                    <EuiSpacer size='xs'></EuiSpacer>
                    <EuiLink target="_blank" href="https://www.swiftrev.com/guides/swiftrev-lead-score-detail/">More Info</EuiLink>
                </EuiFlexItem>
                <EuiFlexItem grow={true}>

                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <EuiButtonIcon aria-label='close popup' iconType="cross" onClick={() => props?.onClose()} />
                </EuiFlexItem>
            </EuiFlexGroup>
            <EuiSpacer></EuiSpacer>
            <EuiText><span style={{ backgroundColor: "#F1F2F2" }}>{props?.lead?.email}</span></EuiText>
            <EuiSpacer></EuiSpacer>
            <EuiBasicTable
                tableLayout='auto'
                className='table-wrap-col-header'
                isSelectable={false}
                items={[props?.lead]}
                columns={colsLeadScoreDetails}>
            </EuiBasicTable>
        </Fragment>
    )
}