import { EuiFlexGrid, EuiFlexGroup, EuiFlexItem, EuiLink, EuiSpacer, EuiStat, EuiText, EuiTextColor } from "@elastic/eui";
import { Fragment } from "react";



export default function CompanyOverview(props) {

    return (
        <Fragment>
            <EuiFlexGroup direction="column">
                <EuiFlexItem><EuiStat descriptionElement="div" reverse titleSize="xxs" title={<EuiLink target="_blank" href={props?.company?.url}>{props?.company?.url || '-'}</EuiLink>} description={<EuiText size="xs" color="subdued">WEBSITE</EuiText>} /></EuiFlexItem>
                <EuiFlexItem><EuiStat descriptionElement="div" reverse titleSize="xxs" title={<EuiLink target="_blank" href={props?.company?.urlLinkedIn}>{props?.company?.urlLinkedIn || '-'}</EuiLink>} description={<EuiText size="xs" color="subdued">LINKEDIN COMPANY PAGE</EuiText>} /></EuiFlexItem>
            </EuiFlexGroup>
            <EuiSpacer />
            <EuiFlexGrid columns={3}>
                <EuiFlexItem><EuiStat descriptionElement="div" reverse titleSize="xxs" title={props?.company?.foundedYear || '-'} description={<EuiText size="xs" color="subdued">FOUNDED YEAR</EuiText>} /></EuiFlexItem>
                <EuiFlexItem><EuiStat descriptionElement="div" reverse titleSize="xxs" title={props?.company?.industry || '-'} description={<EuiText size="xs" color="subdued">INDUSTRY</EuiText>} /></EuiFlexItem>
                <EuiFlexItem></EuiFlexItem>
                <EuiFlexItem><EuiStat descriptionElement="div" reverse titleSize="xxs" title={props?.company?.employeesRange || '-'} description={<EuiText size="xs" color="subdued">TOTAL EMPLOYEES</EuiText>} /></EuiFlexItem>
                <EuiFlexItem><EuiStat descriptionElement="div" reverse titleSize="xxs" title={props?.company?.marketingEmployees || '-'} description={<EuiText size="xs" color="subdued">MARKETING EMPLOYEES</EuiText>} /></EuiFlexItem>
                <EuiFlexItem></EuiFlexItem>
                <EuiFlexItem><EuiStat descriptionElement="div" reverse titleSize="xxs" title={props?.company?.city || '-'} description={<EuiText size="xs" color="subdued">CITY</EuiText>} /></EuiFlexItem>
                <EuiFlexItem><EuiStat descriptionElement="div" reverse titleSize="xxs" title={props?.company?.state || '-'} description={<EuiText size="xs" color="subdued">STATE</EuiText>} /></EuiFlexItem>
                <EuiFlexItem><EuiStat descriptionElement="div" reverse titleSize="xxs" title={props?.company?.country || '-'} description={<EuiText size="xs" color="subdued">COUNTRY</EuiText>} /></EuiFlexItem>
            </EuiFlexGrid>
            <EuiSpacer />
            {/* <EuiFlexGroup>
                <EuiFlexItem><EuiStat reverse titleSize="xxs" title={props?.company?.description || '-'} description={<EuiText size="xs" color="subdued">DESCRIPTION</EuiText>} /></EuiFlexItem>
            </EuiFlexGroup> */}
        </Fragment >
    )
}