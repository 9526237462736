import React, { useState, useContext, useEffect } from 'react';
import {
    EuiTitle, EuiSpacer, EuiButtonEmpty,
    EuiSelect, EuiFormRow, EuiFlexGroup, EuiFlexItem,
    useEuiTheme,
    EuiHorizontalRule
} from '@elastic/eui';
import { useSearchParams, useParams, useNavigate } from "react-router-dom";
import AccountContext from './AccountContext';
import CompanyLeads from './CompanyLeads';
import IndividualLeads from './IndividualLeads';

const companiesPath = 'companies'
const individualsPath = 'individuals'

export default function LeadsV2() {
    let euiTheme = useEuiTheme().euiTheme;
    const navigate = useNavigate();
    const { brandId, tab } = useParams();
    const accountContext = useContext(AccountContext)
    const onChangeBrand = (e) => {
        navigate(`../${e.target.value}/${tab}`);
    };

    // Custom tab like button styles
    const unSelectedTabStyle = {
        "color": euiTheme.colors.text
    }
    const selectedTabStyle = {
        "backgroundColor": euiTheme.colors.lightestShade,
        "color": euiTheme.colors.link
    }

    useEffect(() => {
        // Pick first brand with featureLeads if none specified
        if (!brandId) {
            const navBrand = (accountContext?.account?.brands || []).find(b => b.featureLeads)?.brandId
            if (navBrand) navigate(`${navBrand}/${companiesPath}`)
        }
        else if (!tab) navigate(companiesPath)
    }, [brandId, tab]);

    return (
        <React.Fragment>
            <EuiFlexGroup>
                <EuiFlexItem grow={false}>
                    <EuiTitle size="m">
                        <h1>Leads</h1>
                    </EuiTitle>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <EuiFormRow>
                        <EuiSelect
                            options={(accountContext?.account?.brands || []).map(b => {
                                return { value: b.brandId, text: b.brandName, disabled: !b.featureLeads }
                            })}
                            value={brandId || ''}
                            onChange={(e) => onChangeBrand(e)}
                        />
                    </EuiFormRow>
                </EuiFlexItem>
            </EuiFlexGroup>
            <EuiSpacer />
            {brandId &&
                <EuiFlexGroup responsive={false} wrap gutterSize="s" alignItems="center">
                    <EuiFlexItem grow={false}>
                        <EuiButtonEmpty
                            style={tab === companiesPath ? selectedTabStyle : unSelectedTabStyle}
                            onClick={() => navigate(`../${companiesPath}`, { relative: "path" })}>
                            By Company
                        </EuiButtonEmpty>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                        <EuiButtonEmpty
                            style={tab === individualsPath ? selectedTabStyle : unSelectedTabStyle}
                            onClick={() => navigate(`../${individualsPath}`, { relative: "path" })}>
                            By Individual
                        </EuiButtonEmpty>
                    </EuiFlexItem>
                </EuiFlexGroup>
            }
            <EuiHorizontalRule />
            {brandId && tab === companiesPath && <CompanyLeads brandId={brandId} />}
            {brandId && tab === individualsPath && <IndividualLeads brandId={brandId} />}
        </React.Fragment>
    )
}