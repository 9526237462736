import { useState, useContext } from "react"
import { EuiButton, EuiButtonEmpty, EuiComboBox, EuiFieldText, EuiForm, EuiFormRow, EuiFlyout, EuiFlyoutBody, EuiFlyoutFooter, EuiFlyoutHeader, EuiFlyoutHeaderTitle, EuiTitle, EuiSpacer, EuiFlexItem, EuiFlexGroup, EuiModalFooter, EuiModalBody, EuiModal, EuiModalHeader, EuiModalHeaderTitle } from "@elastic/eui"

export const UserAdd = (props) => {

  const [email, setEmail] = useState('');
  const onEmailChanged = (e) => setEmail(e.target.value);

  function onSave() {
    props.done({ email })
  }
  function onCancel() {
    props.cancel()
  }

  return (
    <EuiModal onClose={() => onCancel()} initialFocus="[name=email]">
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <h1>Invite User</h1>
        </EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
        <EuiForm component="form">
          <EuiFormRow label="Email">
            <EuiFieldText value={email} name="email" onChange={(e) => onEmailChanged(e)} />
          </EuiFormRow>
        </EuiForm>
      </EuiModalBody>

      <EuiModalFooter>
        <EuiButtonEmpty onClick={() => onCancel()}>Cancel</EuiButtonEmpty>
        <EuiButton onClick={() => onSave()} fill>
          Save
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  )
}