
import { EuiButton, EuiButtonEmpty, EuiImage, EuiLink, EuiSpacer, EuiText, EuiTextColor } from '@elastic/eui';
import { useEffect, useState } from 'react';
import { useFilePicker } from 'use-file-picker';
import useAxios from 'axios-hooks';
import axios from 'axios'
import { makeUseAxios } from 'axios-hooks'

const useAxiosUpload = makeUseAxios({
    axios: axios.create()
})

export default function FileLoader(props) {

    const [openFileSelector, { loading, errors, plainFiles, clear }] = useFilePicker({
        multiple: false,
        accept: ['.pdf'],
        limitFilesConfig: { min: 1, max: 1 },
        maxFileSize: 10, // Mb
        readFilesContent: false,
    });

    // Get file upload url
    const [
        { loading: gettingFileUploadUrl, error: errorGettingFileUploadUrl }, getFileUploadUrl
    ] = useAxios({
        url: '/data/cdn',
        method: 'POST'
    }, {
        manual: true
    })
    // Upload file
    const [
        { loading: uploadingFile, error: errorUploadingFile }, uploadFile
    ] = useAxiosUpload({
        method: 'POST'
    }, {
        manual: true
    })

    const processFileUpload = async (file) => {
        const { data: fileUrlResponse } = await getFileUploadUrl({
            data: {
                "accountId": props.accountId,
                "brandId": props.brandId,
                "fileName": file.name,
                "fileType": file.type
            }
        })
        const resp = await fetch(fileUrlResponse.uploadUrl, {
            method: 'PUT',
            body: file,
            headers: {
                'Content-Type': file.type,
            },
        })
        return fileUrlResponse.downloadUrl
    }

    const onChangeFile = async (file) => {
        const downloadUrl = await processFileUpload(file, file.name)
        props.onChange(downloadUrl)
    }

    useEffect(() => {
        if (!!plainFiles[0]) onChangeFile(plainFiles[0])
    }, [plainFiles]);

    return (
        <div>
            <span>
                {(!!props.downloadUrl) && <EuiLink href={props.downloadUrl}>Download File</EuiLink>}
                {(!props.downloadUrl) && <EuiTextColor color='subdued'>No File Uploaded</EuiTextColor>}
                <EuiButton isLoading={gettingFileUploadUrl || uploadingFile} style={{ marginLeft: "25px" }} size='s' onClick={() => openFileSelector()}>Upload File</EuiButton>
            </span>
            {!!errors.length &&
                <div>
                    <EuiText color='danger'>Something went wrong:</EuiText>
                    <EuiText color='danger'>
                        {errors[0].fileSizeTooSmall && 'File size is too small!'}
                        {errors[0].fileSizeToolarge && 'File size is too large!'}
                        {errors[0].readerError && 'Problem occured while reading file!'}
                        {errors[0].maxLimitExceeded && 'Too many files'}
                        {errors[0].minLimitNotReached && 'Not enough files'}
                    </EuiText>
                </div>
            }
        </div>
    )
}