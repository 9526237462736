import { Fragment } from 'react'; import {
    EuiEmptyPrompt,
    EuiButton,
    EuiButtonEmpty,
} from '@elastic/eui';
import { useNavigate } from "react-router-dom";

export default function Error() {
    let navigate = useNavigate();
    return (
        <EuiEmptyPrompt
            iconType="faceSad"
            color="subdued"
            title={<h2>Error</h2>}
            layout="vertical"
            body={
                <p>
                    Sorry, we ran into a little trouble.
                </p>
            }
            actions={[
                <EuiButton color="primary" fill onClick={() => { navigate('/') }}>
                    Go home
                </EuiButton>,
                <EuiButtonEmpty iconType="arrowLeft" flush="both" onClick={() => { navigate(-1) }}>
                    Go back
                </EuiButtonEmpty>,
            ]}
        />
    );
}