import { EuiBottomBar, EuiButton, EuiFlexGroup, EuiFlexItem } from '@elastic/eui';
import { Fragment, useState, useEffect, useContext } from 'react';
import useAxios from 'axios-hooks';
import EditableList from './EditableList';
import AccountContext from './AccountContext';

export default function AccountDivisions(props) {
  const accountContext = useContext(AccountContext);
  const [hasChanges, setHasChanges] = useState(false);
  const [divisions, setDivisions] = useState(props.divisions || []);
  useEffect(() => {
    setHasChanges(
      divisions.map(d => JSON.stringify(d)).toString() !== (props.divisions || []).map(d => JSON.stringify(d)).toString()
    );
  }, [
    divisions
  ]);
  function onListChange(newDivisions) {
    setDivisions(newDivisions)
  }
  // Save account divisions
  const [
    { data, loading: updating, error: errorUpdating }, executeUpdate
  ] = useAxios({
    url: '/data/account-divisions',
    method: 'POST'
  }, {
    manual: true
  })
  async function update() {
    await executeUpdate({
      data: {
        "accountId": accountContext?.account?.accountId,
        "divisions": divisions
      }
    })
    props.refetch()
  }

  const divIndx = {};
  accountContext.account.brands.forEach(b => {
    if (b.divisionId) {
      if (!divIndx[b.divisionId]) divIndx[b.divisionId] = [];
      divIndx[b.divisionId].push(b.brandName)
    }
  })

  return (
    <Fragment>
      <EditableList onListChange={onListChange} list={divisions} badges={divisions.map(d => {
        return {
          label: divIndx[d.id]?.length || 0,
          title: "Brands",
          tooltipContent: divIndx[d.id]?.length ? divIndx[d.id].join(" | ") : "No Brands"
        }
      })} />
      {hasChanges && <EuiBottomBar>
        <EuiFlexGroup justifyContent="flexEnd">
          <EuiFlexItem grow={false}>
            <EuiButton disabled={updating} onClick={() => props?.refetch()} color="ghost" size="s" iconType="save">
              Discard Changes
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton isLoading={updating} color="primary" fill size="s" iconType="save"
              onClick={() => { update() }}>
              Save Changes
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiBottomBar>}
    </Fragment>
  );
};