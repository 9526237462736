import React, { useState, useContext, useEffect } from 'react';
import {
    EuiBasicTable, EuiTitle, EuiSpacer, EuiProgress, EuiLink,
    EuiSelect, EuiFormRow, EuiFlexGroup, EuiFlexItem, EuiText, htmlIdGenerator,
    EuiModalFooter, EuiModalBody, EuiModal, EuiModalHeader, EuiModalHeaderTitle, EuiButton, EuiButtonEmpty, EuiGlobalToastList, EuiButtonIcon, EuiPopover, EuiEmptyPrompt, EuiDescriptionList
} from '@elastic/eui';
import { useParams, useSearchParams } from "react-router-dom";
import useAxios from 'axios-hooks';
import AccountContext from './AccountContext';
import fileDownload from 'js-file-download';
import LeadDetails from './LeadDetails';


export default function IndividualLeads({ brandId }) {
    const accountContext = useContext(AccountContext)
    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const [showLeadScoreDetails, setShowLeadScoreDetails] = useState({});
    const [showBrandsDetails, setShowBrandsDetails] = useState({});
    const [showMeetingRequestDetails, setShowMeetingRequestDetails] = useState({});

    // Get leads
    const [{ data: leadResults, loading, error }, getLeads] = useAxios(
        {
            url: '/data/leads',
            method: 'GET'
        },
        { manual: true }
    );

    // Archive lead
    const [
        { loading: archivingLead, error: errorArchivingLead }, executeArchiveLead
    ] = useAxios({
        url: '/data/leads',
        method: 'DELETE'
    }, {
        manual: true
    })

    async function archiveLead(lead) {
        await executeArchiveLead({
            data: lead
        })
            .then(() => {
                addToast({ title: "Lead archived successfully.", color: 'success' })
            })
            .catch((e) => {
                addToast({ title: `Error. Lead not archived.`, color: 'danger' })
            })
            .finally(() => {
            });
        getLeads({
            params: {
                accountId: params.accountId,
                brandId,
                swiftrevScore: swiftrevLeadScore,
                prType: nurtureStep
            }
        });
    }

    const colsLeadScoreDetails = [
        {
            field: 'leadScore',
            name: 'SwiftRev Lead Score',
            width: "150px",
            render: (leadScore) => {
                return (
                    <EuiFlexGroup component="span" gutterSize="s" alignItems='center'>
                        <EuiFlexItem grow={false}>
                            <EuiText>{leadScore?.toString() || '0'}</EuiText>
                        </EuiFlexItem>
                        <EuiFlexItem grow={true}>
                            <EuiProgress size='l' value={leadScore || 0} max={100}></EuiProgress>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                )
            }
        },
        {
            field: 'handleitCount',
            name: 'HandleIts',
            width: "115px",
            dataType: 'number'
        },
        {
            field: 'leadScoreDetails.opensScore',
            name: '# Email Opens',
            width: "115px",
            dataType: 'number',
            render: (opensScore) => {
                return (opensScore || 0) / 2
            }
        },
        {
            field: 'leadScoreDetails.clicksScore',
            name: '# Email Clicks',
            width: "115px",
            dataType: 'number',
            render: (clicksScore) => {
                return (clicksScore || 0) / 5
            }
        },
    ]

    const columns = [
        {
            field: 'email',
            name: 'Email',
            dataType: 'string',
            width: "500px"
        },
        {
            field: 'leadScore',
            name: 'SwiftRev Lead Score',
            width: "115px",
            render: (leadScore, lead) => {
                // return <EuiCallOut style={{ width: "115px", textAlign: "center" }} title={leadScoreDetails?.leadScore || '0'} />
                return (
                    <EuiFlexGroup component="span" gutterSize="s" alignItems='center'>
                        <EuiFlexItem grow={false}>
                            <EuiText>{(leadScore || 0).toString() || '0'}</EuiText>
                        </EuiFlexItem>
                        <EuiFlexItem grow={true}>
                            <EuiProgress size='l' value={leadScore || 0} max={100}></EuiProgress>
                        </EuiFlexItem>
                        <EuiFlexItem grow={false}>
                            <EuiPopover
                                anchorPosition="downCenter"
                                panelStyle={{ maxWidth: "800px" }}
                                isOpen={showLeadScoreDetails[lead.email] === true}
                                closePopover={() => setShowLeadScoreDetails({ ...showLeadScoreDetails, [lead.email]: false })}
                                button={<EuiButtonIcon aria-label='show lead score details' onClick={() => setShowLeadScoreDetails({ ...showLeadScoreDetails, [lead.email]: true })} iconSize='m' iconType='questionInCircle'></EuiButtonIcon>}
                            >
                                <EuiFlexGroup>
                                    <EuiFlexItem grow={false}>
                                        <EuiTitle size='m'><span>Lead Score Details</span></EuiTitle>
                                        <EuiSpacer size='xs'></EuiSpacer>
                                        <EuiLink target="_blank" href="https://www.swiftrev.com/guides/swiftrev-lead-score-detail/">More Info</EuiLink>
                                    </EuiFlexItem>
                                    <EuiFlexItem grow={true}>

                                    </EuiFlexItem>
                                    <EuiFlexItem grow={false}>
                                        <EuiButtonIcon aria-label='close popup' iconType="cross" onClick={() => setShowLeadScoreDetails({ ...showLeadScoreDetails, [lead.email]: false })} />
                                    </EuiFlexItem>
                                </EuiFlexGroup>
                                <EuiSpacer></EuiSpacer>
                                <EuiText><span style={{ backgroundColor: "#F1F2F2" }}>{lead.email}</span></EuiText>
                                <EuiSpacer></EuiSpacer>
                                <EuiBasicTable
                                    tableLayout='auto'
                                    className='table-wrap-col-header'
                                    isSelectable={false}
                                    items={[lead]}
                                    columns={colsLeadScoreDetails}>
                                </EuiBasicTable>
                            </EuiPopover>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                )
            }
        },
        {
            field: 'handleitCount',
            name: 'HandleIts',
            dataType: 'number',
            render: (handleitCount, lead) => {
                return (
                    <EuiButtonEmpty onClick={() => setModalLeadDetails(lead)}>{handleitCount}</EuiButtonEmpty>
                )
            }
        },
        {
            field: 'paidCount',
            name: 'Paid PRs',
            dataType: 'number',

        },
        {
            field: 'meetingRequested',
            name: 'Meeting Requested',
            width: "50px",
            dataType: 'string',
            render: (meetingRequested, lead) => {
                return (
                    <EuiPopover
                        button={(<EuiButtonEmpty disabled={meetingRequested !== 'yes'} onClick={() => setShowMeetingRequestDetails({ ...showMeetingRequestDetails, [lead.email]: true })}>{meetingRequested === 'yes' ? 'Yes' : 'No'}</EuiButtonEmpty>)}
                        anchorPosition="downCenter"
                        panelStyle={{ maxWidth: "800px" }}
                        isOpen={showMeetingRequestDetails[lead.email] === true}
                        closePopover={() => setShowMeetingRequestDetails({ ...showMeetingRequestDetails, [lead.email]: false })}
                    >
                        <EuiFlexGroup>
                            <EuiFlexItem grow={true}>
                                <EuiTitle size='m'><span>Meeting Request</span></EuiTitle>
                            </EuiFlexItem>
                            <EuiFlexItem grow={false}>
                                <EuiButtonIcon aria-label='close popup' iconType="cross" onClick={() => setShowMeetingRequestDetails({ ...showMeetingRequestDetails, [lead.email]: false })} />
                            </EuiFlexItem>
                        </EuiFlexGroup>
                        <EuiSpacer></EuiSpacer>
                        <EuiText><span style={{ backgroundColor: "#F1F2F2" }}>{lead.email}</span></EuiText>
                        <EuiSpacer></EuiSpacer>
                        <EuiText size='s'>When the request was submitted an email was sent to all sales users connected to this brand.</EuiText>
                        <EuiSpacer></EuiSpacer>
                        <EuiDescriptionList
                            type="column"
                            compressed={true}
                            titleProps={{ style: { marginTop: "5px" } }}
                            descriptionProps={{ style: { marginTop: "5px" } }}
                            listItems={[
                                { "title": 'Date Requested', "description": new Date(lead?.meetingRequest?.createdAt).toLocaleDateString() },
                                { "title": 'Email', "description": lead?.meetingRequest?.email },
                                { "title": 'Phone', "description": lead?.meetingRequest?.phone },
                                { "title": 'Name', "description": lead?.meetingRequest?.name },
                                { "title": 'Company', "description": lead?.meetingRequest?.company },
                                { "title": 'Persona', "description": lead?.meetingRequest?.role === 'marketer' ? 'Corporate Marketer' : 'PR Firm / Agency' },
                                { "title": 'Location', "description": `${lead?.meetingRequest?.country} / ${lead?.meetingRequest?.state}` },
                                { "title": 'Comments', "description": lead?.meetingRequest?.extra }
                            ]}
                            style={{ maxWidth: '400px' }}
                        ></EuiDescriptionList>
                    </EuiPopover>
                )
            }
        },
        {
            field: 'nurtureStep',
            name: 'Nurture Step',
            render: (nurtureStep) => {
                if (nurtureStep === -1) return '-'
                else if (nurtureStep === 0) return 'Preparing'
                else if (nurtureStep === 8) return 'Complete'
                else return nurtureStep
            }
        },
        {
            field: 'leadBrands',
            name: 'Brands Engaged',
            dataType: 'number',
            render: (leadBrands, lead) => {
                return (
                    <EuiPopover
                        button={(<EuiButtonEmpty disabled={(leadBrands || []).length <= 1} onClick={() => setShowBrandsDetails({ ...showBrandsDetails, [lead.email]: true })}>{(leadBrands || []).length || 0}</EuiButtonEmpty>)}
                        anchorPosition="downCenter"
                        panelStyle={{ maxWidth: "800px" }}
                        isOpen={showBrandsDetails[lead.email] === true}
                        closePopover={() => setShowBrandsDetails({ ...showBrandsDetails, [lead.email]: false })}
                    >
                        <EuiFlexGroup>
                            <EuiFlexItem grow={true}>
                                <EuiTitle size='m'><span>Engaged Brands</span></EuiTitle>
                            </EuiFlexItem>
                            <EuiFlexItem grow={false}>
                                <EuiButtonIcon iconType="cross" onClick={() => setShowBrandsDetails({ ...showBrandsDetails, [lead.email]: false })} />
                            </EuiFlexItem>
                        </EuiFlexGroup>
                        <EuiSpacer></EuiSpacer>
                        <EuiText><span style={{ backgroundColor: "#F1F2F2" }}>{lead.email}</span></EuiText>
                        <EuiSpacer></EuiSpacer>
                        <EuiText size='s'>This contact has presented press releases to the following brands:</EuiText>
                        <EuiSpacer size='s'></EuiSpacer>
                        <EuiText size='s'>
                            <ul>
                                {(leadBrands || []).map(brandId => {
                                    return (
                                        <li>{accountContext?.account?.brandNameIndx[brandId]}</li>
                                    )
                                })}
                            </ul>
                        </EuiText>
                    </EuiPopover>
                )
            }
        },
        {
            field: 'lastEngagement',
            name: 'Last Engagement',
            dataType: 'string',
            render: (lastEngagement) => {
                return `${lastEngagement} days ago`
            }
        },
        {
            name: 'Actions',
            width: "75px",
            render: (item) => {
                return (
                    <EuiButtonIcon aria-label='archive lead' size='s' color="danger" iconType="logstashInput" onClick={() => setModalLead(item)}></EuiButtonIcon>
                );
            }
        }
    ];

    const [swiftrevLeadScore, setSwiftrevLeadScore] = useState(searchParams.get('swiftrevLeadScore') || "any");
    const onChangeSwiftrevScore = (e) => {
        setSwiftrevLeadScore(e.target.value);
    };

    const [nurtureStep, setNurtureStep] = useState(searchParams.get('nurtureStep') || "any");
    const onChangePrType = (e) => {
        setNurtureStep(e.target.value);
    };

    const [modalLead, setModalLead] = useState(null);


    const [modalLeadDetails, setModalLeadDetails] = useState(null);

    useEffect(() => {
        setSearchParams({
            swiftrevLeadScore,
            nurtureStep
        })
        getLeads({
            params: {
                accountId: params.accountId,
                brandId,
                swiftrevLeadScore,
                nurtureStep
            }
        });
    }, [brandId, swiftrevLeadScore, nurtureStep]);

    const [toasts, setToasts] = useState([]);
    const genToastId = htmlIdGenerator('toast');
    const addToast = (toast) => {
        toast.id = genToastId();
        setToasts(toasts.concat(toast));
    };
    const removeToast = removedToast => {
        setToasts(toasts.filter(toast => toast.id !== removedToast.id));
    };


    // Download leads
    const [{ loading: downloadingLeads, error: errorDownloadingLeads }, execDownloadLeads] = useAxios(
        {
            url: '/data/download-leads',
            method: 'GET'
        },
        { manual: true }
    );
    const downLoadLeads = async () => {
        const res = await execDownloadLeads({
            params: {
                accountId: params.accountId,
                brandId
            }
        });
        fileDownload(res.data, `${params.accountId} ${brandId} leads.csv`);
    }

    const [sort, setSort] = useState({ field: 'leadScore', direction: 'desc' })

    const onTableChange = ({ sort }) => {
        if (sort) {
            setSort(sort)
        }
    };

    const [sortedLeads, setSortedLeads] = useState([])

    useEffect(() => {
        if (sort) {
            const sorted = (leadResults?.leads || []).toSorted((a, b) => {
                let aVal = sort.field.split('.').reduce(function (p, prop) { return p[prop] }, a)
                let bVal = sort.field.split('.').reduce(function (p, prop) { return p[prop] }, b)
                if (Array.isArray(aVal)) aVal = aVal.length
                if (Array.isArray(bVal)) bVal = bVal.length
                if (aVal === bVal) return 0
                else if (aVal < bVal && sort.direction === 'asc') return -1
                else if (aVal < bVal && sort.direction === 'desc') return 1
                else if (aVal > bVal && sort.direction === 'asc') return 1
                else if (aVal > bVal && sort.direction === 'desc') return -1
            })
            setSortedLeads(sorted)
        }
    }, [leadResults, sort])

    return (
        <React.Fragment>
            <EuiGlobalToastList
                toasts={toasts}
                dismissToast={removeToast}
                toastLifeTimeMs={3000}
            />
            <EuiText>Note - only leads less than 60 days old are shown in the table below. Click "Download" to get all leads, ever.</EuiText>
            <EuiSpacer />
            <EuiFlexGroup>
                <EuiFlexItem grow={false}>
                    <EuiFormRow label="SwiftRev Lead Score">
                        <EuiSelect
                            hasNoInitialSelection={true}
                            options={[
                                { value: "any", text: "Any" },
                                { value: "high", text: "High (40-100)" },
                                { value: "medium", text: "Medium (10-39)" },
                                { value: "low", text: "Low (0-9)" }
                            ]}
                            value={swiftrevLeadScore}
                            onChange={(e) => onChangeSwiftrevScore(e)}
                        />
                    </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <EuiFormRow label="Nurture Step">
                        <EuiSelect
                            hasNoInitialSelection={true}
                            options={[
                                { value: "any", text: "Any" },
                                { value: "8", text: "Step 8" },
                                { value: "7", text: "Step 7" },
                                { value: "6", text: "Step 6" },
                                { value: "5", text: "Step 5" },
                                { value: "4", text: "Step 4" },
                                { value: "3", text: "Step 3" },
                                { value: "2", text: "Step 2" },
                                { value: "1", text: "Step 1" }
                            ]}
                            value={nurtureStep}
                            onChange={(e) => onChangePrType(e)}
                        />
                    </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem grow={true} />
                <EuiFlexItem grow={false}>
                    <EuiButton isLoading={downloadingLeads} onClick={() => downLoadLeads()} iconType="download" >Download All Leads</EuiButton>
                </EuiFlexItem>
            </EuiFlexGroup>
            <EuiSpacer />
            <EuiProgress hidden={!loading && !archivingLead} size="xs" color="primary" />
            {!loading && !archivingLead && leadResults?.leads &&
                < EuiBasicTable
                    tableCaption="Leads"
                    items={sortedLeads || []}
                    onChange={onTableChange}
                    sorting={{ sort: sort, enableAllColumns: true }}
                    columns={columns}
                    tableLayout="auto"
                    hasActions={true}
                    noItemsMessage={
                        <EuiEmptyPrompt
                            title={(<EuiTitle size='m'><span>{leadResults.featureLeads === true ? 'No leads yet!' : 'Leads Feature Inactive'}</span></EuiTitle>)}
                            body={(<EuiText>{leadResults.featureLeads === true ? 'The editors can help. Ask them to start forwarding "HandleIt\'s" - the press releases they don\'t intend to cover.' : 'You do not have access to this feature. Contact your admin to unlock it.'}</EuiText>)}
                        >

                        </EuiEmptyPrompt>
                    }
                />}
            {modalLead &&
                <EuiModal onClose={() => { setModalLead(null) }}>
                    <EuiModalHeader>
                        <EuiModalHeaderTitle>
                            <h1>Are you sure you want to archive this lead?</h1>
                        </EuiModalHeaderTitle>
                    </EuiModalHeader>

                    <EuiModalBody>
                        <EuiText><strong>{modalLead.email}</strong></EuiText>
                        <EuiSpacer />
                        <EuiText>Note - Lead details will still be accesible via csv file download.</EuiText>
                    </EuiModalBody>

                    <EuiModalFooter style={{ "justifyContent": "flex-start", "alignContent": "center" }}>
                        <EuiButton onClick={() => { archiveLead(modalLead); setModalLead(null); }} fill>
                            Yes, I am sure
                        </EuiButton>
                        <EuiButtonEmpty size='s' onClick={() => { setModalLead(null); }}>No, return to previous screen</EuiButtonEmpty>
                    </EuiModalFooter>
                </EuiModal>
            }
            {modalLeadDetails &&
                <EuiModal onClose={() => { setModalLeadDetails(null) }} >
                    <EuiModalHeader>
                        <EuiModalHeaderTitle>HandleIts <EuiText size='m' color='subdued'>{modalLeadDetails.email}</EuiText></EuiModalHeaderTitle>
                    </EuiModalHeader>
                    <EuiModalBody>
                        <LeadDetails email={modalLeadDetails.email} accountId={params.accountId} brandId={brandId}></LeadDetails>
                    </EuiModalBody>
                </EuiModal>
            }
        </React.Fragment>
    );
};