import { Fragment, useState, useEffect } from 'react';
import {
    EuiForm,
    EuiFormRow,
    EuiFieldText,
    EuiDescribedFormGroup,
    EuiCodeBlock,
    EuiBottomBar,
    EuiFlexGroup,
    EuiFlexItem,
    EuiButton,
    EuiSelect,
    EuiSpacer,
    EuiBetaBadge,
    EuiFieldPassword
} from '@elastic/eui';
import useAxios from 'axios-hooks';
import { func } from 'prop-types';

export default function BrandWidgets(props) {
    const feedDurationOptions = [
        { value: 7, text: 'Past 7 Days' },
        { value: 30, text: 'Past 30 Days' },
        { value: -1, text: 'No Limit' },
    ];
    const feedCountOptions = [
        { value: 5, text: '5' },
        { value: 10, text: '10' },
        { value: 25, text: '25' },
    ];

    const [hasChanges, setHasChanges] = useState(false);

    const [feedDuration, setFeedDuration] = useState(props.brand.feedDuration || feedDurationOptions[0].value);
    const onChangeFeedDuration = (e) => {
        setFeedDuration(parseInt(e.target.value));
    };
    const [feedCount, setFeedCount] = useState(props.brand.feedCount || feedCountOptions[0].value);
    const onChangeFeedCount = (e) => {
        setFeedCount(parseInt(e.target.value));
    };

    const [prFeedUrl, setPrFeedUrl] = useState(props.brand.prFeedUrl);
    const onChangePrFeedUrl = (e) => {
        setPrFeedUrl(e.target.value);
    };

    const [prWebhookUrl, setPrWebhookUrl] = useState(props.brand.prWebhookUrl);
    const onChangePrWebhookUrl = (e) => {
        setPrWebhookUrl(e.target.value);
    };

    const [webhookToken] = useState(props.brand.webhookToken);

    // Send latest PR to webhook
    const [
        { data: resultsPRWebhook, loading: loadingPRWebhook, error: errorPRWebhook }, sendLatestPR
    ] = useAxios({
        url: '/data/brands/send-pr-webhook',
        method: 'POST'
    }, {
        manual: true
    })
    async function onSendLatestPRClick() {
        await sendLatestPR({
            "data": {
                "accountId": props.brand.accountId,
                "brandId": props.brand.brandId
            }
        })
    }


    useEffect(() => {
        setHasChanges(
            prFeedUrl !== props.brand.prFeedUrl ||
            feedCount !== props.brand.feedCount ||
            feedDuration !== props.brand.feedDuration ||
            prWebhookUrl !== props.brand.prWebhookUrl
        );
    }, [
        prFeedUrl,
        feedCount,
        feedDuration,
        prWebhookUrl
    ]);
    // Save brand marketing updates
    const [
        { data: updateResults, loading: updating, error: errorUpdating }, executeUpdate
    ] = useAxios({
        url: '/data/brands/update-widgets',
        method: 'POST'
    }, {
        manual: true
    })
    async function update() {
        await executeUpdate({
            data: {
                "accountId": props.brand.accountId,
                "brandId": props.brand.brandId,
                prFeedUrl,
                feedCount,
                feedDuration,
                prWebhookUrl
            }
        })
        props.refetch()
    }
    return (
        <Fragment>
            <EuiForm component="form">
                <EuiDescribedFormGroup fullWidth
                    ratio="third"
                    title={<h2>Webhook Settings <EuiBetaBadge label="Beta" size='s' /></h2>}
                    description={
                        <Fragment>
                            Settings to enable webhooks.
                        </Fragment>
                    }
                >
                    <EuiFormRow label="Webhook Token" helpText="Token will be included in the 'x-swiftrev-token' header.">
                        <EuiFieldPassword fullWidth value={webhookToken} readOnly type='dual' />
                    </EuiFormRow>
                    <EuiSpacer size='m' />
                    <EuiFormRow fullWidth label="Press Release Webhook" helpText="Full url of the webhook to receive press release updates.">
                        <EuiFieldText fullWidth value={prWebhookUrl} onChange={(e) => onChangePrWebhookUrl(e)} name="prWebhookUrl" placeholder='https://webhook.site/abcde' />
                    </EuiFormRow>
                    <EuiFormRow>
                        <EuiButton isDisabled={props.brand.prWebhookUrl == null} isLoading={loadingPRWebhook} onClick={() => onSendLatestPRClick()}>Send Latest Press Release</EuiButton>
                    </EuiFormRow>
                </EuiDescribedFormGroup>
                <EuiDescribedFormGroup fullWidth
                    ratio="third"
                    title={<h2>Widget Settings</h2>}
                    description={
                        <Fragment>
                            Settings to control widget links and controls.
                        </Fragment>
                    }
                >
                    <EuiFlexGroup >
                        <EuiFlexItem>
                            <EuiFormRow label="Feed Timeline" helpText="Only include press released posted within the selected timeline">
                                <EuiSelect
                                    options={feedDurationOptions}
                                    value={feedDuration}
                                    onChange={(e) => onChangeFeedDuration(e)}
                                />
                            </EuiFormRow>
                        </EuiFlexItem>
                        <EuiFlexItem>
                            <EuiFormRow label="Feed Page Size" helpText="Maximum number of press releases per page">
                                <EuiSelect
                                    options={feedCountOptions}
                                    value={feedCount}
                                    onChange={(e) => onChangeFeedCount(e)}
                                />
                            </EuiFormRow>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                    <EuiSpacer size='m' />
                    <EuiFormRow isInvalid={!prFeedUrl} fullWidth label="Press release news feed url" helpText="Full url of the page that hosts the feed widget.">
                        <EuiFieldText fullWidth value={prFeedUrl} onChange={(e) => onChangePrFeedUrl(e)} name="feedUrl" placeholder='https://example.com/news' />
                    </EuiFormRow>
                </EuiDescribedFormGroup>
            </EuiForm>
            <EuiDescribedFormGroup fullWidth
                ratio="third"
                title={<h2>Widget Code</h2>}
                description={
                    <Fragment>
                        Place this code in your press release feed and press release details websites.
                    </Fragment>
                }
            >
                <EuiFormRow fullWidth>
                    <EuiCodeBlock isCopyable={true} language="js">
                        {`<div id="swiftrev-widget" data-swiftrev-feed="https://${process.env.REACT_APP_PR_DOMAIN}/feed/${props.brand.accountId}/${props.brand.brandId}"></div><script defer src="https://${process.env.REACT_APP_CDN_DOMAIN}/static/widget-feed.js"></script>`}
                    </EuiCodeBlock>
                </EuiFormRow>
            </EuiDescribedFormGroup>
            <EuiDescribedFormGroup fullWidth
                ratio="third"
                title={<h2>Widget Styles</h2>}
                description={
                    <Fragment>
                        Override these styles in your site's CSS to adjust the widget look and feel.
                    </Fragment>
                }
            >
                <EuiFormRow fullWidth>
                    <EuiCodeBlock isCopyable={true} language="css">
                        {`
    # Styles for the press release feed
                        
        # Style for the press release title
        .sr-title {
            font-weight: bold;
            font-size: 18px;
            margin-bottom:5px;
        }

        # Style for the press release subtitle including date and company
        .sr-subtitle {
            color:gray;
            font-size: 14px;
        }

    # Styles for the individual press release

        # Press release title
        .sr-pr-title {
            font-weight: bold;
            font-size: 24px;
            margin-bottom:25px;
        }

        # Press release subtitles including date, about and contact headings
        .sr-pr-subtitle {
            font-weight: bold;
            font-size: 18px;
            margin-bottom:5px;
        }

        # Press release body section
        .sr-pr-body {
        }

        # Press release about section
        .sr-pr-about {
        }
                    
     # Styles for the button to load more press releases
     # {{brandColor}} is dynamically determined by the brand's primary color setting 
        .sr-btn {
            padding: 6px 12px 6px 12px;
            border-radius:5px;
            background-color: white;
            border: solid {{brandColor}} 1px;
            color: {{brandColor}};
        }
        .sr-btn:hover {
            background-color: {{brandColor}};
            color: white;
        }
                        `}
                    </EuiCodeBlock>
                </EuiFormRow>
            </EuiDescribedFormGroup>
            {hasChanges && <EuiBottomBar>
                <EuiFlexGroup justifyContent="flexEnd">
                    <EuiFlexItem grow={false}>
                        <EuiButton disabled={updating} onClick={() => props.refetch()} color="ghost" size="s" iconType="save">
                            Discard Changes
                        </EuiButton>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                        <EuiButton isLoading={updating} color="primary" fill size="s" iconType="save"
                            onClick={() => { update() }}>
                            Save Changes
                        </EuiButton>
                    </EuiFlexItem>
                </EuiFlexGroup>
            </EuiBottomBar>}
        </Fragment>
    );
};