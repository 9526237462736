import React, { useState, useContext, useEffect, Fragment } from 'react';
import {
    EuiBasicTable, formatDate, EuiTitle, EuiSpacer, EuiProgress, EuiCallOut, EuiLink,
    EuiSelect, EuiFormRow, EuiFlexGroup, EuiFlexItem, EuiText, htmlIdGenerator,
    EuiModalFooter, EuiModalBody, EuiModal, EuiModalHeader, EuiModalHeaderTitle, EuiButton, EuiButtonEmpty, EuiComboBox, EuiFieldText, EuiGlobalToastList, EuiToolTip, EuiTextArea
} from '@elastic/eui';
import { useParams, useNavigate } from "react-router-dom";
import useAxios from 'axios-hooks';
import AccountContext from './AccountContext';

export default function PressReleases() {
    const navigate = useNavigate();
    const accountContext = useContext(AccountContext)
    const params = useParams();
    const [{ data: prResults, loading, error }, getPrs] = useAxios(
        {
            url: '/data/prs',
            method: 'GET'
        },
        { manual: true }
    );

    const [
        { data: updateCoverageResult, loading: updatingCoverage, error: errorUpdatingCoverage }, executeUpdate
    ] = useAxios({
        url: '/data/pr-coverage',
        method: 'POST'
    }, {
        manual: true
    })


    const declinedMsg = "Press Release successfully declined and email sent to submitter. To see the submission, click on 'Show Declined' box in top filter."
    const claimedMsg = "Press Release successfully claimed."

    async function updateCoverage(pr, coverage, reason) {
        await executeUpdate({
            data: {
                "pk": pr.pk,
                "sk": pr.sk,
                "coverage": coverage,
                "reason": reason
            }
        })
            .then(() => {
                addToast({ title: coverage === "declined" ? declinedMsg : claimedMsg, color: 'success' })
            })
            .catch((e) => {
                addToast({ title: `Error. Press release not ${coverage}`, color: 'danger' })
            })
            .finally(() => {
            });
        getPrs({
            params: {
                accountId: params.accountId,
                brandId,
                swiftrevScore,
                prType
            }
        });
    }

    const columns = [
        {
            field: 'prPostDate',
            name: 'Post Date',
            dataType: 'date',
            render: (date) => formatDate(date, 'MM/DD/YYYY'),
            width: "115px"
        },
        {
            field: 'swiftrevScore',
            name: 'SwiftRev Score',
            width: "105px",
            render: (swiftrevScore) => {
                return <EuiCallOut style={{ width: "100px", textAlign: "center" }} title={swiftrevScore?.totalScoreDisplay} />
            }
            // render: (swiftrevScore) => swiftrevScore.totalScoreDisplay
        },
        {
            field: 'prStatus',
            name: 'Type',
            sortable: true,
            width: "100px",
            render: (prStatus, pr) => {
                return (
                    <div>
                        <div>{prStatus === 'submitted' ? 'Free' : 'Paid'}</div>
                        {pr.discountCode && <div><EuiText size='xs' color="subdued">Discounted</EuiText></div>}
                    </div>
                )
            }
        },
        {
            field: 'prTitle',
            name: 'Title',
            render: (prTitle, pr) => {
                return <EuiLink onClick={() => {
                    navigate(pr.brandId + '/' + pr.prSlug)
                }} >{prTitle}</EuiLink>
            }
        },
        {
            field: 'prClassificationData.labelName',
            name: 'Topic',
            width: "200px"
        },
        {
            field: 'company.name',
            name: 'Company',
            width: "200px"
        },
        {
            name: 'Editorial Coverage',
            width: "175px",
            render: (item) => {
                return (
                    <Fragment>
                        {(!item.coverage || item.coverage === 'tbd') &&
                            <EuiFlexGroup component="span" gutterSize="s" justifyContent="flexStart">
                                <EuiFlexItem grow={false}>
                                    <EuiToolTip
                                        position="left"
                                        content="Make clear to other editors on your team that you are intending to write an article based on this press release. Once claimed, visible to all other users. Cannot be 'unclaimed'."
                                    >
                                        <EuiButton size='s' color="success" onClick={() => updateCoverage(item, "claimed")}>
                                            Claim
                                        </EuiButton>
                                    </EuiToolTip>
                                </EuiFlexItem>
                                {item.prStatus === 'submitted' && <EuiFlexItem grow={false}>
                                    <EuiToolTip
                                        position="left"
                                        content="Don't want to write an article on this press release? No problem. Click 'Decline' and we'll automatically notify the submitter and encourage a transition from earned media to paid via a paid posting in your Press Release Channel. Note - the Decline option is not available on free submissions since the call to action when clicked is already done by the user."
                                    >
                                        <EuiButton size='s' color="danger" onClick={() => setModalPR(item)}>
                                            Decline
                                        </EuiButton>
                                    </EuiToolTip>
                                </EuiFlexItem>}
                            </EuiFlexGroup>}
                        {item.coverage === 'claimed' &&
                            <EuiFlexGroup direction='column' gutterSize="xs">
                                <EuiFlexItem><EuiText size='sm' color='success'>Claimed</EuiText></EuiFlexItem>
                                <EuiFlexItem><EuiText size='xs' style={{ fontSize: "10px" }}>{item.coverageUpdatedBy}</EuiText></EuiFlexItem>
                            </EuiFlexGroup>
                        }
                        {item.coverage === 'declined' &&
                            <EuiFlexGroup direction='column' gutterSize="xs">
                                <EuiFlexItem><EuiText size='sm' color='danger'>Declined</EuiText></EuiFlexItem>
                                <EuiFlexItem><EuiText size='xs' style={{ fontSize: "10px" }}>{item.coverageUpdatedBy === 'auto-decline' ? 'Automatically Declined' : item.coverageUpdatedBy}</EuiText></EuiFlexItem>
                            </EuiFlexGroup>
                        }
                    </Fragment>
                );
            }
        }
    ];

    const [brandId, setBrandId] = useState((accountContext?.account?.brands || [])[0]?.brandId || null);
    const onChangeBrand = (e) => {
        setBrandId(e.target.value);
    };

    const [swiftrevScore, setSwiftrevScore] = useState("Any");
    const onChangeSwiftrevScore = (e) => {
        setSwiftrevScore(e.target.value);
    };

    const [prType, setPrType] = useState("Any");
    const onChangePrType = (e) => {
        setPrType(e.target.value);
    };

    const [showDeclined, setShowDeclined] = useState(false);
    const onChangeShowDeclined = (val) => {
        setShowDeclined(val);
    };

    const [modalPR, setModalPR] = useState(null);

    useEffect(() => {
        getPrs({
            params: {
                accountId: params.accountId,
                brandId,
                swiftrevScore,
                prType,
                showDeclined: showDeclined
            }
        });
    }, [brandId, swiftrevScore, prType, showDeclined]);

    const declineOptions = [
        { value: 'Low value type of news', text: 'Low value type of news' },
        { value: 'Wrong audience', text: 'Wrong audience' },
        { value: 'Overly promotional', text: 'Overly promotional' },
        { value: 'Custom', text: 'Custom' },
    ];
    const [declineReason, setDeclineReason] = useState('');
    const [customDecline, setCustomDecline] = useState('');

    const [toasts, setToasts] = useState([]);
    const genToastId = htmlIdGenerator('toast');
    const addToast = (toast) => {
        toast.id = genToastId();
        setToasts(toasts.concat(toast));
    };
    const removeToast = removedToast => {
        setToasts(toasts.filter(toast => toast.id !== removedToast.id));
    };

    return (
        <React.Fragment>
            <EuiGlobalToastList
                toasts={toasts}
                dismissToast={removeToast}
                toastLifeTimeMs={3000}
            />
            <EuiTitle size="m">
                <h1>Press Releases</h1>
            </EuiTitle>
            <EuiSpacer />
            <EuiFlexGroup>
                <EuiFlexItem grow={false}>
                    <EuiFormRow label="Brand">
                        <EuiSelect
                            options={(accountContext?.account?.brands || []).map(b => {
                                return { value: b.brandId, text: b.brandName }
                            })}
                            value={brandId}
                            onChange={(e) => onChangeBrand(e)}
                        />
                    </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <EuiFormRow label="SwiftRev Score">
                        <EuiSelect
                            hasNoInitialSelection={true}
                            options={[
                                { value: "Any", text: "Any" },
                                { value: "High", text: "High" },
                                { value: "Medium", text: "Medium" },
                                { value: "Low", text: "Low" }
                            ]}
                            value={swiftrevScore}
                            onChange={(e) => onChangeSwiftrevScore(e)}
                        />
                    </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <EuiFormRow label="Type">
                        <EuiSelect
                            hasNoInitialSelection={true}
                            options={[
                                { value: "any", text: "Any" },
                                { value: "submitted", text: "Free" },
                                { value: "paid", text: "Paid" }
                            ]}
                            value={prType}
                            onChange={(e) => onChangePrType(e)}
                        />
                    </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <EuiFormRow label="Show Declined">
                        <EuiSelect
                            defaultValue={'no'}
                            options={[
                                { value: 'no', text: "No" },
                                { value: 'yes', text: "Yes" }
                            ]}
                            value={showDeclined}
                            onChange={(e) => onChangeShowDeclined(e.target.value)}
                        />
                    </EuiFormRow>
                </EuiFlexItem>
            </EuiFlexGroup>
            <EuiSpacer />
            <EuiProgress hidden={!loading && !updatingCoverage} size="xs" color="primary" />
            {!loading && !updatingCoverage && prResults?.items &&
                < EuiBasicTable
                    tableCaption="Press releases"
                    items={prResults?.items || []}
                    rowHeader="brandId"
                    columns={columns}
                    tableLayout="auto"
                    hasActions={true}
                />}
            {modalPR &&
                <EuiModal onClose={() => { setModalPR(null); setDeclineReason('') }} initialFocus="[name=email]">
                    <EuiModalHeader>
                        <EuiModalHeaderTitle>
                            <h1>Are you sure you want to decline editorial coverage?</h1>
                        </EuiModalHeaderTitle>
                    </EuiModalHeader>

                    <EuiModalBody>
                        <EuiText>Note - We will instantly send a message confirming this press release will not be covered and encourage the purchase of a submission in the paid press release channel.</EuiText>
                        <EuiSpacer />
                        <EuiFormRow label="Reason for decline">
                            <EuiSelect
                                hasNoInitialSelection={true}
                                options={declineOptions}
                                value={declineReason}
                                onChange={(e) => { setCustomDecline(e.target.value); setDeclineReason(e.target.value); }}
                            />
                        </EuiFormRow>
                        {declineReason === 'Custom' &&
                            <EuiFormRow fullWidth>
                                <EuiTextArea fullWidth
                                    resize={false}
                                    rows={2}
                                    placeholder="Custom decline reason"
                                    onChange={(e) => setCustomDecline(e.target.value)}
                                />
                            </EuiFormRow>
                        }
                    </EuiModalBody>

                    <EuiModalFooter style={{ "justifyContent": "flex-start" }}>
                        <EuiButton onClick={() => { updateCoverage(modalPR, "declined", customDecline); setModalPR(null); setCustomDecline(''); setDeclineReason(''); }} fill>
                            Yes, I am sure
                        </EuiButton>
                        <EuiButtonEmpty size='s' onClick={() => { setModalPR(null); setCustomDecline(''); setDeclineReason(''); }}>No, return to previous screen</EuiButtonEmpty>
                    </EuiModalFooter>
                </EuiModal>
            }
        </React.Fragment>
    );
};