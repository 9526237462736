import { Fragment, useState, useContext, useRef } from 'react';
import {
  EuiBasicTable,
  EuiAvatar,
  EuiSpacer,
  EuiButton,
  EuiFlexItem,
  EuiFlexGroup,
  EuiGlobalToastList,
  htmlIdGenerator,
  EuiLink,
  EuiText,
  formatDate,
  EuiButtonIcon,
  copyToClipboard,
  EuiToolTip,
  EuiModalFooter, EuiModalBody, EuiModal, EuiModalHeader, EuiModalHeaderTitle,
  EuiFieldText, EuiForm, EuiFormRow,
  EuiButtonEmpty
} from '@elastic/eui';
import { UserAdd } from './UserAdd';
import AccountContext from './AccountContext';
import useAxios from 'axios-hooks';
import { useParams, useNavigate } from "react-router-dom";

export default function BrandDiscountCodes(props) {
  const navigate = useNavigate();

  const accountContext = useContext(AccountContext);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const closeModal = () => setIsModalVisible(false);

  const [email, setEmail] = useState('');
  const onEmailChanged = (e) => setEmail(e.target.value);

  const [
    { loading: createDiscountCode, error: errorCreatingCode },
    executePut
  ] = useAxios(
    {
      url: '/data/discount-code',
      method: 'POST'
    },
    { manual: true }
  )

  const [
    { loading: removingCode, error: errorRemovingCode },
    executeRemoveCode
  ] = useAxios(
    {
      url: '/data/brands/discount-code',
      method: 'DELETE'
    },
    { manual: true }
  )

  function createCode() {
    closeModal()
    executePut({
      data: {
        accountId: accountContext.account.accountId,
        brandId: props?.brand?.brandId,
        customerEmail: email
      }
    })
      .then(() => {
        addToast({ title: "Discount code created!", color: 'success' })
        props.refetch()
      })
      .catch((e) => {
        addToast({ title: "Error creating discount code", color: 'danger' })
      })
      .finally(() => {
      })
  }

  function cancelCreateCode() {
    closeModal();
  }

  const deleteCode = (discountCode) => {
    executeRemoveCode({
      data: {
        discountCode: discountCode,
        accountId: accountContext.account.accountId,
        brandId: props?.brand?.brandId,
      }
    })
      .then(() => {
        addToast({ title: "Discount code removed", color: 'success' })
        props.refetch()
      })
      .catch((e) => {
        addToast({ title: "Error removing discount code", color: 'danger' })
      })
      .finally(() => {
      })
  };

  const [toasts, setToasts] = useState([]);
  const genToastId = htmlIdGenerator('toast');
  const addToast = (toast) => {
    toast.id = genToastId();
    setToasts(toasts.concat(toast));
  };
  const removeToast = removedToast => {
    setToasts(toasts.filter(toast => toast.id !== removedToast.id));
  };


  const buttonRef = useRef();
  const [isTextCopied, setTextCopied] = useState(false);
  const onClick = (text) => {
    copyToClipboard(text);
    setTextCopied(true);
  };
  const onBlur = () => {
    setTextCopied(false);
  };

  const userColumns = [
    {
      field: 'sk',
      name: 'Code',
      dataType: 'string',
      width: "350px",
      render: (sk) => {
        return (
          <EuiFlexGroup>
            <EuiFlexItem grow={false}>
              <EuiToolTip
                content={isTextCopied ? 'Code copied to clipboard' : 'Copy Code'}
              >
                <EuiButtonIcon
                  buttonRef={buttonRef}
                  aria-label="Copy text to clipboard"
                  color="text"
                  iconType="copy"
                  onClick={() => onClick(sk)}
                  onBlur={onBlur}
                />
              </EuiToolTip>
            </EuiFlexItem>
            <EuiFlexItem>{sk}</EuiFlexItem>
          </EuiFlexGroup>
        )
      }
    },
    {
      field: 'isk',
      name: 'Status',
      dataType: 'string',
      width: "100px"
    },
    {
      field: 'prSlug',
      name: 'Press Release',
      dataType: 'string',
      width: "125px",
      render: (prSlug, discountCode) => {
        if (discountCode.isk === 'used') {
          return <EuiLink onClick={() => {
            navigate('../../../press-releases/' + props.brand?.brandId + '/' + prSlug)
          }} >View</EuiLink>
        }
        else return 'n/a'
      }
    },
    {
      field: 'customerEmail',
      name: 'Customer Email',
      dataType: 'string',
      width: "300px"
    },
    {
      field: 'createdByEmail',
      name: 'Created By',
      dataType: 'string',
      width: "300px"
    },
    {
      field: 'createdAt',
      name: 'Created Date',
      dataType: 'string',
      width: "125px",
      render: (date) => formatDate(date, 'MM/DD/YYYY')
    },
    // {
    //   name: 'Actions',
    //   actions: [{
    //     name: 'Remove',
    //     description: 'Remove this code',
    //     icon: 'trash',
    //     color: 'danger',
    //     type: 'icon',
    //     onClick: deleteCode,
    //     isPrimary: true,
    //     'data-test-subj': 'action-delete',
    //     enabled: (discountCode) => !removingCode && !createDiscountCode && discountCode.isk === 'active'
    //   }],
    // }
  ]

  return (
    <Fragment>
      <EuiGlobalToastList
        toasts={toasts}
        dismissToast={removeToast}
        toastLifeTimeMs={3000}
      />
      <EuiText>
        <span>Create a Discount Code to be used by your customer online, enabling 100% off a new purchase. It can only be used once and never expires. </span>
        <EuiLink target='_blank' href='https://www.swiftrev.com/guides/discount-code/'>Find out more.</EuiLink>
      </EuiText>
      <EuiSpacer />
      <EuiFlexGroup>
        <EuiFlexItem grow={false}>
          <EuiButton size="s" isLoading={createDiscountCode} disabled={isModalVisible} fill onClick={() => setIsModalVisible(true)}>Create Code</EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />
      <EuiBasicTable
        tableCaption="Discount Codes"
        items={props?.brand?.discountCodes?.sort((a, b) => { return b.createdAt.localeCompare(a.createdAt) }) || []}
        rowHeader="createdAt"
        columns={userColumns}
        tableLayout="auto"
      />
      {
        isModalVisible &&
        <EuiModal onClose={() => cancelCreateCode()} initialFocus="[name=email]">
          <EuiModalHeader>
            <EuiModalHeaderTitle>
              <h2>What's your customer's email address?</h2>
            </EuiModalHeaderTitle>
          </EuiModalHeader>
          <EuiModalBody>
            <EuiForm component="form">
              <EuiFormRow label="Customer Email">
                <EuiFieldText value={email} name="email" onChange={(e) => onEmailChanged(e)} />
              </EuiFormRow>
              <EuiFormRow fullWidth={true}><EuiText><strong>Note - you will need to email the customer with instructions to access the codes.</strong></EuiText></EuiFormRow>
              <EuiFormRow fullWidth={true}><EuiText>Find the <a rel='noreferrer' target='_blank' href='https://www.swiftrev.com/guides/recommended-email-to-discount-code-users/'>recommended copy and paste email here.</a></EuiText></EuiFormRow>
            </EuiForm>
          </EuiModalBody>
          <EuiModalFooter>
            <EuiButtonEmpty onClick={() => cancelCreateCode()}>Cancel</EuiButtonEmpty>
            <EuiButton onClick={() => createCode()} fill>
              Save
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>
      }
    </Fragment>
  );
};