import { EuiProgress, EuiPanel, EuiTitle, EuiSpacer, EuiText, EuiTextColor, EuiButtonIcon, EuiToolTip } from '@elastic/eui';
import useAxios from 'axios-hooks';
import { Fragment } from 'react';
import fileDownload from 'js-file-download';
export default function LeadDetails(props) {
    // Get lead details
    const [{ data: leadDetails, loading, error }] = useAxios(
        {
            url: '/data/lead-handleits',
            method: 'GET',
            params: {
                accountId: props.accountId,
                brandId: props.brandId,
                email: props.email
            }
        },
    );
    // Download email
    const [{ loading: downloadingEmail, error: errorDownloadingEmail }, execDownloadEmail] = useAxios(
        {
            url: '/data/handleit-email',
            method: 'GET'
        },
        { manual: true }
    );
    const downLoadEmail = async (handleit) => {
        const res = await execDownloadEmail({
            params: {
                messageId: handleit.messageId
            }
        });
        fileDownload(res.data, `${handleit.subject}.eml`);
    }
    return (
        <Fragment>
            <EuiProgress hidden={!loading && !downloadingEmail} size="xs" color="primary" />
            <table>
                {!loading && leadDetails &&
                    leadDetails.handleits.map(h => {
                        return (
                            <tr>
                                <td style={{ width: "50px" }}>
                                    <EuiToolTip content="Click to downlad the full email">
                                        <EuiButtonIcon disabled={downloadingEmail} aria-label='download email' size='s' iconType="download" onClick={() => { downLoadEmail(h) }}></EuiButtonIcon>
                                    </EuiToolTip>
                                </td>
                                <td style={{ width: "100px" }}><EuiText color="subdued">{new Date(h.ts).toLocaleDateString()}</EuiText></td>
                                <td><EuiText>{h.subject}</EuiText></td>
                            </tr>
                        )
                    })
                }
            </table>
        </Fragment>
    );
}