import { EuiLink, EuiBasicTable, formatDate, EuiPagination, EuiFlexGroup, EuiFlexItem } from '@elastic/eui';
import useAxios from 'axios-hooks';
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function PressReleaseSummary({ prIds, accountId, brandId }) {
    const navigate = useNavigate();
    const pageSize = 5
    const pageCount = Math.ceil((prIds?.length || 0) / pageSize)
    const [activePage, setActivePage] = useState(0);

    // Get PR Data
    const [{ data: dataPRs, loading: loadingPRs, error: errorPRs }, getPRs] = useAxios(
        {
            url: '/data/pr-summary',
            method: 'POST'
        },
        { manual: true }
    );

    // Get initial PRs
    useEffect(() => {
        if (prIds && prIds?.length > 0) {
            getPRs({
                data: {
                    prIds: prIds?.slice(pageSize * activePage, (pageSize * activePage) + pageSize),
                    accountId, brandId
                }
            })
        }
    }, [activePage, accountId, brandId, pageSize, getPRs, prIds]);

    const columns = [
        {
            field: 'prPostDate',
            name: 'Post Date',
            dataType: 'date',
            render: (date) => formatDate(date, 'MM/DD/YYYY'),
            width: "115px"
        },
        {
            field: 'prTitle',
            name: 'Title',
            render: (prTitle, pr) => {
                return <EuiLink target='_blank' href={`${process.env.REACT_APP_URL}/accounts/${accountId}/press-releases/${pr.brandId}/${pr.prSlug}`} >{prTitle}</EuiLink>
            }
        }
    ];

    return (
        <Fragment>
            < EuiBasicTable
                items={dataPRs?.prs || []}
                error={errorPRs}
                loading={loadingPRs}
                columns={columns}
                tableLayout="auto"
                noItemsMessage="No Paid Press Releases"
            />
            {pageCount > 1 &&
                <EuiFlexGroup justifyContent="spaceAround">
                    <EuiFlexItem grow={false}>
                        <EuiPagination
                            aria-label="Paging for Paid Press Releases"
                            pageCount={pageCount}
                            activePage={activePage}
                            onPageClick={(activePage) => setActivePage(activePage)}
                        />
                    </EuiFlexItem>
                </EuiFlexGroup>
            }
        </Fragment>
    )
}