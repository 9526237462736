import React, { Component, Fragment } from 'react';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { EuiBetaBadge, EuiButtonIcon, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiSpacer } from '@elastic/eui';

class EditableList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: props.list,
            newInput: ''
        };
    }

    handleListItemChange = (event, index, id) => {
        const newList = [...this.state.list];
        newList[index] = { name: event.target.value, id: id };
        this.setState(
            {
                list: newList
            },
            () => {
                this.props.onListChange(this.state.list);
            }
        );
    };

    deleteItem = (index) => {
        const newList = [...this.state.list];
        newList.splice(index, 1);
        this.setState(
            {
                list: newList
            },
            () => {
                this.props.onListChange(this.state.list);
            }
        );
    };

    handleDeleteButtonClick = (index) => {
        return (event) => {
            // In order for the form not to be submitted
            event.preventDefault();
            this.deleteItem(index);
        }
    };

    getList = () => {
        return this.state.list.map((elem, index) => {
            return (
                <EuiFlexItem key={index} grow={false}>
                    <EuiFlexGroup alignItems='center'>
                        <EuiFlexItem grow={false}>
                            <EuiFieldText style={{ minWidth: "400px" }}
                                value={elem.name}
                                placeholder={this.props.placeholder}
                                onChange={e => {
                                    this.handleListItemChange(e, index, elem.id);
                                }}
                                append={<EuiButtonIcon aria-label='remove item' disabled={this.props.badges[index]?.label > 0} iconType="cross" onClick={this.handleDeleteButtonClick(index)} />}
                            />
                        </EuiFlexItem>
                        <EuiFlexItem grow={false}>
                            <EuiBetaBadge
                                label={this.props.badges[index]?.label}
                                tooltipContent={this.props.badges[index]?.tooltipContent}
                                title={this.props.badges[index]?.title}
                            />
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFlexItem>
            );
        });
    };

    onChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    onKeyUp = (event) => {
        if (event.key === 'Enter' && event.target.value.trim(' ').length > 0) {
            const newList = [...this.state.list];
            this.setState(
                {
                    list: newList.concat({ "name": event.target.value, "id": uuidv4() }),
                    newInput: ''
                },
                () => {
                    this.props.onListChange(this.state.list);
                }
            );
        }
    };

    render() {
        return (
            <Fragment>
                <EuiFlexGroup direction="column">{this.getList()} </EuiFlexGroup>
                <EuiSpacer />
                <EuiFieldText
                    name='newInput'
                    onChange={this.onChange}
                    onKeyUp={this.onKeyUp}
                    placeholder='Press enter to add new division'
                    value={this.state.newInput}
                />
            </Fragment>
        );
    }
}

EditableList.propTypes = {
    list: PropTypes.array.isRequired,
    showBullets: PropTypes.bool,
    onListChange: PropTypes.func.isRequired
};

EditableList.defaultProps = {
    list: [],
    showBullets: true
};

export default EditableList;
