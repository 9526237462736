import { Fragment, useRef, useEffect, useContext } from "react";
import CompanyOverview from "./CompanyOverview";
import { EuiSpacer, EuiTitle, EuiLink, EuiTextColor, EuiFlexGroup, EuiFlexItem, EuiText, EuiHorizontalRule, EuiButtonEmpty, EuiHeader } from "@elastic/eui";
import ContactSummary from "./ContactSummary";
import HandleItSummary from "./HandleItSummary";
import PressReleaseSummary from "./PressReleaseSummary";
import CompanyInterestLevelDetails from "./CompanyInterestLevelDetails";
import BrandsGallery from "./BrandsGallery";
import AccountContext from './AccountContext';

export default function ComanyLeadDetails({ companyLead, scrollTo }) {
    const refContacts = useRef(null);
    const refPRs = useRef(null);
    const refHandleIts = useRef(null);
    const refBrands = useRef(null);
    const refCompanyInterest = useRef(null);
    const refOverview = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            if (!scrollTo || scrollTo === 'overview') refOverview?.current?.scrollIntoView({ behavior: 'smooth' });
            if (scrollTo === 'contacts') refContacts?.current?.scrollIntoView({ behavior: 'smooth' });
            if (scrollTo === 'prs') refPRs?.current?.scrollIntoView({ behavior: 'smooth' });
            if (scrollTo === 'handleits') refHandleIts?.current?.scrollIntoView({ behavior: 'smooth' });
            if (scrollTo === 'brands') refBrands?.current?.scrollIntoView({ behavior: 'smooth' });
            if (scrollTo === 'companyinterest') refCompanyInterest?.current?.scrollIntoView({ behavior: 'smooth' });
        })
    }, [scrollTo]);

    return (
        <Fragment>
            <div ref={refOverview} style={{ paddingTop: "10px" }}>
                <EuiTitle size="xs">
                    <h3 style={{ backgroundColor: "gray", padding: "10px" }}>
                        <EuiTextColor color="white">Company Overview</EuiTextColor>
                        <EuiLink style={{ marginLeft: "20px", fontSize: "12px", color: "whitesmoke" }} target="_blank" href="https://www.swiftrev.com/guides/company-leads/#2">More Info</EuiLink>
                    </h3>
                </EuiTitle>
                <EuiSpacer size='l' />
                {companyLead?.company &&
                    <div style={{ marginLeft: "10px", marginRight: "10px" }}>
                        <CompanyOverview company={companyLead.company} />
                    </div>
                }
            </div>

            <EuiSpacer size="xxl" />

            <div ref={refCompanyInterest} style={{ paddingTop: "10px" }}>
                <EuiTitle size="xs"><h3 style={{ backgroundColor: "gray", padding: "10px" }}>
                    <EuiTextColor color="white">Company Interest Level</EuiTextColor>
                    <EuiLink style={{ marginLeft: "20px", fontSize: "12px", color: "whitesmoke" }} target="_blank" href="https://www.swiftrev.com/guides/company-leads/#3">More Info</EuiLink>
                </h3>
                </EuiTitle>
                <EuiSpacer size='m' />
                <div style={{ marginLeft: "10px", marginRight: "10px" }}>
                    <CompanyInterestLevelDetails lead={companyLead} />
                </div>
            </div>

            <EuiSpacer size="xxl" />

            <div ref={refBrands} style={{ paddingTop: "10px" }}>
                <EuiTitle size="xs"><h3 style={{ backgroundColor: "gray", padding: "10px" }}>
                    <EuiTextColor color="white">Brands Engaged</EuiTextColor>
                    <EuiLink style={{ marginLeft: "20px", fontSize: "12px", color: "whitesmoke" }} target="_blank" href="https://www.swiftrev.com/guides/company-leads/#4">More Info</EuiLink>
                </h3></EuiTitle>
                <EuiSpacer size='m' />
                <div style={{ marginLeft: "10px", marginRight: "10px" }}>
                    <BrandsGallery accountId={companyLead?.accountId} brandIds={companyLead?.leadBrands} />
                </div>
            </div>

            <EuiSpacer size="xxl" />

            <div ref={refHandleIts} style={{ paddingTop: "10px" }}>
                <EuiTitle size="xs"><h3 style={{ backgroundColor: "gray", padding: "10px" }}>
                    <EuiTextColor color="white">HandleIts</EuiTextColor>
                    <EuiLink style={{ marginLeft: "20px", fontSize: "12px", color: "whitesmoke" }} target="_blank" href="https://www.swiftrev.com/guides/company-leads/#5">More Info</EuiLink>
                </h3></EuiTitle>
                <EuiSpacer size='m' />
                <div style={{ marginLeft: "10px", marginRight: "10px" }}>
                    <HandleItSummary handleitIds={companyLead?.handleitIds} />
                </div>
            </div>

            <EuiSpacer size="xxl" />

            <div ref={refPRs} style={{ paddingTop: "10px" }}>
                <EuiTitle size="xs"><h3 style={{ backgroundColor: "gray", padding: "10px" }}>
                    <EuiTextColor color="white">Paid PR Spend</EuiTextColor>
                    <EuiLink style={{ marginLeft: "20px", fontSize: "12px", color: "whitesmoke" }} target="_blank" href="https://www.swiftrev.com/guides/company-leads/#6">More Info</EuiLink>
                </h3></EuiTitle>
                <EuiSpacer size='m' />
                <div style={{ marginLeft: "10px", marginRight: "10px" }}>
                    <PressReleaseSummary prIds={companyLead?.prPaidIds} accountId={companyLead?.accountId} brandId={companyLead?.brandId} />
                </div>
            </div>

            <EuiSpacer size="xxl" />

            <div ref={refContacts} style={{ paddingTop: "10px" }}>
                <EuiTitle size="xs"><h3 style={{ backgroundColor: "gray", padding: "10px" }}>
                    <EuiTextColor color="white">Contacts</EuiTextColor>
                    <EuiLink style={{ marginLeft: "20px", fontSize: "12px", color: "whitesmoke" }} target="_blank" href="https://www.swiftrev.com/guides/company-leads/#7">More Info</EuiLink>
                </h3></EuiTitle>
                <EuiSpacer size='m' />
                <div style={{ marginLeft: "10px", marginRight: "10px" }}>
                    <ContactSummary emails={companyLead?.contacts} accountId={companyLead?.accountId} brandId={companyLead?.brandId} />
                </div>
            </div>

        </Fragment >
    )
}