import { Fragment, useState, useEffect, useContext } from 'react';
import {
    EuiForm,
    EuiFormRow,
    EuiFieldText,
    EuiDescribedFormGroup,
    EuiBottomBar,
    EuiFlexGroup,
    EuiFlexItem,
    EuiButton,
    EuiFieldNumber,
    EuiSelect
} from '@elastic/eui';
import useAxios from 'axios-hooks';
import ReactQuill from 'react-quill';
import AccountContext from './AccountContext';

export default function AccountEcomm(props) {
    const accountContext = useContext(AccountContext);
    const [hasChanges, setHasChanges] = useState(false);
    const [defaultPrice, setDefaultPrice] = useState(props?.accountEcomm?.defaultPrice || 0);
    const onChangeDefaultPrice = (e) => {
        setDefaultPrice(parseInt(e.target.value));
    };
    const [contentPolicy, setContentPolicy] = useState(props?.accountEcomm?.contentPolicy || '');

    // const autoDeclineOptions = [
    //     { value: 8, text: '8' },
    //     { value: 12, text: '12' },
    //     { value: 24, text: '24' },
    //     { value: 48, text: '48' }
    // ];
    // const [defaultAutoDeclineHours, setAutoDecline] = useState(props?.accountEcomm?.defaultAutoDeclineHours || autoDeclineOptions[0].value);
    // const onChangeFeedCount = (e) => {
    //     setAutoDecline(parseInt(e.target.value));
    // };

    useEffect(() => {
        setHasChanges(
            defaultPrice !== props?.accountEcomm?.defaultPrice ||
            contentPolicy !== props?.accountEcomm?.contentPolicy
            // defaultAutoDeclineHours !== props?.accountEcomm?.defaultAutoDeclineHours
        );
    }, [
        defaultPrice,
        contentPolicy,
        // defaultAutoDeclineHours
    ]);

    // Save brand marketing updates
    const [
        { data: updateAccountEcomm, loading: updating, error: errorUpdating }, executeUpdate
    ] = useAxios({
        url: '/data/account-ecomm',
        method: 'POST'
    }, {
        manual: true
    })
    async function update() {
        await executeUpdate({
            data: {
                "accountId": accountContext?.account?.accountId,
                "ecomm": {
                    defaultPrice,
                    contentPolicy,
                    // defaultAutoDeclineHours
                }
            }
        })
        props.refetch()
    }
    return (
        <Fragment>
            <EuiForm component="form">
                {/* <EuiDescribedFormGroup fullWidth ratio="quarter"
                    title={<h2>Free Submissions</h2>}
                    description={
                        <Fragment>
                            Settings to control defaults of free press release submissions for new brands.
                        </Fragment>
                    }
                >
                    <EuiFormRow label="Auto Decline Time, New Brand Default" helpText="Number of hours before free submissions are automatically declined.">
                        <EuiSelect
                            options={autoDeclineOptions}
                            value={defaultAutoDeclineHours}
                            onChange={(e) => onChangeFeedCount(e)}
                        />
                    </EuiFormRow>
                </EuiDescribedFormGroup> */}
                <EuiDescribedFormGroup fullWidth ratio="quarter"
                    title={<h2>Pricing</h2>}
                    description={
                        <Fragment>
                            Settings to control default pricing of press release submissions.
                        </Fragment>
                    }
                >
                    <EuiFormRow label="Default Price" helpText="Universal price per press release post across all brands.">
                        <EuiFieldNumber min={0} value={defaultPrice} onChange={(e) => onChangeDefaultPrice(e)} name="defaultPrice" />
                    </EuiFormRow>

                    <EuiFormRow label="Currency" helpText="SwiftRev currently only supports transactions in USD.">
                        <EuiFieldText disabled={true} readOnly={true} defaultValue={props?.accountEcomm?.defaultCurrency || 'USD'} />
                    </EuiFormRow>

                </EuiDescribedFormGroup>
                <EuiDescribedFormGroup fullWidth ratio="quarter"
                    title={<h2>Content Policy</h2>}
                    description={
                        <Fragment>
                            Create and maintain your company wide content policy shown at checkout. SwiftRev can not issue refunds.
                        </Fragment>
                    }
                >
                    <EuiFormRow fullWidth>
                        <ReactQuill theme="snow" value={contentPolicy} onChange={setContentPolicy} />
                    </EuiFormRow>
                </EuiDescribedFormGroup>
            </EuiForm>
            {hasChanges && <EuiBottomBar>
                <EuiFlexGroup justifyContent="flexEnd">
                    <EuiFlexItem grow={false}>
                        <EuiButton disabled={updating} onClick={() => props.refetch()} color="ghost" size="s" iconType="save">
                            Discard Changes
                        </EuiButton>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                        <EuiButton isLoading={updating} color="primary" fill size="s" iconType="save"
                            onClick={() => { update() }}>
                            Save Changes
                        </EuiButton>
                    </EuiFlexItem>
                </EuiFlexGroup>
            </EuiBottomBar>}
        </Fragment>
    );
};