import { useState, Fragment, useEffect } from 'react';
import {
    EuiBasicTable, EuiTitle, EuiProgress,
    EuiFlexGroup, EuiFlexItem, EuiText,
    EuiButtonIcon, EuiPopover, EuiEmptyPrompt, EuiPagination
} from '@elastic/eui';
import IndividualLeadScoreDetails from './IndividualLeadScoreDetails';
import useAxios from 'axios-hooks';


export default function ContactSummary({ accountId, brandId, emails }) {
    const [showLeadScoreDetails, setShowLeadScoreDetails] = useState({});

    const colContacts = [
        {
            field: 'email',
            name: 'Email',
            dataType: 'string',
            width: "500px"
        },
        {
            field: 'leadScore',
            name: 'SwiftRev Lead Score',
            width: "115px",
            render: (leadScore, lead) => {
                return (
                    <EuiFlexGroup component="span" gutterSize="s" alignItems='center'>
                        <EuiFlexItem grow={false}>
                            <EuiText>{(leadScore || 0).toString() || '0'}</EuiText>
                        </EuiFlexItem>
                        <EuiFlexItem grow={true}>
                            <EuiProgress size='l' value={leadScore || 0} max={100}></EuiProgress>
                        </EuiFlexItem>
                        <EuiFlexItem grow={false}>
                            <EuiPopover
                                anchorPosition="downCenter"
                                panelStyle={{ maxWidth: "800px" }}
                                isOpen={showLeadScoreDetails[lead.email] === true}
                                closePopover={() => setShowLeadScoreDetails({ ...showLeadScoreDetails, [lead.email]: false })}
                                button={<EuiButtonIcon aria-label='show lead score details' onClick={() => setShowLeadScoreDetails({ ...showLeadScoreDetails, [lead.email]: true })} iconSize='m' iconType='questionInCircle'></EuiButtonIcon>}
                            >
                                <IndividualLeadScoreDetails lead={lead} />
                            </EuiPopover>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                )
            }
        },
        {
            field: 'handleitCount',
            name: 'HandleIts',
            dataType: 'number',
        },
        {
            field: 'paidCount',
            name: 'Paid PRs',
            dataType: 'number',

        },
        {
            field: 'lastEngagement',
            name: 'Last Engagement',
            dataType: 'string',
            render: (lastEngagement) => {
                return `${lastEngagement} days ago`
            }
        },
        {
            field: 'nurtureStep',
            name: 'Nurture Step',
            render: (nurtureStep) => {
                if (nurtureStep === -1 || nurtureStep == null) return '-'
                else if (nurtureStep === 0) return 'Preparing'
                else if (nurtureStep === 8) return 'Complete'
                else return nurtureStep
            }
        }
    ]

    const [{ data: dataContacts, loading: loadingContacts, error: errorContacts }, getContacts] = useAxios(
        {
            url: '/data/company-contacts',
            method: 'POST'
        },
        { manual: true }
    );

    const pageSize = 5
    const pageCount = Math.ceil((emails?.length || 0) / pageSize)
    const [activePage, setActivePage] = useState(0);

    useEffect(() => {
        if (emails && emails.length > 0) {
            getContacts({
                data: {
                    emails: emails?.slice(pageSize * activePage, (pageSize * activePage) + pageSize),
                    accountId, brandId
                }
            })
        }
    }, [activePage, getContacts, emails, accountId, brandId]);

    return (
        <Fragment>
            < EuiBasicTable
                items={dataContacts?.contacts || []}
                error={errorContacts}
                loading={loadingContacts}
                columns={colContacts}
                tableLayout="auto"
                noItemsMessage={
                    <EuiEmptyPrompt
                        title={(<EuiTitle size='m'><span>No leads yet!</span></EuiTitle>)}
                        body={(<EuiText>The editors can help. Ask them to start forwarding "HandleIts" - the press releases they don't intend to cover.</EuiText>)}
                    >

                    </EuiEmptyPrompt>
                }
            />
            {pageCount > 1 &&
                <EuiFlexGroup justifyContent="spaceAround">
                    <EuiFlexItem grow={false}>
                        <EuiPagination
                            aria-label="Paging for Company Contacts"
                            pageCount={pageCount}
                            activePage={activePage}
                            onPageClick={(activePage) => setActivePage(activePage)}
                        />
                    </EuiFlexItem>
                </EuiFlexGroup>
            }
        </Fragment>
    )
}