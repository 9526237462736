import { EuiHeader, EuiHeaderSection, EuiHeaderSectionItem, EuiImage } from '@elastic/eui';
import { Fragment } from 'react';
import { Route, Routes, Link, Navigate } from "react-router-dom";
import Dashboard from './Dashboard';
import AccountContainer from './AccountContainer';
import logo from '../Assets/logo-swiftrev-small-white.png';
import { UserButton, SignedIn, SignedOut, RedirectToSignIn, useUser } from '@clerk/clerk-react';
import NotFound from './NotFound';
import AccountInactive from './AccountInactive';

export default function Container() {
  const userApi = useUser();
  return (
    <Fragment>
      <EuiHeader theme='dark' position='fixed'>
        <EuiHeaderSection grow={true}>
          <EuiHeaderSectionItem border="none">
            <Link to="/" ><EuiImage style={{ "marginLeft": "16px", "height": "24px" }} alt="SwiftRev" url={logo} /></Link>
          </EuiHeaderSectionItem>
        </EuiHeaderSection>
        <EuiHeaderSection grow={false} side="right" style={{ "marginRight": "16px" }}>
        </EuiHeaderSection>
        <EuiHeaderSection grow={false} side="right" style={{ "marginRight": "16px" }}>
          <EuiHeaderSectionItem border='none'>
            <UserButton afterSignOutUrl={process.env.REACT_APP_URL} />
          </EuiHeaderSectionItem>
        </EuiHeaderSection>
      </EuiHeader>
      <Routes>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="accounts/:accountId/*" element={<AccountContainer />} />
        <Route path="account-inactive" element={<AccountInactive />} />
        <Route path="*" element={
          <Fragment>
            <SignedIn>
              {userApi.user?.publicMetadata?.account_id && <Navigate to={`accounts/${userApi.user?.publicMetadata?.account_id}`} />}
              {!userApi.user?.publicMetadata?.account_id && <Navigate to="/dashboard" />}
            </SignedIn>
            <SignedOut><RedirectToSignIn /></SignedOut>
          </Fragment>
        } />
      </Routes>
    </Fragment>
  );
};