import { Fragment, useState, useContext, useEffect } from 'react';
import { htmlIdGenerator, EuiGlobalToastList, EuiText, EuiIcon, EuiLink, EuiBasicTable, formatDate, EuiTitle, EuiSpacer, EuiFlexGroup, EuiFlexItem, EuiButton, EuiModal, EuiModalHeader, EuiModalHeaderTitle, EuiModalFooter, EuiButtonEmpty, EuiModalBody, EuiForm, EuiFormRow, EuiFieldText } from '@elastic/eui';
import { useNavigate } from "react-router-dom";
import useAxios from 'axios-hooks'
import { useUser } from '@clerk/clerk-react';
import AccountContext from './AccountContext';
const genToastId = htmlIdGenerator('toast');
export default function Brands() {
    const userApi = useUser();
    const accountContext = useContext(AccountContext);
    let navigate = useNavigate();
    const [toasts, setToasts] = useState([]);

    const addToast = (toast) => {
        toast.id = genToastId();
        setToasts(toasts.concat(toast));
    };
    const removeToast = removedToast => {
        setToasts(toasts.filter(toast => toast.id !== removedToast.id));
    };

    const [sort, setSort] = useState({ field: 'brandName', direction: 'asc' })
    const onTableChange = ({ sort }) => {
        if (sort) {
            setSort(sort)
        }
    };
    const [sortedBrands, setSortedBrands] = useState([])

    useEffect(() => {
        if (sort) {
            const sorted = (accountContext?.account?.brands || []).toSorted((a, b) => {
                let aVal = sort.field.split('.').reduce(function (p, prop) { return p[prop] }, a)
                let bVal = sort.field.split('.').reduce(function (p, prop) { return p[prop] }, b)
                if (Array.isArray(aVal)) aVal = aVal.length
                if (Array.isArray(bVal)) bVal = bVal.length
                if (aVal === bVal) return 0
                else if (aVal < bVal && sort.direction === 'asc') return -1
                else if (aVal < bVal && sort.direction === 'desc') return 1
                else if (aVal > bVal && sort.direction === 'asc') return 1
                else if (aVal > bVal && sort.direction === 'desc') return -1
            })
            setSortedBrands(sorted)
        }
    }, [accountContext?.account?.brands, sort])


    const columns = [
        {
            field: 'brandName',
            name: 'Brand',
            sortable: true,
            render: (brand, item) => {
                return <EuiLink onClick={() => { navigate(item.brandId) }}>{item.brandName}</EuiLink>
            }
        },
        {
            field: 'nurtureActive',
            name: 'Nurture Active',
            sortable: true,
            width: "50px",
            align: "center",
            render: (nurtureActive) => nurtureActive ? 'Yes' : 'No'
        },
        {
            field: 'editorCount',
            name: 'Editor Count',
            dataType: 'number',
            sortable: true,
            width: "50px",
            align: "center",
            render: (editorCount) => editorCount || 0
        },
        {
            field: 'salesCount',
            name: 'Sales Count',
            sortable: true,
            dataType: 'number',
            width: "50px",
            align: "center",
            render: (salesCount) => salesCount || 0
        },
        {
            field: 'prFeedUrl',
            name: 'Press Release Site',
            width: "100px",
            align: "center",
            render: (prFeedUrl, item) => {
                if (prFeedUrl) return <EuiLink target="_blank" href={prFeedUrl} >View</EuiLink>
                else return "Not Available"
            },
        },
        {
            field: 'ecommUrl',
            name: 'eCommerce Site',
            width: "100px",
            align: "center",
            render: (ecommUrl, item) => {
                if (ecommUrl) return <EuiLink target="_blank" href={ecommUrl} >View</EuiLink>
                else return "Not Available"
            },
        },
        {
            field: 'featureLeads',
            name: 'Feature | Leads',
            sortable: true,
            width: "50px",
            align: "center",
            render: (featureLeads) => featureLeads ? 'Yes' : 'No'
        },
        {
            field: 'featureDiscountCodes',
            name: 'Feature | Discount Codes',
            sortable: true,
            width: "50px",
            align: "center",
            render: (featureDiscountCodes) => featureDiscountCodes ? 'Yes' : 'No'
        },
    ];

    const [newBrandName, setNewBrandName] = useState('');
    const onNewBrandNameChanged = (e) => setNewBrandName(e.target.value);

    const [
        { data: createBrandResults, loading: creatingBrand, error: errorCreatingBrand },
        executePut
    ] = useAxios(
        {
            url: '/data/brands',
            method: 'POST'
        },
        { manual: true }
    )

    function addBrand() {
        executePut({
            data: {
                accountId: accountContext.account.accountId,
                name: newBrandName
            }
        })
            .then(() => {
                addToast({ title: "Brand created!", color: 'success' })
                accountContext.refetchAccount()
            })
            .catch((e) => {
                addToast({ title: "Error creating brand", color: 'danger' })
            })
            .finally(() => {
                setNewBrandName('')
                closeModal()
            })
    }

    const [isModalVisible, setIsModalVisible] = useState(false);
    const closeModal = () => setIsModalVisible(false);
    const showModal = () => setIsModalVisible(true);

    return (
        <Fragment>
            <EuiGlobalToastList
                toasts={toasts}
                dismissToast={removeToast}
                toastLifeTimeMs={3000}
            />
            <EuiFlexGroup>
                <EuiFlexItem>
                    <EuiTitle size="m">
                        <h1>Brands</h1>
                    </EuiTitle>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    {(userApi?.user?.publicMetadata?.platformAdmin === 'yes' || accountContext?.account?.isAccountAdmin) &&
                        <EuiButton disabled={creatingBrand || isModalVisible} fill onClick={showModal}>Add Brand</EuiButton>
                    }
                </EuiFlexItem>
            </EuiFlexGroup>
            <EuiSpacer />
            <EuiBasicTable
                tableCaption="List of account brands"
                items={sortedBrands || []}
                onChange={onTableChange}
                sorting={{ sort: sort }}
                rowHeader="sk"
                columns={columns}
                tableLayout="auto"
            />
            {isModalVisible &&
                <EuiModal onClose={closeModal} initialFocus="[name=popswitch]">
                    <EuiModalHeader>
                        <EuiModalHeaderTitle>
                            <h1>Create New Brand</h1>
                        </EuiModalHeaderTitle>
                    </EuiModalHeader>

                    <EuiModalBody>
                        <EuiForm component="form" disabled={creatingBrand}>
                            <EuiFormRow label="Brand Name">
                                <EuiFieldText value={newBrandName} name="popfirst" onChange={(e) => onNewBrandNameChanged(e)} />
                            </EuiFormRow>
                        </EuiForm>
                    </EuiModalBody>

                    <EuiModalFooter>
                        <EuiButtonEmpty onClick={closeModal}>Cancel</EuiButtonEmpty>
                        <EuiButton isLoading={creatingBrand} onClick={addBrand} fill>
                            Save
                        </EuiButton>
                    </EuiModalFooter>
                </EuiModal>}
        </Fragment>
    );
};