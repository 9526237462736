import React, { useState, useContext, Fragment } from 'react';
import { EuiModalFooter, EuiModalBody, EuiModal, EuiModalHeader, EuiModalHeaderTitle, EuiSpacer, EuiProgress, EuiCallOut, PropertySortType, EuiFlexGroup, EuiFlexItem, EuiText, EuiStat, EuiLink, EuiBadge, EuiImage, EuiButton, EuiButtonIcon, EuiButtonEmpty, EuiToolTip, htmlIdGenerator, EuiGlobalToastList, EuiFormRow, EuiSelect, EuiTextArea } from '@elastic/eui';
import { useParams, useLocation } from "react-router-dom";
import useAxios from 'axios-hooks';
import AccountContext from './AccountContext';
import { SymbolOverview } from "react-ts-tradingview-widgets";
import GoogleTrends from './GoogleTrends';
import { saveAs } from 'file-saver';

export default function PressRelease(props) {
    const accountContext = useContext(AccountContext)
    const params = useParams();
    const location = useLocation();
    const [{ data: pr, loading, error }, refetch] = useAxios(
        {
            url: '/data/prs',
            method: 'GET',
            params: {
                accountId: params.accountId,
                brandId: params.brandId,
                prSlug: params.prSlug,
            }
        }
    );

    const [
        { data: updateCoverageResult, loading: updatingCoverage, error: errorUpdatingCoverage }, executeUpdate
    ] = useAxios({
        url: '/data/pr-coverage',
        method: 'POST'
    }, {
        manual: true
    })

    const [modalPR, setModalPR] = useState(null);

    const declinedMsg = "Press Release successfully declined and email sent to submitter. To see the submission, click on 'Show Declined' box in top filter."
    const claimedMsg = "Press Release successfully claimed."

    async function updateCoverage(pr, coverage, reason) {
        await executeUpdate({
            data: {
                "pk": pr.pk,
                "sk": pr.sk,
                "coverage": coverage,
                "reason": reason
            }
        })
            .then(() => {
                addToast({ title: coverage === "declined" ? declinedMsg : claimedMsg, color: 'success' })
            })
            .catch((e) => {
                addToast({ title: `Error. Press release not ${coverage}`, color: 'danger' })
            })
            .finally(() => {
                refetch();
            });
    }

    const declineOptions = [
        { value: 'Low value type of news', text: 'Low value type of news' },
        { value: 'Wrong audience', text: 'Wrong audience' },
        { value: 'Overly promotional', text: 'Overly promotional' },
        { value: 'Custom', text: 'Custom' },
    ];
    const [declineReason, setDeclineReason] = useState('');
    const [customDecline, setCustomDecline] = useState('');

    const [toasts, setToasts] = useState([]);
    const genToastId = htmlIdGenerator('toast');
    const addToast = (toast) => {
        toast.id = genToastId();
        setToasts(toasts.concat(toast));
    };
    const removeToast = removedToast => {
        setToasts(toasts.filter(toast => toast.id !== removedToast.id));
    };
    return (
        <Fragment>
            <EuiGlobalToastList
                toasts={toasts}
                dismissToast={removeToast}
                toastLifeTimeMs={3000}
            />
            <EuiProgress hidden={!loading} size="xs" color="primary" />
            {!loading && pr &&
                <React.Fragment>
                    {pr?.prStatus === 'submitted' &&
                        <Fragment>
                            <EuiFlexGroup alignItems="center">
                                <EuiFlexItem grow={false}>
                                    <EuiCallOut title="Free Submission" color="success" />
                                </EuiFlexItem><EuiFlexItem>
                                    <EuiText>
                                        <span style={{ fontStyle: "italic" }} >This does NOT show up in the public list of press releases</span>
                                    </EuiText>
                                </EuiFlexItem>
                            </EuiFlexGroup>
                            <EuiSpacer />
                        </Fragment>
                    }
                    <Fragment>
                        {(!pr.coverage || pr.coverage === 'tbd') &&
                            <Fragment>
                                <EuiFlexGroup component="span" gutterSize="s" justifyContent="flexStart">
                                    <EuiFlexItem grow={false}>
                                        <EuiToolTip
                                            position="left"
                                            content="Make clear to other editors on your team that you are intending to write an article based on this press release. Once claimed, visible to all other users. Cannot be 'unclaimed'."
                                        >
                                            <EuiButton size='s' color="success" onClick={() => updateCoverage(pr, "claimed")}>
                                                Claim
                                            </EuiButton>
                                        </EuiToolTip>
                                    </EuiFlexItem>
                                    {pr.prStatus === 'submitted' && <EuiFlexItem grow={false}>
                                        <EuiToolTip
                                            position="left"
                                            content="Don't want to write an article on this press release? No problem. Click 'Decline' and we'll automatically notify the submitter and encourage a transition from earned media to paid via a paid posting in your Press Release Channel. Note - the Decline option is not available on free submissions since the call to action when clicked is already done by the user."
                                        >
                                            <EuiButton size='s' color="danger" onClick={() => setModalPR(pr)}>
                                                Decline
                                            </EuiButton>
                                        </EuiToolTip>
                                    </EuiFlexItem>}
                                </EuiFlexGroup>
                                <EuiSpacer />
                            </Fragment>}
                        {pr.coverage === 'claimed' &&
                            <Fragment>
                                <EuiFlexGroup alignItems='center' justifyContent='flexStart'>
                                    <EuiFlexItem grow={false}><EuiText color='success'>Claimed</EuiText></EuiFlexItem>
                                    <EuiFlexItem grow={false}><EuiText size='sm'>{pr.coverageUpdatedBy}</EuiText></EuiFlexItem>
                                </EuiFlexGroup>
                                <EuiSpacer />
                            </Fragment>
                        }
                        {pr.coverage === 'declined' &&
                            <Fragment>
                                <EuiFlexGroup alignItems='center' justifyContent='flexStart'>
                                    <EuiFlexItem grow={false}><EuiText color='danger'>Declined</EuiText></EuiFlexItem>
                                    <EuiFlexItem grow={false}><EuiText size='sm'>{pr.coverageUpdatedBy === 'auto-decline' ? 'Automatically Declined' : pr.coverageUpdatedBy}</EuiText></EuiFlexItem>
                                </EuiFlexGroup>
                                <EuiSpacer />
                            </Fragment>
                        }
                        {pr.coverage === 'tbd' &&
                            <Fragment>
                                <EuiFlexGroup alignItems='center' justifyContent='flexStart'>
                                    <EuiFlexItem grow={false}><EuiText color='danger' size='sm'>Note: This press release will be declined automatically on {new Date(pr.autoDecline).toString()}</EuiText></EuiFlexItem>
                                </EuiFlexGroup>
                                <EuiSpacer />
                            </Fragment>
                        }
                    </Fragment>
                    <EuiText>
                        <h2>{pr?.prTitle}</h2>
                        {pr?.prStatus !== 'submitted' && <EuiLink href={pr?.externalUrl} target="_blank">View Online</EuiLink>}
                        {(pr?.prPostDate > new Date().toISOString()) && <span style={{ marginLeft: "12px", color: "darkred" }}>Note: This Press Release will go live on {pr?.prPostDatePretty}</span>}
                    </EuiText>
                    <EuiSpacer size='xl' />
                    <EuiFlexGroup>
                        <EuiFlexItem grow={6}>
                            <div>
                                <EuiFlexGroup justifyContent='center' className='swiftrev-alert' style={{ margin: "0", padding: "5px" }}>
                                    <EuiFlexItem grow={false} style={{ width: "155px", justifyContent: "space-between" }}>
                                        <EuiText style={{ marginBottom: "10px", fontWeight: "bolder" }} textAlign='center' size='m'>SwiftRev Score</EuiText>
                                        <EuiStat titleColor="white" style={{ borderRadius: "5px", marginBottom: "10px", textAlign: "center", padding: "5px", backgroundColor: "rgb(9,172,223)", color: "white" }} title={pr?.swiftrevScore?.totalScoreDisplay} />
                                        <EuiProgress color="rgb(9,172,223)" value={pr?.swiftrevScore?.totalScore} max={100} size="l" />
                                    </EuiFlexItem>
                                    <EuiFlexItem grow={false} style={{ borderRight: "solid white 1px" }}>

                                    </EuiFlexItem>
                                    <EuiFlexItem grow={false} style={{ width: "165px", justifyContent: "space-between", alignItems: "center" }}>
                                        <EuiText style={{ marginBottom: "10px" }} textAlign='center' size='s'>Release Topic</EuiText>
                                        <div style={{ marginBottom: "10px", textAlign: "center", width: "100px", padding: "5px", borderRadius: "5px", border: "solid white 1px" }}><EuiText style={{ fontWeight: "bold", fontSize: "14px" }}>{pr?.swiftrevScore?.classificationScoreDisplay}</EuiText></div>
                                        <EuiText textAlign='center' size='xs' style={{ marginBottom: "10px", height: "32px" }}>{pr?.prClassificationData?.labelName}</EuiText>
                                        <EuiProgress color="rgb(9,172,223)" value={pr?.swiftrevScore?.weightedClassificationScore} max={100} size="l" />
                                    </EuiFlexItem>
                                    <EuiFlexItem grow={false} style={{ justifyContent: "center" }}>
                                        <EuiText color="white"><h1>+</h1></EuiText>
                                    </EuiFlexItem>
                                    <EuiFlexItem grow={false} style={{ width: "165px", justifyContent: "space-between", alignItems: "center" }}>
                                        <EuiText style={{ marginBottom: "10px" }} textAlign='center' size='s'>Company Interest</EuiText>
                                        <div style={{ marginBottom: "10px", textAlign: "center", width: "100px", padding: "5px", borderRadius: "5px", border: "solid white 1px" }}><EuiText style={{ fontWeight: "bold", fontSize: "14px" }}>{pr?.swiftrevScore?.companySizeScoreDisplay || "N/A"}</EuiText></div>
                                        <EuiText textAlign='center' size='xs' style={{ marginBottom: "10px", height: "32px" }}>{pr?.company?.name}</EuiText>
                                        <EuiProgress color="rgb(9,172,223)" value={pr?.swiftrevScore?.weightedCompanyScore || 0} max={100} size="l" />
                                    </EuiFlexItem>
                                </EuiFlexGroup>
                                <EuiSpacer size='s' />
                                <EuiText size='xs' textAlign='right'>
                                    <EuiLink target="_blank" href='https://www.swiftrev.com/guides/press-release-interest-score/'>More Info on the SwiftRev Score</EuiLink>
                                </EuiText>
                                <EuiSpacer size='xl' />
                                <EuiText>
                                    <h4>{pr?.company?.name} Trends</h4>
                                    <div id='trend-line'>
                                        <GoogleTrends
                                            containerId='trend-line'
                                            type="TIMESERIES"
                                            keyword={pr?.company?.name}
                                            url="https://ssl.gstatic.com/trends_nrtr/2051_RC11/embed_loader.js"
                                        />
                                    </div>
                                    <div id='trend-map' >
                                        <GoogleTrends
                                            containerId='trend-map'
                                            type="GEO_MAP"
                                            keyword={pr?.company?.name}
                                            url="https://ssl.gstatic.com/trends_nrtr/2051_RC11/embed_loader.js"
                                        />
                                    </div>
                                </EuiText>
                                <EuiSpacer size='xl' />
                                {pr?.pitch &&
                                    <EuiText>
                                        <h4>Message / Pitch</h4>
                                        <div>{pr?.pitch}</div>
                                        <br />
                                    </EuiText>
                                }
                                {pr?.prHeaderImage &&
                                    <Fragment>
                                        <EuiImage style={{ marginBottom: "12px" }} size='fullWidth' src={pr.prHeaderImage + '?x-cache-bust=final'}></EuiImage>
                                        <EuiButtonEmpty iconType="download" onClick={() => { saveAs(pr.prHeaderImage + '?x-cache-bust=final'); }}>Download Image</EuiButtonEmpty>
                                    </Fragment>
                                }
                                <EuiText style={{ marginTop: "24px" }}>
                                    <h4>Press Release Content</h4>
                                    <div className="sr-subtitle">{pr?.prPostDatePretty}</div>
                                    <div dangerouslySetInnerHTML={{ "__html": pr?.prBody }}></div>
                                    <h4>About Content</h4>
                                    <div dangerouslySetInnerHTML={{ "__html": pr?.prAbout }}></div>
                                </EuiText>
                            </div>
                        </EuiFlexItem>
                        <EuiFlexItem grow={4}>
                            <div>
                                <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                                    <EuiText>
                                        <h4>About {pr?.company?.name}</h4>
                                    </EuiText>
                                    <EuiSpacer />
                                    <div style={{ textAlign: "center" }}><img style={{ maxWidth: "128px", maxHeight: "128px" }} src={pr?.company?.logo} alt={pr?.company?.name} /></div>
                                </div>
                                <div style={{ padding: "20px" }}>
                                    <EuiText>
                                        <div style={{ fontWeight: "bold", marginBottom: "5px" }}>Highlights</div>
                                        <EuiSpacer size='s' />
                                        <div>{pr?.company?.dataLinkedIn?.description}</div>
                                        <div style={{ color: "gray", fontSize: "smaller" }}>DESCRIPTION</div>
                                        <EuiSpacer size='s' />
                                        <div><a target="_blank" rel='noreferrer' href={`https://${pr?.company?.domain}`}>https://{pr?.company?.domain}</a></div>
                                        <div style={{ color: "gray", fontSize: "smaller" }}>WEBSITE</div>
                                        <EuiSpacer size='s' />
                                        {pr?.company?.dataLinkedIn?.industry &&
                                            <Fragment>
                                                <div>{pr?.company?.dataLinkedIn?.industry}</div>
                                                <div style={{ color: "gray", fontSize: "smaller" }}>INDUSTRY</div>
                                                <EuiSpacer size='s' />
                                            </Fragment>
                                        }
                                        <EuiFlexGroup>
                                            {pr?.company?.size &&
                                                <EuiFlexItem grow={false}>
                                                    <div>
                                                        <div>{pr?.company?.size}</div>
                                                        <div style={{ color: "gray", fontSize: "smaller" }}>SIZE</div>
                                                    </div>
                                                </EuiFlexItem>
                                            }
                                            {pr?.company?.type &&
                                                <EuiFlexItem grow={false}>
                                                    <div style={{ marginLeft: "30px" }}>
                                                        <div>{pr?.company?.type}</div>
                                                        <div style={{ color: "gray", fontSize: "smaller" }}>TYPE</div>
                                                    </div>
                                                </EuiFlexItem>
                                            }
                                        </EuiFlexGroup>
                                        <EuiSpacer size='s' />
                                        <div>{pr?.company?.hq || "Unknown"}</div>
                                        <div style={{ color: "gray", fontSize: "smaller" }}>LOCATION</div>
                                        <EuiSpacer size='s' />
                                        {pr?.company?.dataTicker &&
                                            <Fragment>
                                                <div><a target="_blank" href={`https://finance.yahoo.com/quote/${pr?.company?.dataTicker?.symbol}`}>{pr?.company?.dataTicker?.symbol}</a></div>
                                                <div style={{ color: "gray", fontSize: "smaller" }}>STOCK TICKER</div>
                                                <div style={{ height: "250px", display: "grid", justifyContent: "center", marginTop: "25px" }}>
                                                    <SymbolOverview
                                                        symbols={[`${pr?.company?.dataTicker?.exchDisp}:${pr?.company?.dataTicker?.symbol}`]}
                                                        autosize={true}
                                                        colorTheme="light"
                                                        isTransparent={true}
                                                    />
                                                </div>
                                            </Fragment>
                                        }
                                    </EuiText>
                                </div>
                                <EuiSpacer />
                                <div style={{ padding: "20px", backgroundColor: "rgb(248,249,250)" }}>
                                    <EuiText>
                                        <h4>Press Contact</h4>
                                        <div>{pr?.prContact?.name}</div>
                                        <div>{pr?.prContact?.email}</div>
                                        <div>{pr?.prContact?.phone}</div>
                                    </EuiText>
                                    <EuiSpacer />
                                    <EuiText>
                                        <h4>Customer Contact</h4>
                                        <div>{pr?.customer?.name}</div>
                                        <div>{pr?.customer?.email}</div>
                                        <div>{pr?.customer?.phone}</div>
                                    </EuiText>
                                </div>
                                <EuiSpacer />
                                {!(pr?.prStatus === 'submitted') &&
                                    <div style={{ padding: "20px", backgroundColor: "rgb(248,249,250)" }}>
                                        <EuiText>
                                            <h4>Reject Press Release</h4>
                                            <EuiLink target="_blank" rel="noopener noreferrer" href={
                                                `mailto:support@swiftrev.com?subject=Request%20to%20remove%20press%20release&body=I'm%20an%20Editor%20with%20${(accountContext?.account?.brands?.find(b => b.brandId === pr.brandId))?.brandName}.%20This%20is%20a%20request%20to%20remove%20the%20following%20press%20release%3A%0D%0A%0D%0A${window.location.href}%0D%0A%0D%0AMy%20reason%20for%20removing%20this%20is%20(required)%3A`
                                            }>Start Rejection Process</EuiLink>
                                        </EuiText>
                                    </div>
                                }
                            </div>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                    {modalPR &&
                        <EuiModal onClose={() => { setModalPR(null); setDeclineReason('') }} initialFocus="[name=email]">
                            <EuiModalHeader>
                                <EuiModalHeaderTitle>
                                    <h1>Are you sure you want to decline editorial coverage?</h1>
                                </EuiModalHeaderTitle>
                            </EuiModalHeader>

                            <EuiModalBody>
                                <EuiText>Note - We will instantly send a message confirming this press release will not be covered and encourage the purchase of a submission in the paid press release channel.</EuiText>
                                <EuiSpacer />
                                <EuiFormRow label="Reason for decline">
                                    <EuiSelect
                                        hasNoInitialSelection={true}
                                        options={declineOptions}
                                        value={declineReason}
                                        onChange={(e) => { setCustomDecline(e.target.value); setDeclineReason(e.target.value); }}
                                    />
                                </EuiFormRow>
                                {declineReason === 'Custom' &&
                                    <EuiFormRow fullWidth>
                                        <EuiTextArea fullWidth
                                            resize={false}
                                            rows={2}
                                            placeholder="Custom decline reason"
                                            onChange={(e) => setCustomDecline(e.target.value)}
                                        />
                                    </EuiFormRow>
                                }
                            </EuiModalBody>

                            <EuiModalFooter style={{ "justifyContent": "flex-start" }}>
                                <EuiButton onClick={() => { updateCoverage(modalPR, "declined", customDecline); setModalPR(null); setCustomDecline(''); setDeclineReason(''); }} fill>
                                    Yes, I am sure
                                </EuiButton>
                                <EuiButtonEmpty size='sm' onClick={() => { setModalPR(null); setCustomDecline(''); setDeclineReason(''); }}>No, return to previous screen</EuiButtonEmpty>
                            </EuiModalFooter>
                        </EuiModal>
                    }
                </React.Fragment>
            }
        </Fragment >
    );
};