
import { EuiButton, EuiButtonEmpty, EuiImage, EuiSpacer, EuiText } from '@elastic/eui';
import { useEffect, useState } from 'react';
import { useFilePicker } from 'use-file-picker';
import { v4 as uuidv4 } from 'uuid';
import useAxios from 'axios-hooks';
import axios from 'axios'
import { makeUseAxios } from 'axios-hooks'

const useAxiosUpload = makeUseAxios({
    axios: axios.create()
})

export default function ImageLoader(props) {

    // Get file upload url
    const [
        { loading: gettingFileUploadUrl, error: errorGettingFileUploadUrl }, getFileUploadUrl
    ] = useAxios({
        url: '/data/cdn',
        method: 'POST'
    }, {
        manual: true
    })
    // Upload file
    const [
        { loading: uploadingFile, error: errorUploadingFile }, uploadFile
    ] = useAxiosUpload({
        method: 'POST'
    }, {
        manual: true
    })

    const processFileUpload = async (file) => {
        const { data: fileUrlResponse } = await getFileUploadUrl({
            data: {
                "accountId": props.accountId,
                "brandId": props.brandId,
                "fileName": uuidv4(),
                "fileType": file.type
            }
        })
        const resp = await fetch(fileUrlResponse.uploadUrl, {
            method: 'PUT',
            body: file,
            headers: {
                'Content-Type': file.type,
            },
        })
        return fileUrlResponse.downloadUrl
    }

    const onChangeNutureFile = async (file) => {
        const downloadUrl = await processFileUpload(file, file.name)
        props.onChange(downloadUrl)
    }

    const [openImageFileSelector, { filesContent, loading, errors, plainFiles, clear }] = useFilePicker({
        multiple: false,
        readAs: 'DataURL', // availible formats: "Text" | "BinaryString" | "ArrayBuffer" | "DataURL"
        accept: ['.jpg', '.jpeg', '.png', '.gif'],
        limitFilesConfig: { min: 1, max: 1 },
        maxFileSize: 1, // Mb
        readFilesContent: true,
    });

    const [imagePreview, setImagePreview] = useState(props.imageUrl);

    useEffect(() => {
        if (!!plainFiles[0]) onChangeNutureFile(plainFiles[0])
    }, [plainFiles]);

    useEffect(() => {
        if (!!filesContent[0]) setImagePreview(filesContent[0].content)
    }, [filesContent]);

    return (
        <div>
            {(!!imagePreview) && <div><EuiImage alt='Image' src={imagePreview} /><EuiSpacer size='s' /></div>}
            <EuiButton isLoading={gettingFileUploadUrl || uploadingFile} onClick={() => openImageFileSelector()}>Upload Image</EuiButton>
            {!!errors.length &&
                <div>
                    <EuiText color='danger'>Something went wrong, retry!</EuiText>
                    <EuiText color='danger'>
                        {errors[0].fileSizeTooSmall && 'File size is too small!'}
                        {errors[0].fileSizeToolarge && 'File size is too large!'}
                        {errors[0].readerError && 'Problem occured while reading file!'}
                        {errors[0].maxLimitExceeded && 'Too many files'}
                        {errors[0].minLimitNotReached && 'Not enough files'}
                    </EuiText>
                </div>
            }
        </div>
    )
}