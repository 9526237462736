import { Fragment, useState, useEffect } from 'react'
import {
    EuiLoadingChart, EuiLink, EuiPanel, EuiComboBox,
    EuiGlobalToastList, EuiFieldText, EuiFormRow, EuiTitle,
    EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiBasicTable
} from '@elastic/eui';
import { useNavigate, useParams } from 'react-router-dom';
import useAxios from 'axios-hooks'
import { Chart, AreaSeries, Heatmap, Axis, Position, ScaleType, CurveType, Settings, BarSeries } from '@elastic/charts';
import AccountContext from './AccountContext';

export default function Analytics() {
    const navigate = useNavigate();
    let params = useParams();

    const [{ data: stats, loading: loadingStats }, getStats] = useAxios(
        {
            url: '/data/stats',
            method: 'GET',
            params: {
                accountId: params.accountId
            }
        }
    )

    const columns = [{
        field: 'name',
        name: 'Account Name',
        render: (name, account) => {
            return <EuiLink onClick={() => { navigate(account.id) }} >{name}</EuiLink>
        },
    }, {
        field: 'name',
        name: 'Customer App',
        render: (name, account) => {
            return <EuiLink href={`${process.env.REACT_APP_SWIFTREV_APP_URL}/accounts/${account.id}`} target='_blank' >{name}</EuiLink>
        },
    }, {
        field: 'id',
        name: 'Account Id'
    }]

    return (
        <Fragment>
            {loadingStats && <EuiLoadingChart size="xl" mono />}
            {stats && !loadingStats &&
                <Fragment>
                    <Chart title='Account HandleIts Per Week' size={['100%', 300]}>
                        <Axis id="bottom" position={Position.Bottom} />
                        <Axis id="right" position={Position.Right} tickFormat={(d) => `${Number(d).toFixed(0)}`}
                        />
                        <Axis id="left" groupId='bar' position={Position.Left} tickFormat={(d) => `${Number(d).toFixed(0)}`}
                        />
                        <BarSeries
                            id="Weekly HandleIts"
                            groupId='bar'
                            xScaleType={ScaleType.Ordinal}
                            yScaleType={ScaleType.Linear}
                            xAccessor={'week'}
                            yAccessors={['weekCount']}
                            yNice
                            data={(stats?.accountWeekStats || [])}
                            curve={CurveType.CURVE_MONOTONE_X}
                        />
                        <AreaSeries
                            id="Total HandleIts"
                            xScaleType={ScaleType.Ordinal}
                            yScaleType={ScaleType.Linear}
                            xAccessor={'week'}
                            yAccessors={['totalCount']}
                            yNice
                            data={(stats?.accountWeekStats || [])}
                            curve={CurveType.CURVE_MONOTONE_X}
                        />
                    </Chart>
                    <EuiSpacer />
                    <Chart title='Brand HandleIts Per Month' size={['100%', new Set([...(stats?.brandMonthStats || []).map(s => s.brandId)]).size * 50]}>
                        <Heatmap
                            xSortPredicate="alphaAsc"
                            ySortPredicate='alphaAsc'
                            xAccessor={'month'}
                            yAccessor={'brandName'}
                            valueAccessor={'handleitCount'}
                            valueFormatter={(value) => value.toFixed(0)}
                            data={(stats?.brandMonthStats || [])}
                            colorScale={{
                                type: 'bands',
                                bands: [
                                    { start: -Infinity, end: 39, color: '#f7eaea' },
                                    { start: 40, end: Infinity, color: '#c0e2d7' },
                                ]
                            }}
                        />
                    </Chart>
                    <EuiSpacer />
                    <Chart title='Editor HandleIts Per Month' size={['100%', new Set([...(stats?.userMonthStats || []).map(s => s.email)]).size * 50]}>
                        <Heatmap
                            xSortPredicate="alphaAsc"
                            ySortPredicate='alphaAsc'
                            xAccessor={'month'}
                            yAccessor={'email'}
                            valueAccessor={'handleitCount'}
                            valueFormatter={(value) => value.toFixed(0)}
                            data={(stats?.userMonthStats || [])}
                            colorScale={{
                                type: 'bands',
                                bands: [
                                    { start: -Infinity, end: 10, color: '#f7eaea' },
                                    { start: 11, end: Infinity, color: '#c0e2d7' },
                                ]
                            }}
                        />
                    </Chart>
                </Fragment>
            }
        </Fragment>
    );
};