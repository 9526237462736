import React from 'react'; import {
    EuiEmptyPrompt,
    EuiImage
} from '@elastic/eui';
import logo from '../Assets/logo-swiftrev-small-black.png';

export default function Dashboard() {
    return (
        <EuiEmptyPrompt
            icon={<EuiImage size={200} alt="SwiftRev" url={logo} />}
            title={<h2>Welcome!</h2>}
            layout="vertical"
            body={
                <p>
                    
                </p>
            }
        />
    );
}