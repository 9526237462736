import { Fragment, useContext, useState } from 'react';
import {
  EuiSpacer,
  EuiFlexGroup,
  EuiFlexItem,
  EuiButtonEmpty,
  EuiTitle,
  EuiHorizontalRule,
  EuiGlobalToastList,
  EuiPanel,
  EuiProgress,
  useEuiTheme,
  htmlIdGenerator
} from '@elastic/eui';
import { Routes, Route, Navigate } from "react-router-dom";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import useAxios from 'axios-hooks';
import NotFound from './NotFound';
import 'react-quill/dist/quill.snow.css';
import AccountEcomm from './AccountEcomm';
import AccountContext from './AccountContext';
import AccountUsers from './AccountUsers';
import AccountDivisions from './AccountDivisions';

export default function AccountSettings() {
  const accountContext = useContext(AccountContext);
  let euiTheme = useEuiTheme().euiTheme;
  let navigate = useNavigate();
  let location = useLocation();
  let params = useParams();
  const pathPrefix = `/accounts/${params.accountId}/settings/`

  const [{ data, loading, error }, refetchAccount] = useAxios(
    {
      url: '/data/accounts',
      method: 'GET',
      params: {
        accountId: params.accountId
      }
    }
  )
  // Custom tab like button styles
  let unSelectedTabStyle = {
    "color": euiTheme.colors.text
  }
  let selectedTabStyle = {
    "backgroundColor": euiTheme.colors.lightestShade,
    "color": euiTheme.colors.link
  }

  return (
    <Fragment>
      <EuiPanel grow={true} borderRadius='none' paddingSize='none' hasShadow={false}>
        <EuiTitle size="m">
          <h1>{accountContext?.account?.accountName}</h1>
        </EuiTitle>
        <EuiSpacer size="m" />
        <EuiProgress hidden={!loading} size="xs" color="primary" />
        {!loading && data.item &&
          <Fragment>
            <EuiFlexGroup responsive={false} wrap gutterSize="s" alignItems="center">
              <EuiFlexItem grow={false}>
                <EuiButtonEmpty
                  style={location.pathname.startsWith(pathPrefix + 'users') ? selectedTabStyle : unSelectedTabStyle}
                  onClick={() => { navigate('users') }}>
                  Users
                </EuiButtonEmpty>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiButtonEmpty
                  style={location.pathname.startsWith(pathPrefix + 'divisions') ? selectedTabStyle : unSelectedTabStyle}
                  onClick={() => { navigate('divisions') }}>
                  Divisions
                </EuiButtonEmpty>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiButtonEmpty
                  style={location.pathname.startsWith(pathPrefix + 'ecomm') ? selectedTabStyle : unSelectedTabStyle}
                  onClick={() => { navigate('ecomm') }}>
                  E-Commerce
                </EuiButtonEmpty>
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiHorizontalRule />
            <Routes>
              <Route index element={<Navigate to="users" replace={true} />} />
              <Route path="users" element={<AccountUsers userDetails={data?.item?.userDetails} refetch={refetchAccount} />} />
              <Route path="divisions" element={<AccountDivisions divisions={data?.item?.divisions} refetch={refetchAccount} />} />
              <Route path="ecomm" element={<AccountEcomm accountEcomm={data?.item?.ecomm} refetch={refetchAccount} />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Fragment>}
      </EuiPanel>
    </Fragment>
  );
};