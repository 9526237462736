import { Fragment, useState, useContext } from 'react';
import {
  EuiBasicTable,
  EuiAvatar,
  EuiBadge,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiButton,
  EuiSpacer,
  EuiGlobalToastList,
  htmlIdGenerator
} from '@elastic/eui';
import useAxios from 'axios-hooks';
import { UserAdd } from './UserAdd';
import AccountContext from './AccountContext';

export default function AccountUsers(props) {
  const accountContext = useContext(AccountContext);
  const [userDetails, setUserDetails] = useState(props?.userDetails.sort((a, b) => a.email.localeCompare(b.email)) || []);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const closeModal = () => setIsModalVisible(false);
  const showModal = () => setIsModalVisible(true);
  const [
    { data, loading: addingUser, error: errorAddingUser },
    executePut
  ] = useAxios(
    {
      url: '/data/users',
      method: 'POST'
    },
    { manual: true }
  )

  const [
    { data: removeUserResults, loading: removingUser, error: errorRemovingUser },
    executeRemoveUser
  ] = useAxios(
    {
      url: '/data/accounts/users',
      method: 'DELETE'
    },
    { manual: true }
  )

  const deleteUser = (user) => {
    executeRemoveUser({
      data: {
        userId: user.userId,
        accountId: accountContext.account.accountId
      }
    })
      .then(() => {
        addToast({ title: "User removed", color: 'success' })
        accountContext.refetchAccount();
      })
      .catch((e) => {
        addToast({ title: "Error removing user", color: 'danger' })
      })
      .finally(() => {
      })
  };

  function addUser({ email, firstName, lastName }) {
    closeModal()
    executePut({
      data: {
        accountId: accountContext.account.accountId,
        email,
        firstName,
        lastName,
        role: "admin"
      }
    })
      .then(() => {
        addToast({ title: "User added!", color: 'success' })
        props.refetch();
      })
      .catch((e) => {
        addToast({ title: "Error adding user", color: 'danger' })
      })
      .finally(() => {
      })
  }

  function cancelAddUser() {
    closeModal();
  }

  const userColumns = [
    {
      field: 'profileImageUrl',
      name: '',
      dataType: 'string',
      width: "75px",
      render: (profileImageUrl, item) => {
        return (
          <EuiAvatar
            size="m"
            name={item.firstName + ' ' + item.lastName}
            imageUrl={profileImageUrl}
          />)
      }
    },
    {
      field: 'email',
      name: 'Email',
      sortable: true,
      dataType: 'string'
    },
    {
      field: 'emailAliases',
      name: 'Email Aliases (from your profile)',
      dataType: 'string', render: (emailAliases, item) => {
        return (
          <div style={{ display: "block" }}>
            {emailAliases && emailAliases.map(e => {
              return (<div><EuiText size='s' color='subdued'>{e}</EuiText></div>)
            })
            }
          </div>
        )
      }
    },
    {
      field: 'brands',
      name: 'Brands',
      dataType: 'string',
      render: (brands, item) => {
        return (
          <EuiFlexGroup direction={"column"}>
            {(brands || []).map(brandId => {
              return (
                <EuiFlexItem style={{ margin: "0" }} key={brandId}>{(accountContext?.account?.brands?.find(b => b.brandId === brandId))?.brandName}</EuiFlexItem>
              )
            })}
          </EuiFlexGroup>
        )
      }
    },
    {
      field: 'role',
      width: "75px",
      name: 'Role',
      dataType: 'string',
      sortable: true,
      render: (r, item) => {
        return (
          <EuiBadge
            color={r === 'admin' ? '#B00020' : '#27AAE1'}
          >
            {r}
          </EuiBadge>
        )
      }
    },
    {
      name: 'Actions',
      width: "75px",
      actions: [{
        name: 'Remove',
        description: 'Remove this user',
        icon: 'trash',
        color: 'danger',
        type: 'icon',
        onClick: deleteUser,
        isPrimary: true,
        'data-test-subj': 'action-delete',
        enabled: () => !removingUser && !addingUser
      }],
    }
  ]

  const [toasts, setToasts] = useState([]);
  const genToastId = htmlIdGenerator('toast');
  const addToast = (toast) => {
    toast.id = genToastId();
    setToasts(toasts.concat(toast));
  };
  const removeToast = removedToast => {
    setToasts(toasts.filter(toast => toast.id !== removedToast.id));
  };

  const [sortField, setSortField] = useState('email');
  const [sortDirection, setSortDirection] = useState('asc');
  const onTableChange = ({ sort = {} }) => {
    const { field: sortField, direction: sortDirection } = sort;
    setSortField(sortField);
    setSortDirection(sortDirection);
    if (sortDirection === 'asc') setUserDetails([...userDetails].sort((a, b) => a[sortField].localeCompare(b[sortField])))
    else setUserDetails([...userDetails].sort((a, b) => b[sortField].localeCompare(a[sortField])))

  };
  const sorting = {
    sort: {
      field: sortField,
      direction: sortDirection,
    }
  };

  return (
    <Fragment>
      <EuiGlobalToastList
        toasts={toasts}
        dismissToast={removeToast}
        toastLifeTimeMs={3000}
      />
      <EuiFlexGroup>
        <EuiFlexItem grow={false}>
          <EuiButton size="s" isLoading={addingUser} disabled={isModalVisible || removingUser || addingUser} fill onClick={showModal}>Add Admin</EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />
      <EuiBasicTable
        tableCaption="List of account users"
        items={userDetails || []}
        rowHeader="sk"
        columns={userColumns}
        tableLayout="auto"
        sorting={sorting}
        onChange={onTableChange}
      />
      {isModalVisible && <UserAdd done={addUser} cancel={cancelAddUser} />}
    </Fragment>
  );
};