import React, { Fragment, useState, useEffect } from 'react';
import {
    EuiForm,
    EuiFormRow,
    EuiFieldText,
    EuiDescribedFormGroup,
    EuiCodeBlock,
    EuiBottomBar,
    EuiFlexGroup,
    EuiFlexItem,
    EuiButton,
    EuiSelect,
    EuiSpacer,
    EuiSwitch,
    EuiProgress,
    EuiButtonEmpty,
    useEuiTheme,
    EuiHorizontalRule,
    EuiPanel,
    EuiText,
    EuiStat,
    EuiImage,
    EuiFormHelpText,
    EuiFieldNumber,
    EuiLink,
    EuiFilePicker,
    EuiTextArea,
    EuiButtonIcon,
    htmlIdGenerator,
    EuiGlobalToastList,
    EuiRadio
} from '@elastic/eui';
import useAxios from 'axios-hooks';

import { v4 as uuidv4 } from 'uuid';

// import NurtureImage1 from '../Assets/nurture-email-screenshots-1.gif'
// import NurtureImage2 from '../Assets/nurture-email-screenshots-2.gif'
// import NurtureImage3 from '../Assets/nurture-email-screenshots-3.gif'
import NurtureImage1 from '../Assets/nurturev2_step1.gif'
import NurtureImage2 from '../Assets/nurturev2_step2.gif'
import NurtureImage3 from '../Assets/nurturev2_step3.gif'
import NurtureImage4 from '../Assets/nurture-email-screenshots-4.gif'
import NurtureImage5 from '../Assets/nurture-email-screenshots-5.gif'
import NurtureImage6 from '../Assets/nurture-email-screenshots-6.gif'
import NurtureImage7 from '../Assets/nurture-email-screenshots-7.gif'
import NurtureImage8 from '../Assets/nurture-email-screenshots-8.gif'
import ReactQuill from 'react-quill';
import ImageLoader from './ImageLoader';

import axios from 'axios'
import { makeUseAxios } from 'axios-hooks'
import FileLoader from './FileLoader';

const genToastId = htmlIdGenerator('toast');

const useAxiosUpload = makeUseAxios({
    axios: axios.create()
})

const nurtureScreenshots = [NurtureImage1, NurtureImage2, NurtureImage3, NurtureImage4, NurtureImage5, NurtureImage6, NurtureImage7, NurtureImage8]

export default function BrandSettings(props) {

    // Set default values if missing
    if (props?.brand?.declineMessage == null) props.brand.declineMessage = '';
    if (props?.brand?.nurture && props?.brand?.nurture?.active == null) props.brand.nurture.active = false;
    if (props?.brand?.nurture && props?.brand?.nurture?.excludeRoles == null) props.brand.nurture.excludeRoles = false;

    const [toasts, setToasts] = useState([]);
    const addToast = (toast) => {
        toast.id = genToastId();
        setToasts(toasts.concat(toast));
    };
    const removeToast = removedToast => {
        setToasts(toasts.filter(toast => toast.id !== removedToast.id));
    };

    let euiTheme = useEuiTheme().euiTheme;

    const autoDeclineOptions = [
        { value: 8, text: '8' },
        { value: 12, text: '12' },
        { value: 24, text: '24' },
        { value: 48, text: '48' }
    ];
    const [useDefaultDeclineMessage, setUseDefaultDeclineMessage] = useState(!props?.brand?.declineMessage);
    const [declineMessage, setDeclineMessage] = useState(props?.brand?.declineMessage || '');
    const onChangeDeclineMessage = (e) => {
        setDeclineMessage(e);
    };
    const [autoDeclineHours, setAutoDecline] = useState(props?.brand?.autoDeclineHours || autoDeclineOptions[0].value);
    const onChangeAutoDeclineHours = (e) => {
        setAutoDecline(parseInt(e.target.value));
    };
    const [totalFieldCompletion, setTotalFieldCompletion] = useState(0)
    const [totalFieldCount, setTotalFieldCount] = useState(0)
    const [nurtureStep, setNurtureStep] = useState(5)
    const [nurtureStepFiveFields, setNurtureStepFiveFields] = useState(0)
    const [nurtureStepFiveFieldsRemaining, setNurtureStepFiveFieldsRemaining] = useState(0)
    const [nurtureStepSixFields, setNurtureStepSixFields] = useState(1)
    const [nurtureStepSixFieldsRemaining, setNurtureStepSixFieldsRemaining] = useState(0)
    const [nurtureStepSevenFields, setNurtureStepSevenFields] = useState(0)
    const [nurtureStepSevenFieldsRemaining, setNurtureStepSevenFieldsRemaining] = useState(0)
    const [nurtureStepEightFields, setNurtureStepEightFields] = useState(0)
    const [nurtureStepEightFieldsRemaining, setNurtureStepEightFieldsRemaining] = useState(0)
    const [hasChanges, setHasChanges] = useState(false);
    const [step5_AudienceReportUpload, set_step5_AudienceReportUpload] = useState((props?.brand?.nurture?.step5_AudienceReportUrl || 'https://cdn.swiftrev').startsWith('https://cdn.swiftrev'))
    const [step6_EditorialCalendarUpload, set_step6_EditorialCalendarUpload] = useState((props?.brand?.nurture?.step6_EditorialCalendarUrl || 'https://cdn.swiftrev').startsWith('https://cdn.swiftrev'))
    const [step7_MediaKitUpload, set_step7_MediaKitUpload] = useState((props?.brand?.nurture?.step7_MediaKitUrl || 'https://cdn.swiftrev').startsWith('https://cdn.swiftrev'))

    const [nurtureSettings, setNurtureSettings] = useState(props?.brand?.nurture);
    const onChangeNurtureSettings = (newValues) => {
        const curValues = JSON.parse(JSON.stringify(nurtureSettings))
        setNurtureSettings({ ...curValues, ...newValues })
    }

    const onChangeNutureFile = async (propName, downloadUrl) => {
        const curValues = JSON.parse(JSON.stringify(nurtureSettings))
        curValues[propName] = downloadUrl;
        setNurtureSettings({ ...curValues })
    }

    const onChangeStep7Solutions = async (indx, propName, newValue) => {
        const curValues = JSON.parse(JSON.stringify(nurtureSettings))
        curValues.step7_Solutions[indx][propName] = newValue;
        setNurtureSettings({ ...curValues })
    }
    const onChangeStep8Subproducts = async (indx, propName, newValue) => {
        const curValues = JSON.parse(JSON.stringify(nurtureSettings))
        curValues.step8_Subproducts[indx][propName] = newValue;
        setNurtureSettings({ ...curValues })
    }

    const addNurtureSolution = () => {
        const curValues = JSON.parse(JSON.stringify(nurtureSettings))
        if (!curValues.step7_Solutions) curValues.step7_Solutions = [];
        curValues.step7_Solutions.push({
            "imageUrl": '',
            "title": '',
            "description": ''
        })
        setNurtureSettings({ ...curValues })
    }

    const addNurtureSubproduct = () => {
        const curValues = JSON.parse(JSON.stringify(nurtureSettings))
        if (!curValues.step8_Subproducts) curValues.step8_Subproducts = [];
        curValues.step8_Subproducts.push({
            "imageUrl": '',
            "title": '',
            "description": ''
        })
        setNurtureSettings({ ...curValues })
    }

    const removeNurtureSolution = (indx) => {
        const curValues = JSON.parse(JSON.stringify(nurtureSettings))
        curValues.step7_Solutions.splice(indx, 1)
        setNurtureSettings({ ...curValues })
    }

    const removeNurtureSubproduct = (indx) => {
        const curValues = JSON.parse(JSON.stringify(nurtureSettings))
        curValues.step8_Subproducts.splice(indx, 1)
        setNurtureSettings({ ...curValues })
    }

    useEffect(() => {
        setHasChanges(
            declineMessage !== props.brand.declineMessage ||
            autoDeclineHours !== props.brand.autoDeclineHours ||
            JSON.stringify(nurtureSettings) !== JSON.stringify(props.brand.nurture)
        );
        // Check step 5 filed completion settings
        let step5FieldsRemaining = 0;
        let step5FieldCount = 0
        Object.keys(nurtureSettings).forEach(k => {
            if (k.startsWith('step5_')) {
                step5FieldCount += 1
                if (!nurtureSettings[k] || nurtureSettings[k] === 0) { step5FieldsRemaining += 1 }
            }
        })
        setNurtureStepFiveFieldsRemaining(step5FieldsRemaining)
        setNurtureStepFiveFields(step5FieldCount)

        // Check step 6 fields
        let step6FieldCount = 1;
        let step6FieldsRemaining = nurtureSettings.step6_Skip === 'yes' ? 0 : (!!nurtureSettings?.step6_EditorialCalendarUrl ? 0 : 1)
        setNurtureStepSixFieldsRemaining(step6FieldsRemaining)

        // Check step 7 filed completion settings
        let step7FieldsRemaining = 0;
        let step7FieldCount = ((nurtureSettings?.step7_Solutions || []).length * 3) + 1
        if (!nurtureSettings.step7_MediaKitUrl || nurtureSettings.step7_MediaKitUrl === '') { step7FieldsRemaining += 1 }
        (nurtureSettings?.step7_Solutions || []).forEach(s => {
            if (!s.title || s.title === '') { step7FieldsRemaining += 1 }
            if (!s.description || s.description === '') { step7FieldsRemaining += 1 }
            if (!s.imageUrl || s.imageUrl === '') { step7FieldsRemaining += 1 }
        })
        setNurtureStepSevenFields(step7FieldCount)
        setNurtureStepSevenFieldsRemaining(step7FieldsRemaining)

        // Check step 7 filed completion settings
        let step8FieldsRemaining = 0;
        let step8FieldCount = ((nurtureSettings?.step8_Subproducts || []).length * 3) + 1
        if (!nurtureSettings.step8_ProductTitle || nurtureSettings.step8_ProductTitle === '') { step8FieldsRemaining += 1 }
        (nurtureSettings?.step8_Subproducts || []).forEach(s => {
            if (!s.title || s.title === '') { step8FieldsRemaining += 1 }
            if (!s.description || s.description === '') { step8FieldsRemaining += 1 }
            if (!s.imageUrl || s.imageUrl === '') { step8FieldsRemaining += 1 }
        })
        setNurtureStepEightFields(step8FieldCount)
        setNurtureStepEightFieldsRemaining(step8FieldsRemaining)

        let totalFieldCount = step5FieldCount + step6FieldCount + step7FieldCount + step8FieldCount
        setTotalFieldCount(totalFieldCount)
        let totalFieldsRemaining = step5FieldsRemaining + step6FieldsRemaining + step7FieldsRemaining + step8FieldsRemaining
        setTotalFieldCompletion(totalFieldCount - totalFieldsRemaining)

    }, [
        declineMessage,
        autoDeclineHours,
        nurtureSettings
    ]);

    // Save brand settings
    const [
        { data: updateResults, loading: updating, error: errorUpdating }, executeUpdate
    ] = useAxios({
        url: '/data/brands/update-settings',
        method: 'POST'
    }, {
        manual: true
    })
    async function update() {
        await executeUpdate({
            data: {
                "accountId": props.brand.accountId,
                "brandId": props.brand.brandId,
                autoDeclineHours,
                declineMessage,
                "nurture": nurtureSettings
            }
        })
        props.refetch()
    }

    // Send test nurture email
    const [{ loading: sendingTestEmail }, sendTestEmail] = useAxios(
        {
            url: '/data/send-nurture-test',
            method: 'POST'
        },
        { manual: true }
    );

    // Send test decline email
    const [{ loading: sendingTestDeclineEmail }, sendTestDeclineEmail] = useAxios(
        {
            url: '/data/send-handleit-test',
            method: 'POST'
        },
        { manual: true }
    );

    const onTestEmailClick = (stepNumber) => {
        sendTestEmail({
            data: {
                "accountId": props.brand.accountId,
                "brandId": props.brand.brandId,
                "nurtureStep": stepNumber
            }
        })
            .then(() => {
                addToast({ title: "Test Email sent!", color: 'success' })
            })
            .catch((e) => {
                addToast({ title: "Error sending Test Email", color: 'danger' })
            });
    }


    const onTestDeclineEmailClick = (declineMessage) => {
        sendTestDeclineEmail({
            data: {
                "accountId": props.brand.accountId,
                "brandId": props.brand.brandId,
                "declineMessage": declineMessage
            }
        })
            .then(() => {
                addToast({ title: "Test Email sent!", color: 'success' })
            })
            .catch((e) => {
                addToast({ title: "Error sending Test Email", color: 'danger' })
            });
    }

    // Custom tab like button styles
    let unSelectedTabStyle = {
        "color": euiTheme.colors.text
    }
    let selectedTabStyle = {
        "backgroundColor": euiTheme.colors.lightestShade,
        "color": euiTheme.colors.link
    }
    return (
        <Fragment>
            <EuiGlobalToastList
                toasts={toasts}
                dismissToast={removeToast}
                toastLifeTimeMs={3000}
            />
            <EuiForm component="form">
                <EuiDescribedFormGroup fullWidth ratio="quarter"
                    title={<h2>HandleIt Emails</h2>}
                    description={
                        <Fragment>
                            <div>Customize your message then click 'Send Test Handleit Email' to see a preview.</div>
                            <div>Note - if you go back to the Default message, your custom message will not be saved.</div>
                        </Fragment>
                    }
                >
                    <EuiFormRow>
                        <EuiSwitch label='Use Default HandleIt Email'
                            checked={useDefaultDeclineMessage}
                            onChange={(e) => {
                                setUseDefaultDeclineMessage(e.target.checked);
                                if (e.target.checked) setDeclineMessage('')
                            }}
                        />
                    </EuiFormRow>
                    {!useDefaultDeclineMessage &&
                        <EuiFormRow fullWidth label="">
                            <ReactQuill readOnly={useDefaultDeclineMessage} preserveWhitespace={true} style={{ backgroundColor: "white" }} theme="snow" value={declineMessage} onChange={(e) => { onChangeDeclineMessage(e) }} />
                        </EuiFormRow>
                    }
                    <EuiFormRow>
                        <EuiButton isLoading={sendingTestDeclineEmail} onClick={() => onTestDeclineEmailClick(declineMessage)}>Send Test HandleIt Email</EuiButton>
                    </EuiFormRow>
                </EuiDescribedFormGroup>
                <EuiSpacer size='xl'></EuiSpacer>
                {props?.brand?.featureLeads === true &&
                    <Fragment>
                        <EuiDescribedFormGroup fullWidth ratio="quarter"
                            title={<h2>Nurture</h2>}
                            description={
                                <Fragment>
                                    Settings to control nurture marketing campaigns.
                                </Fragment>
                            }
                        >
                            <EuiFlexGroup >
                                <EuiFlexItem grow={false}>
                                    <EuiFormRow label="Active">
                                        <EuiSwitch showLabel={false} label=''
                                            disabled={totalFieldCompletion < totalFieldCount}
                                            checked={totalFieldCompletion < totalFieldCount ? false : nurtureSettings.active}
                                            onChange={(e) => onChangeNurtureSettings({ active: e.target.checked })}
                                        />
                                    </EuiFormRow>
                                </EuiFlexItem>
                                <EuiFlexItem grow={false}>
                                    <EuiFormRow label="Total Field Completion">
                                        <EuiProgress style={{ marginTop: "5px" }} size='l' value={totalFieldCompletion || 0} max={totalFieldCount} />
                                    </EuiFormRow>
                                </EuiFlexItem>
                                <EuiFlexItem grow={false}>
                                    <EuiLink target="_blank" href={props.brand.meetingRequestUrl}>View Meeting Request Page</EuiLink>
                                </EuiFlexItem>
                                {/* <EuiFlexItem grow={false}>
                                    <EuiFormRow
                                        label="Exclude Role Emails"
                                        helpText={(<EuiLink target='_blank' href='https://swiftrev.com/guides/nurture'>Find Out More</EuiLink>)}>
                                        <EuiSwitch showLabel={false} label=''
                                            disabled={!nurtureSettings.active}
                                            checked={nurtureSettings.excludeRoles || false}
                                            onChange={(e) => onChangeNurtureSettings({ excludeRoles: e.target.checked })}
                                        />
                                    </EuiFormRow>
                                </EuiFlexItem> */}
                            </EuiFlexGroup>
                        </EuiDescribedFormGroup>
                        <EuiFlexGroup responsive={false} wrap gutterSize="s" alignItems="center">
                            <EuiFlexItem grow={false}>
                                <EuiButtonEmpty
                                    style={nurtureStep === 1 ? selectedTabStyle : unSelectedTabStyle}
                                    onClick={() => { setNurtureStep(1) }}
                                    iconType={'check'}
                                    iconSide="right"
                                >
                                    Step 1
                                </EuiButtonEmpty>
                            </EuiFlexItem>
                            <EuiFlexItem grow={false}>
                                <EuiButtonEmpty
                                    style={nurtureStep === 2 ? selectedTabStyle : unSelectedTabStyle}
                                    onClick={() => { setNurtureStep(2) }}
                                    iconType={'check'}
                                    iconSide="right"
                                >
                                    Step 2
                                </EuiButtonEmpty>
                            </EuiFlexItem>
                            <EuiFlexItem grow={false}>
                                <EuiButtonEmpty
                                    style={nurtureStep === 3 ? selectedTabStyle : unSelectedTabStyle}
                                    onClick={() => { setNurtureStep(3) }}
                                    iconType={'check'}
                                    iconSide="right"
                                >
                                    Step 3
                                </EuiButtonEmpty>
                            </EuiFlexItem>
                            <EuiFlexItem grow={false}>
                                <EuiButtonEmpty
                                    style={nurtureStep === 4 ? selectedTabStyle : unSelectedTabStyle}
                                    onClick={() => { setNurtureStep(4) }}
                                    iconType={'check'}
                                    iconSide="right"
                                >
                                    Step 4
                                </EuiButtonEmpty>
                            </EuiFlexItem>
                            <EuiFlexItem grow={false}>
                                <EuiButtonEmpty
                                    style={nurtureStep === 5 ? selectedTabStyle : unSelectedTabStyle}
                                    onClick={() => { setNurtureStep(5) }}
                                    iconType={nurtureStepFiveFieldsRemaining === 0 ? 'check' : 'alert'}
                                    iconSide="right">
                                    Step 5
                                </EuiButtonEmpty>
                            </EuiFlexItem>
                            <EuiFlexItem grow={false}>
                                <EuiButtonEmpty
                                    style={nurtureStep === 6 ? selectedTabStyle : unSelectedTabStyle}
                                    onClick={() => { setNurtureStep(6) }}
                                    iconType={nurtureStepSixFieldsRemaining === 0 ? 'check' : 'alert'}
                                    iconSide="right">
                                    Step 6
                                </EuiButtonEmpty>
                            </EuiFlexItem>
                            <EuiFlexItem grow={false}>
                                <EuiButtonEmpty
                                    style={nurtureStep === 7 ? selectedTabStyle : unSelectedTabStyle}
                                    onClick={() => { setNurtureStep(7) }}
                                    iconType={nurtureStepSevenFieldsRemaining === 0 ? 'check' : 'alert'}
                                    iconSide="right">
                                    Step 7
                                </EuiButtonEmpty>
                            </EuiFlexItem>
                            <EuiFlexItem grow={false}>
                                <EuiButtonEmpty
                                    style={nurtureStep === 8 ? selectedTabStyle : unSelectedTabStyle}
                                    onClick={() => { setNurtureStep(8) }}
                                    iconType={nurtureStepEightFieldsRemaining === 0 ? 'check' : 'alert'}
                                    iconSide="right">
                                    Step 8
                                </EuiButtonEmpty>
                            </EuiFlexItem>
                        </EuiFlexGroup>
                        <EuiHorizontalRule margin='s' />
                        <EuiPanel hasShadow={false} >
                            <EuiFlexGroup>
                                <EuiFlexItem grow={7}>
                                    {(nurtureStep === 1) &&
                                        <div>
                                            <EuiText size='s'>In this step, we emphasize the primary benefits of buying a paid press release, focusing on social proofing as the primary benefit.</EuiText>
                                            <EuiSpacer />
                                            <EuiFlexGroup>
                                                <EuiFlexItem grow={false}>
                                                    <EuiStat title="0" description="Total Fields" reverse />
                                                </EuiFlexItem>
                                                <EuiFlexItem grow={false}>
                                                    <EuiStat title="0" description="Fields Remaining" reverse titleColor="success" />
                                                </EuiFlexItem>
                                                <EuiFlexItem grow={true} />
                                                <EuiFlexItem grow={false}>
                                                    <EuiButton isLoading={sendingTestEmail} onClick={() => onTestEmailClick(nurtureStep)}>Send Test Email</EuiButton>
                                                </EuiFlexItem>
                                            </EuiFlexGroup>
                                            <EuiHorizontalRule />
                                            <EuiText size='s'>No fields exist for this step. Continue on to another!</EuiText>
                                        </div>
                                    }
                                    {(nurtureStep === 2) &&
                                        <div>
                                            <EuiText size='s'>In this step, we focus on the benefits of the SwiftRev Report that is emailed to editors at purchase. We present it as extra "VIP consideration".</EuiText>
                                            <EuiSpacer />
                                            <EuiFlexGroup>
                                                <EuiFlexItem grow={false}>
                                                    <EuiStat title="0" description="Total Fields" reverse />
                                                </EuiFlexItem>
                                                <EuiFlexItem grow={false}>
                                                    <EuiStat title="0" description="Fields Remaining" reverse titleColor="success" />
                                                </EuiFlexItem>
                                                <EuiFlexItem grow={true} />
                                                <EuiFlexItem grow={false}>
                                                    <EuiButton isLoading={sendingTestEmail} onClick={() => onTestEmailClick(nurtureStep)}>Send Test Email</EuiButton>
                                                </EuiFlexItem>
                                            </EuiFlexGroup>
                                            <EuiHorizontalRule />
                                            <EuiText size='s'>No fields exist for this step. Continue on to another!</EuiText>
                                        </div>
                                    }
                                    {(nurtureStep === 3) &&
                                        <div>
                                            <EuiText size='s'>In this step, we promote bulk purchasing of press releases. Note - we drive action to go to the meeting request form. Make sure sales is prepared for a response.</EuiText>
                                            <EuiSpacer />
                                            <EuiFlexGroup>
                                                <EuiFlexItem grow={false}>
                                                    <EuiStat title="0" description="Total Fields" reverse />
                                                </EuiFlexItem>
                                                <EuiFlexItem grow={false}>
                                                    <EuiStat title="0" description="Fields Remaining" reverse titleColor="success" />
                                                </EuiFlexItem>
                                                <EuiFlexItem grow={true} />
                                                <EuiFlexItem grow={false}>
                                                    <EuiButton isLoading={sendingTestEmail} onClick={() => onTestEmailClick(nurtureStep)}>Send Test Email</EuiButton>
                                                </EuiFlexItem>
                                            </EuiFlexGroup>
                                            <EuiHorizontalRule />
                                            <EuiText size='s'>No fields exist for this step. Continue on to another!</EuiText>
                                        </div>
                                    }
                                    {(nurtureStep === 4) &&
                                        <div>
                                            <EuiText size='s'>In this step the idea of a "Journey" is introduced. Effort is made to ensure brand credibility and set expectations with the user of upcoming emails - to increase open rate.</EuiText>
                                            <EuiSpacer />
                                            <EuiFlexGroup>
                                                <EuiFlexItem grow={false}>
                                                    <EuiStat title="0" description="Total Fields" reverse />
                                                </EuiFlexItem>
                                                <EuiFlexItem grow={false}>
                                                    <EuiStat title="0" description="Fields Remaining" reverse titleColor="success" />
                                                </EuiFlexItem>
                                                <EuiFlexItem grow={true} />
                                                <EuiFlexItem grow={false}>
                                                    <EuiButton isLoading={sendingTestEmail} onClick={() => onTestEmailClick(nurtureStep)}>Send Test Email</EuiButton>
                                                </EuiFlexItem>
                                            </EuiFlexGroup>
                                            <EuiHorizontalRule />
                                            <EuiText size='s'>No fields exist for this step. Continue on to another!</EuiText>
                                        </div>
                                    }
                                    {(nurtureStep === 5) &&
                                        <div>
                                            <EuiText size='s'>In this step we dive into the details of your audience. Through a set of standardized audience metrics and access to an "Audience Report", you shed full light on why your audience outperforms.</EuiText>
                                            <EuiSpacer />
                                            <EuiFlexGroup>
                                                <EuiFlexItem grow={false}>
                                                    <EuiStat title={nurtureStepFiveFields} description="Total Fields" reverse />
                                                </EuiFlexItem>
                                                <EuiFlexItem grow={false}>
                                                    <EuiStat title={nurtureStepFiveFieldsRemaining} description="Fields Remaining" reverse titleColor={nurtureStepFiveFieldsRemaining > 0 ? 'danger' : 'success'} />
                                                </EuiFlexItem>
                                                <EuiFlexItem grow={true} />
                                                <EuiFlexItem grow={false}>
                                                    <EuiButton isLoading={sendingTestEmail} onClick={() => onTestEmailClick(nurtureStep)}>Send Test Email</EuiButton>
                                                </EuiFlexItem>
                                            </EuiFlexGroup>
                                            <EuiHorizontalRule />
                                            <EuiText size='xs'>All fields are <strong>required. </strong><EuiLink external href='https://www.swiftrev.com/guides/nurture-step-5'>Need help filling out these fields?</EuiLink></EuiText>
                                            <EuiSpacer size='xxl' />
                                            <EuiText size='l'><strong>Audience Report</strong></EuiText>
                                            <EuiSpacer />
                                            <EuiFlexGroup>
                                                <EuiFlexItem grow={false}><EuiRadio id='step5_upload' value={true} onChange={(e) => set_step5_AudienceReportUpload(e.target.checked)} checked={step5_AudienceReportUpload} name='step5_AudienceReportUrl' label='Upload File'></EuiRadio></EuiFlexItem>
                                                <EuiFlexItem grow={false}><EuiRadio id='step5_url' value={false} onChange={(e) => set_step5_AudienceReportUpload(!e.target.checked)} checked={!step5_AudienceReportUpload} name='step5_AudienceReportUrl' label='Specify Url'></EuiRadio></EuiFlexItem>
                                            </EuiFlexGroup>
                                            {step5_AudienceReportUpload &&
                                                <EuiFormRow fullWidth hasEmptyLabelSpace helpText='Select your Audience Report file. Only PDF format allowed. Max size 5 MB.'>
                                                    <FileLoader accountId={props.brand.accountId} brandId={props.brand.brandId} onChange={(file) => { onChangeNutureFile('step5_AudienceReportUrl', file) }} downloadUrl={nurtureSettings?.step5_AudienceReportUrl} />
                                                </EuiFormRow>
                                            }
                                            {!step5_AudienceReportUpload &&
                                                <EuiFormRow fullWidth hasEmptyLabelSpace helpText='Url of your audience report file.'>

                                                    <EuiFieldText fullWidth
                                                        value={nurtureSettings?.step5_AudienceReportUrl || ''}
                                                        onChange={(e) => onChangeNurtureSettings({ step5_AudienceReportUrl: e.target.value })}
                                                    />
                                                </EuiFormRow>
                                            }
                                            <EuiSpacer />
                                            <EuiText size='l'><strong>Digital Reach</strong></EuiText>
                                            <EuiSpacer />
                                            <EuiFlexGroup>
                                                <EuiFlexItem>
                                                    <EuiFormRow
                                                        label='Average Unique Monthly Users'
                                                        helpText='This should be accurate and verifiable'
                                                    >
                                                        <EuiFieldNumber
                                                            fullWidth min={0}
                                                            value={nurtureSettings?.step5_AvgMonthlyUsers || 0}
                                                            onChange={(e) => onChangeNurtureSettings({ step5_AvgMonthlyUsers: e.target.value })}
                                                        />
                                                    </EuiFormRow>
                                                </EuiFlexItem>
                                                <EuiFlexItem>
                                                    <EuiFormRow
                                                        label='Average Monthly Page Views'
                                                        helpText='This should be accurate and verifiable'
                                                    >
                                                        <EuiFieldNumber
                                                            fullWidth min={0}
                                                            value={nurtureSettings?.step5_AvgMonthlyViews || 0}
                                                            onChange={(e) => onChangeNurtureSettings({ step5_AvgMonthlyViews: e.target.value })}
                                                        />
                                                    </EuiFormRow>
                                                </EuiFlexItem>
                                                <EuiFlexItem>
                                                    <EuiFormRow
                                                        label='eNewsLetter Reach'
                                                        helpText='This should be accurate and verifiable'
                                                    >
                                                        <EuiFieldNumber
                                                            fullWidth min={0}
                                                            value={nurtureSettings?.step5_NewsletterReach || 0}
                                                            onChange={(e) => onChangeNurtureSettings({ step5_NewsletterReach: e.target.value })}
                                                        />
                                                    </EuiFormRow>
                                                </EuiFlexItem>
                                            </EuiFlexGroup>
                                            <EuiSpacer size='xxl' />
                                            <EuiText size='l'><strong>Top Job Functions</strong></EuiText>
                                            <EuiLink style={{ fontSize: "10px" }} href='https://www.swiftrev.com/guides/nurture-step-5/#1' external>What's a job function?</EuiLink>
                                            <EuiSpacer />
                                            <EuiFlexGroup>
                                                <EuiFlexItem>
                                                    <EuiFormRow
                                                        label='Job Function 1, Title'
                                                    >
                                                        <EuiFieldText
                                                            placeholder='e.g. Executive Management'
                                                            fullWidth
                                                            value={nurtureSettings?.step5_JobFunction1Title || ''}
                                                            onChange={(e) => onChangeNurtureSettings({ step5_JobFunction1Title: e.target.value })}
                                                        />
                                                    </EuiFormRow>
                                                </EuiFlexItem>
                                                <EuiFlexItem>
                                                    <EuiFormRow
                                                        label='Job Function 2, Title'
                                                    >
                                                        <EuiFieldText
                                                            placeholder='e.g. Executive Management'
                                                            fullWidth
                                                            value={nurtureSettings?.step5_JobFunction2Title || ''}
                                                            onChange={(e) => onChangeNurtureSettings({ step5_JobFunction2Title: e.target.value })}
                                                        />
                                                    </EuiFormRow>
                                                </EuiFlexItem>
                                                <EuiFlexItem>
                                                    <EuiFormRow
                                                        label='Job Function 3, Title'
                                                    >
                                                        <EuiFieldText
                                                            placeholder='e.g. Executive Management'
                                                            fullWidth
                                                            value={nurtureSettings?.step5_JobFunction3Title || ''}
                                                            onChange={(e) => onChangeNurtureSettings({ step5_JobFunction3Title: e.target.value })}
                                                        />
                                                    </EuiFormRow>
                                                </EuiFlexItem>
                                            </EuiFlexGroup>
                                            <EuiFlexGroup>
                                                <EuiFlexItem>
                                                    <EuiFormRow
                                                        label='Job Function 1, %'
                                                        helpText='Do not include "%" just the number'
                                                    >
                                                        <EuiFieldNumber
                                                            fullWidth min={0}
                                                            value={nurtureSettings?.step5_JobFunction1Percent || 0}
                                                            onChange={(e) => onChangeNurtureSettings({ step5_JobFunction1Percent: e.target.value })}
                                                        />
                                                    </EuiFormRow>
                                                </EuiFlexItem>
                                                <EuiFlexItem>
                                                    <EuiFormRow
                                                        label='Job Function 2, %'
                                                        helpText='Do not include "%" just the number'
                                                    >
                                                        <EuiFieldNumber
                                                            fullWidth min={0}
                                                            value={nurtureSettings?.step5_JobFunction2Percent || 0}
                                                            onChange={(e) => onChangeNurtureSettings({ step5_JobFunction2Percent: e.target.value })}
                                                        />
                                                    </EuiFormRow>
                                                </EuiFlexItem>
                                                <EuiFlexItem>
                                                    <EuiFormRow
                                                        label='Job Function 3, %'
                                                        helpText='Do not include "%" just the number'
                                                    >
                                                        <EuiFieldNumber
                                                            fullWidth min={0}
                                                            value={nurtureSettings?.step5_JobFunction3Percent || 0}
                                                            onChange={(e) => onChangeNurtureSettings({ step5_JobFunction3Percent: e.target.value })}
                                                        />
                                                    </EuiFormRow>
                                                </EuiFlexItem>
                                            </EuiFlexGroup>

                                            <EuiSpacer size='xxl' />
                                            <EuiText size='l'><strong>Most Viewed Articles</strong></EuiText>
                                            <EuiLink style={{ fontSize: "10px" }} href='https://www.swiftrev.com/guides/nurture-step-5/#2' external>How do you measure this?</EuiLink>
                                            <EuiSpacer />
                                            <EuiFlexGroup>
                                                <EuiFlexItem>
                                                    <EuiFormRow
                                                        label='Article 1, Title'
                                                    >
                                                        <EuiFieldText
                                                            fullWidth
                                                            value={nurtureSettings?.step5_Article1Title || ''}
                                                            onChange={(e) => onChangeNurtureSettings({ step5_Article1Title: e.target.value })}
                                                        />
                                                    </EuiFormRow>
                                                </EuiFlexItem>
                                                <EuiFlexItem>
                                                    <EuiFormRow
                                                        label='Article 2, Title'
                                                    >
                                                        <EuiFieldText
                                                            fullWidth
                                                            value={nurtureSettings?.step5_Article2Title || ''}
                                                            onChange={(e) => onChangeNurtureSettings({ step5_Article2Title: e.target.value })}
                                                        />
                                                    </EuiFormRow>
                                                </EuiFlexItem>
                                                <EuiFlexItem>
                                                    <EuiFormRow
                                                        label='Article 3, Title'
                                                    >
                                                        <EuiFieldText
                                                            fullWidth
                                                            value={nurtureSettings?.step5_Article3Title || ''}
                                                            onChange={(e) => onChangeNurtureSettings({ step5_Article3Title: e.target.value })}
                                                        />
                                                    </EuiFormRow>
                                                </EuiFlexItem>
                                            </EuiFlexGroup>
                                            <EuiFlexGroup>
                                                <EuiFlexItem>
                                                    <EuiFormRow
                                                        label='Article 1, Url'
                                                    >
                                                        <EuiFieldText
                                                            fullWidth
                                                            value={nurtureSettings?.step5_Article1Url || ''}
                                                            onChange={(e) => onChangeNurtureSettings({ step5_Article1Url: e.target.value })}
                                                        />
                                                    </EuiFormRow>
                                                </EuiFlexItem>
                                                <EuiFlexItem>
                                                    <EuiFormRow
                                                        label='Article 2, Url'
                                                    >
                                                        <EuiFieldText
                                                            fullWidth
                                                            value={nurtureSettings?.step5_Article2Url || ''}
                                                            onChange={(e) => onChangeNurtureSettings({ step5_Article2Url: e.target.value })}
                                                        />
                                                    </EuiFormRow>
                                                </EuiFlexItem>
                                                <EuiFlexItem>
                                                    <EuiFormRow
                                                        label='Article 3, Url'
                                                    >
                                                        <EuiFieldText
                                                            fullWidth
                                                            value={nurtureSettings?.step5_Article3Url || ''}
                                                            onChange={(e) => onChangeNurtureSettings({ step5_Article3Url: e.target.value })}
                                                        />
                                                    </EuiFormRow>
                                                </EuiFlexItem>
                                            </EuiFlexGroup>
                                        </div>
                                    }
                                    {(nurtureStep === 6) &&
                                        <div>
                                            <EuiText size='s'>In this step we share your Editorial Calendar. Don't have one? No worries - this is our only optional step.</EuiText>
                                            <EuiSpacer />
                                            <EuiFlexGroup>
                                                <EuiFlexItem grow={false}>
                                                    <EuiStat title={nurtureStepSixFields} description="Total Fields" reverse />
                                                </EuiFlexItem>
                                                <EuiFlexItem grow={false}>
                                                    <EuiStat title={nurtureStepSixFieldsRemaining} description="Fields Remaining" reverse titleColor={nurtureStepSixFieldsRemaining === 0 ? 'success' : 'danger'} />
                                                </EuiFlexItem>
                                                <EuiFlexItem grow={true} />
                                                <EuiFlexItem grow={false}>
                                                    <EuiButton isLoading={sendingTestEmail} onClick={() => onTestEmailClick(nurtureStep)}>Send Test Email</EuiButton>
                                                </EuiFlexItem>
                                            </EuiFlexGroup>
                                            <EuiHorizontalRule />
                                            <EuiText size='xs'>All fields are <strong>required. </strong><EuiLink external href='https://www.swiftrev.com/guides/nurture-step-6'>Need help filling out these fields?</EuiLink></EuiText>
                                            <EuiSpacer size='xxl' />
                                            <EuiFlexGroup>
                                                <EuiFlexItem grow={false}>
                                                    <EuiFormRow label="Skip This Step?" fullWidth={true} helpText="Don't have an editorial calendar? Select 'Yes' to skip sending this email." >
                                                        <EuiSelect fullWidth={false}
                                                            options={[{ value: 'yes', text: 'Yes' }, { value: 'no', text: 'No' }]}
                                                            value={nurtureSettings?.step6_Skip || 'no'}
                                                            onChange={(e) => onChangeNurtureSettings({ step6_Skip: e.target.value })}
                                                        />
                                                    </EuiFormRow>
                                                </EuiFlexItem>
                                            </EuiFlexGroup>
                                            <EuiSpacer size='xl' />
                                            <EuiText size='l'><strong>Editorial Calendar</strong></EuiText>
                                            <EuiSpacer />
                                            <EuiFlexGroup>
                                                <EuiFlexItem grow={false}><EuiRadio id='step6_upload' value={true} onChange={(e) => set_step6_EditorialCalendarUpload(e.target.checked)} checked={step6_EditorialCalendarUpload} name='step6_EditorialCalendarUrl' label='Upload File'></EuiRadio></EuiFlexItem>
                                                <EuiFlexItem grow={false}><EuiRadio id='step6_url' value={false} onChange={(e) => set_step6_EditorialCalendarUpload(!e.target.checked)} checked={!step6_EditorialCalendarUpload} name='step6_EditorialCalendarUrl' label='Specify Url'></EuiRadio></EuiFlexItem>
                                            </EuiFlexGroup>
                                            {step6_EditorialCalendarUpload &&
                                                <EuiFormRow fullWidth hasEmptyLabelSpace helpText='Select your Editorial Calendar file. Only PDF format allowed. Max size 5 MB.'>
                                                    <FileLoader accountId={props.brand.accountId} brandId={props.brand.brandId} onChange={(file) => { onChangeNutureFile('step6_EditorialCalendarUrl', file) }} downloadUrl={nurtureSettings?.step6_EditorialCalendarUrl} />
                                                </EuiFormRow>
                                            }
                                            {!step6_EditorialCalendarUpload &&
                                                <EuiFormRow fullWidth hasEmptyLabelSpace helpText='Url of your Editorial Calendar file.'>

                                                    <EuiFieldText fullWidth
                                                        value={nurtureSettings?.step6_EditorialCalendarUrl || ''}
                                                        onChange={(e) => onChangeNurtureSettings({ step6_EditorialCalendarUrl: e.target.value })}
                                                    />
                                                </EuiFormRow>
                                            }
                                            {/* <EuiFormRow fullWidth hasEmptyLabelSpace helpText='Select your Editorial Calendar file. Only PDF format allowed. Max size 5 MB.'>
                                                <FileLoader accountId={props.brand.accountId} brandId={props.brand.brandId} onChange={(file) => { onChangeNutureFile('step6_EditorialCalendarUrl', file) }} downloadUrl={nurtureSettings?.step6_EditorialCalendarUrl} />
                                            </EuiFormRow> */}
                                        </div>
                                    }
                                    {(nurtureStep === 7) &&
                                        <div>
                                            <EuiText size='s'>In this step we focus on your Media Kit and your products that focus on delivering awareness. We've prepopulated one for you.</EuiText>
                                            <EuiSpacer />
                                            <EuiFlexGroup>
                                                <EuiFlexItem grow={false}>
                                                    <EuiStat title={nurtureStepSevenFields} description="Total Fields" reverse />
                                                </EuiFlexItem>
                                                <EuiFlexItem grow={false}>
                                                    <EuiStat title={nurtureStepSevenFieldsRemaining} description="Fields Remaining" reverse titleColor={nurtureStepSevenFieldsRemaining > 0 ? 'danger' : 'success'} />
                                                </EuiFlexItem>
                                                <EuiFlexItem grow={true} />
                                                <EuiFlexItem grow={false}>
                                                    <EuiButton isLoading={sendingTestEmail} onClick={() => onTestEmailClick(nurtureStep)}>Send Test Email</EuiButton>
                                                </EuiFlexItem>
                                            </EuiFlexGroup>
                                            <EuiHorizontalRule />
                                            <EuiText size='xs'>All fields are <strong>required. </strong><EuiLink external href='https://www.swiftrev.com/guides/nurture-step-7'>Need help filling out these fields?</EuiLink></EuiText>
                                            <EuiSpacer size='xxl' />
                                            <EuiText size='l'><strong>Media Kit</strong></EuiText>
                                            <EuiSpacer />
                                            <EuiFlexGroup>
                                                <EuiFlexItem grow={false}><EuiRadio id='step7_upload' value={true} onChange={(e) => set_step7_MediaKitUpload(e.target.checked)} checked={step7_MediaKitUpload} name='step7_MediaKitUrl' label='Upload File'></EuiRadio></EuiFlexItem>
                                                <EuiFlexItem grow={false}><EuiRadio id='step7_url' value={false} onChange={(e) => set_step7_MediaKitUpload(!e.target.checked)} checked={!step7_MediaKitUpload} name='step7_MediaKitUrl' label='Specify Url'></EuiRadio></EuiFlexItem>
                                            </EuiFlexGroup>
                                            {step7_MediaKitUpload &&
                                                <EuiFormRow fullWidth hasEmptyLabelSpace helpText='Select your Media Kit file. Only PDF format allowed. Max size 5 MB.'>
                                                    <FileLoader accountId={props.brand.accountId} brandId={props.brand.brandId} onChange={(file) => { onChangeNutureFile('step7_MediaKitUrl', file) }} downloadUrl={nurtureSettings?.step7_MediaKitUrl} />
                                                </EuiFormRow>
                                            }
                                            {!step7_MediaKitUpload &&
                                                <EuiFormRow fullWidth hasEmptyLabelSpace helpText='Url of your Media Kit file.'>

                                                    <EuiFieldText fullWidth
                                                        value={nurtureSettings?.step7_MediaKitUrl || ''}
                                                        onChange={(e) => onChangeNurtureSettings({ step7_MediaKitUrl: e.target.value })}
                                                    />
                                                </EuiFormRow>
                                            }
                                            {/* <EuiFormRow fullWidth hasEmptyLabelSpace helpText='Select your Meida Kit file. Only PDF format allowed. Max size 5 MB.'>
                                                <FileLoader accountId={props.brand.accountId} brandId={props.brand.brandId} onChange={(file) => { onChangeNutureFile('step7_MediaKitUrl', file) }} downloadUrl={nurtureSettings?.step7_MediaKitUrl} />
                                            </EuiFormRow> */}
                                            <EuiSpacer size='xxl' />
                                            <EuiText size='l'><strong>Awareness Solutions</strong></EuiText>
                                            <EuiText size='xs'>We recommend 3 solutions and prepopulate "Advertising Solutions". <EuiLink external href='https://www.swiftrev.com/guides/nurture-step-7'>See our other recommendations.</EuiLink></EuiText>
                                            <EuiSpacer />
                                            <EuiButton onClick={() => { addNurtureSolution() }}>Add Solution</EuiButton>
                                            <EuiSpacer />
                                            {(nurtureSettings?.step7_Solutions || []).map((solution, indx) => {
                                                return (
                                                    <div className='sr-alert-light' style={{ marginTop: "15px" }}>
                                                        <EuiFlexGroup alignItems='center'>
                                                            <EuiFlexItem>
                                                                <EuiText size='l'><strong>Solution {indx + 1}</strong></EuiText>
                                                            </EuiFlexItem>
                                                            <EuiFlexItem grow={false}>
                                                                {indx > 0 && <EuiButtonIcon aria-label='remove solution' iconType="cross" onClick={() => { removeNurtureSolution(indx) }} />}
                                                            </EuiFlexItem>
                                                        </EuiFlexGroup>
                                                        <EuiSpacer size='xl' />
                                                        <EuiFlexGroup>
                                                            <EuiFlexItem grow={7}>
                                                                <EuiFormRow fullWidth label='Solution Title' helpText='This should be the name of the solution, e.g. "Leads" or "Custom Content".'>
                                                                    <EuiFieldText fullWidth value={solution.title} onChange={(e) => { onChangeStep7Solutions(indx, 'title', e.target.value) }} ></EuiFieldText>
                                                                </EuiFormRow>
                                                                <EuiFormRow fullWidth label='Solution Url' helpText='Link to the solution webpage. Defaults to the Media Kit url if left blank.'>
                                                                    <EuiFieldText fullWidth value={solution.url} onChange={(e) => { onChangeStep7Solutions(indx, 'url', e.target.value) }} ></EuiFieldText>
                                                                </EuiFormRow>
                                                            </EuiFlexItem>
                                                            <EuiFlexItem grow={3}>
                                                                <EuiFormRow label='Solution Image'>
                                                                    <ImageLoader accountId={props.brand.accountId} brandId={props.brand.brandId} imageUrl={solution.imageUrl} onChange={(imageUrl) => { onChangeStep7Solutions(indx, 'imageUrl', imageUrl) }} />
                                                                </EuiFormRow>
                                                            </EuiFlexItem>
                                                        </EuiFlexGroup>
                                                        <EuiSpacer />
                                                        <EuiFormRow fullWidth label='Solution Description'>
                                                            <ReactQuill preserveWhitespace={true} style={{ backgroundColor: "white" }} theme="snow" value={solution.description} onChange={(e) => { onChangeStep7Solutions(indx, 'description', e) }} />
                                                        </EuiFormRow>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    }
                                    {(nurtureStep === 8) &&
                                        <div>
                                            <EuiText size='s'>In this step we bring attention to your hottest, most trending Awareness Solution.</EuiText>
                                            <EuiSpacer />
                                            <EuiFlexGroup>
                                                <EuiFlexItem grow={false}>
                                                    <EuiStat title={nurtureStepEightFields} description="Total Fields" reverse />
                                                </EuiFlexItem>
                                                <EuiFlexItem grow={false}>
                                                    <EuiStat title={nurtureStepEightFieldsRemaining} description="Fields Remaining" reverse titleColor={nurtureStepEightFieldsRemaining > 0 ? 'danger' : 'success'} />
                                                </EuiFlexItem>
                                                <EuiFlexItem grow={true} />
                                                <EuiFlexItem grow={false}>
                                                    <EuiButton isLoading={sendingTestEmail} onClick={() => onTestEmailClick(nurtureStep)}>Send Test Email</EuiButton>
                                                </EuiFlexItem>
                                            </EuiFlexGroup>
                                            <EuiHorizontalRule />
                                            <EuiText size='xs'>All fields are <strong>required. </strong><EuiLink external href='https://www.swiftrev.com/guides/nurture-step-8'>Need help filling out these fields?</EuiLink></EuiText>
                                            <EuiSpacer size='xxl' />
                                            <EuiFormRow label='Product Title' helpText='This should be the name of your most popular product or product type.' >
                                                <EuiFieldText
                                                    placeholder='e.g. Video Marketing'
                                                    value={nurtureSettings?.step8_ProductTitle || ''}
                                                    onChange={(e) => onChangeNurtureSettings({ step8_ProductTitle: e.target.value })}
                                                />
                                            </EuiFormRow>
                                            <EuiSpacer size='xxl' />
                                            <EuiText size='l'><strong>Subproducts</strong></EuiText>
                                            <EuiText size='xs'>For the product above you need at least one subproduct. <EuiLink external href='https://www.swiftrev.com/guides/nurture-step-8'>See examples.</EuiLink></EuiText>
                                            <EuiSpacer />
                                            <EuiButton onClick={() => { addNurtureSubproduct() }}>Add Subproduct</EuiButton>
                                            <EuiSpacer />
                                            {(nurtureSettings?.step8_Subproducts || []).map((subproduct, indx) => {
                                                return (
                                                    <div key={`indx-${indx}`} className='sr-alert-light' style={{ marginTop: "15px" }} >
                                                        <EuiFlexGroup alignItems='center'>
                                                            <EuiFlexItem>
                                                                <EuiText size='l'><strong>Subproduct {indx + 1}</strong></EuiText>
                                                            </EuiFlexItem>
                                                            <EuiFlexItem grow={false}>
                                                                {indx > 0 && <EuiButtonIcon aria-label='remove subproduct' iconType="cross" onClick={() => { removeNurtureSubproduct(indx) }} />}
                                                            </EuiFlexItem>
                                                        </EuiFlexGroup>
                                                        <EuiSpacer size='xl' />
                                                        <EuiFlexGroup>
                                                            <EuiFlexItem grow={7}>
                                                                <EuiFormRow fullWidth label='Subproduct Title' helpText='This should be the name of the subproduct, e.g. "Video Marketing".'>
                                                                    <EuiFieldText fullWidth value={subproduct.title} onChange={(e) => { onChangeStep8Subproducts(indx, 'title', e.target.value) }}></EuiFieldText>
                                                                </EuiFormRow>
                                                            </EuiFlexItem>
                                                            <EuiFlexItem grow={3}>
                                                                <EuiFormRow label='Subproduct Image'>
                                                                    <ImageLoader accountId={props.brand.accountId} brandId={props.brand.brandId} imageUrl={subproduct.imageUrl} onChange={(imageUrl) => { onChangeStep8Subproducts(indx, 'imageUrl', imageUrl) }} />
                                                                </EuiFormRow>
                                                            </EuiFlexItem>
                                                        </EuiFlexGroup>
                                                        <EuiSpacer />
                                                        <EuiFormRow fullWidth label='Subproduct Description'>
                                                            <ReactQuill style={{ backgroundColor: "white" }} theme="snow" value={subproduct.description} onChange={(e) => { onChangeStep8Subproducts(indx, 'description', e) }} />
                                                        </EuiFormRow>
                                                        <EuiFormRow fullWidth label='Subproduct Url' helpText='The subproduct action button will link to this url. If blank, the media kit url will be used.'>
                                                            <EuiFieldText fullWidth
                                                                value={subproduct.url || ''}
                                                                onChange={(e) => onChangeStep8Subproducts(indx, 'url', e.target.value)}
                                                            />
                                                        </EuiFormRow>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    }
                                </EuiFlexItem>
                                <EuiFlexItem grow={3}>
                                    <EuiImage alt={`Nurture Step ${nurtureStep}`} src={nurtureScreenshots[nurtureStep - 1]}></EuiImage>
                                </EuiFlexItem>
                            </EuiFlexGroup>
                        </EuiPanel>
                    </Fragment>
                }
            </EuiForm>
            {hasChanges && <EuiBottomBar>
                <EuiFlexGroup justifyContent="flexEnd">
                    <EuiFlexItem grow={false}>
                        <EuiButton disabled={updating} onClick={() => props.refetch()} color="ghost" size="s" iconType="save">
                            Discard Changes
                        </EuiButton>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                        <EuiButton isLoading={updating} color="primary" fill size="s" iconType="save"
                            onClick={() => { update() }}>
                            Save Changes
                        </EuiButton>
                    </EuiFlexItem>
                </EuiFlexGroup>
            </EuiBottomBar>}
        </Fragment>
    );
};