import { Fragment, useContext } from 'react';
import { useParams } from "react-router-dom";
import useAxios from 'axios-hooks';
import BrandDetails from './BrandDetails';
import AccountContext from './AccountContext';
import { EuiProgress, EuiPanel, EuiTitle, EuiSpacer } from '@elastic/eui';
export default function BrandContainer() {
    const params = useParams();
    const accountContext = useContext(AccountContext)
    const [{ data: brandResults, loading: brandLoading, error: brandError }, refetch] = useAxios(
        {
            url: '/data/brands',
            method: 'GET',
            params: {
                accountId: params.accountId,
                brandId: params.brandId
            }
        }
    );
    return (
        <EuiPanel borderRadius='none' paddingSize='none' hasShadow={false}>
            <EuiTitle size="m">
                <h1>{(accountContext?.account?.brands?.find(b => b.brandId === params.brandId))?.brandName}</h1>
            </EuiTitle>
            <EuiSpacer size="m" />
            <EuiProgress hidden={!brandLoading} size="xs" color="primary" />
            {!brandLoading && brandResults?.item &&
                <BrandDetails brand={brandResults?.item} refetch={refetch} />
            }
        </EuiPanel>
    );
}