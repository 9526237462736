import { Fragment } from 'react';
import { Route, Routes } from 'react-router-dom';
import Brands from './Brands';
import NotFound from './NotFound';
import BrandContainer from './BrandContainer';
export default function BrandsContainer() {
    return (
        <Fragment>
            <Routes>
                <Route index element={<Brands />} />
                <Route path=':brandId/*' element={<BrandContainer />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </Fragment>
    );
}