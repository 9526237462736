import React, { useState, useContext, Fragment } from 'react';
import { EuiButtonEmpty, useEuiTheme, EuiFlexGroup, EuiFlexItem } from '@elastic/eui';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useUser } from '@clerk/clerk-react';
import AccountContext from './AccountContext';
export default function SideNav() {
    const accountContext = useContext(AccountContext);
    let euiTheme = useEuiTheme().euiTheme;
    const userApi = useUser();
    let navigate = useNavigate();
    let location = useLocation();
    let params = useParams();
    const [isSideNavOpenOnMobile, setisSideNavOpenOnMobile] = useState(false);
    const toggleOpenOnMobile = () => {
        setisSideNavOpenOnMobile(!isSideNavOpenOnMobile);
    };
    const pathPrefix = `/accounts/${params.accountId}/`
    const pathPressReleases = `press-releases`
    const pathLeads = 'leads'
    const pathBrands = `brands`
    const pathSettings = `settings`
    const pathAnalytics = `analytics`
    const pathNurture = 'nurture'
    // Custom tab like button styles
    let unSelectedTabStyle = {
        "color": euiTheme.colors.text,
    }
    let selectedTabStyle = {
        "backgroundColor": euiTheme.colors.lightestShade,
        "fontWeight": "bold",
        "color": euiTheme.colors.link
    }

    const tmp = { style: { justifyContent: 'left' } }

    return (
        <Fragment>
            <EuiFlexGroup direction="column">
                <EuiFlexItem grow={false}>
                    <EuiButtonEmpty size='s' contentProps={tmp}
                        style={location.pathname.startsWith(pathPrefix + pathPressReleases) ? selectedTabStyle : unSelectedTabStyle}
                        onClick={() => { navigate(pathPressReleases) }}>
                        Press Releases
                    </EuiButtonEmpty>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <EuiButtonEmpty size='s' contentProps={tmp}
                        style={location.pathname.startsWith(pathPrefix + pathLeads) ? selectedTabStyle : unSelectedTabStyle}
                        onClick={() => { navigate(pathLeads) }}>
                        Leads
                    </EuiButtonEmpty>
                </EuiFlexItem>
                {(userApi?.user?.publicMetadata?.platformAdmin === 'yes' || accountContext?.account?.isAccountAdmin || accountContext?.account?.isAccountSales) &&
                    <React.Fragment>
                        <EuiFlexItem grow={false}>
                            <EuiButtonEmpty size='s' contentProps={tmp}
                                style={location.pathname.startsWith(pathPrefix + pathNurture) ? selectedTabStyle : unSelectedTabStyle}
                                onClick={() => { navigate(pathNurture) }}>
                                Nurture
                            </EuiButtonEmpty>
                        </EuiFlexItem>
                    </React.Fragment>
                }
                <EuiFlexItem grow={false}>
                    <EuiButtonEmpty size='s' contentProps={tmp}
                        style={location.pathname.startsWith(pathPrefix + pathBrands) ? selectedTabStyle : unSelectedTabStyle}
                        onClick={() => { navigate(pathBrands) }}>
                        Brands
                    </EuiButtonEmpty>
                </EuiFlexItem>
                {(userApi?.user?.publicMetadata?.platformAdmin === 'yes' || accountContext?.account?.isAccountAdmin) &&
                    <Fragment>
                        <EuiFlexItem grow={false}>
                            <EuiButtonEmpty size='s' contentProps={tmp}
                                style={location.pathname.startsWith(pathPrefix + pathAnalytics) ? selectedTabStyle : unSelectedTabStyle}
                                onClick={() => { navigate(pathAnalytics) }}>
                                Analytics
                            </EuiButtonEmpty>
                        </EuiFlexItem>
                        <EuiFlexItem grow={false}>
                            <EuiButtonEmpty size='s' contentProps={tmp}
                                style={location.pathname.startsWith(pathPrefix + pathSettings) ? selectedTabStyle : unSelectedTabStyle}
                                onClick={() => { navigate(pathSettings) }}>
                                Settings
                            </EuiButtonEmpty>
                        </EuiFlexItem>
                    </Fragment>
                }
            </EuiFlexGroup>
        </Fragment>
    );
};