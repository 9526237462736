import React, { Fragment } from 'react';
import {
    EuiBasicTable, EuiProgress,
    EuiFlexGroup, EuiFlexItem, EuiText
} from '@elastic/eui';

export default function CompanyInterestLevelDetails(props) {

    const scoreItems = [{
        title: "HandleIt Factor",
        value: props?.lead?.leadScoreDetails?.factorHandleIts,
    }, {
        title: "Contact Engagement Factor",
        value: props?.lead?.leadScoreDetails?.factorContacts,
    }, {
        title: "Engagement Recency Factor",
        value: props?.lead?.leadScoreDetails?.factorEngagement,
    }, {
        title: "Paid Press Release Factor",
        value: props?.lead?.leadScoreDetails?.factorSpend,
    }]

    return (
        <Fragment>
            <EuiFlexGroup>
                <EuiFlexItem grow={true} style={{ minWidth: 150 }}>
                    <EuiText style={{ height: "2.5rem" }} size='xs'><strong>Company Interest Level</strong></EuiText>
                    <div>
                        <div><EuiText><strong>{props?.lead?.companyInterestLevelTag}</strong></EuiText></div>
                        <EuiFlexGroup gutterSize="s" alignItems='center' style={{ marginRight: "15px" }}>
                            <EuiFlexItem grow={false}>
                                <EuiText>{(props?.lead?.companyInterestLevel || 0).toString() || '0'}</EuiText>
                            </EuiFlexItem>
                            <EuiFlexItem grow={true}>
                                <EuiProgress style={{ width: "100px" }} size='l' value={props?.lead?.companyInterestLevel || 0} max={100}></EuiProgress>
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    </div>
                </EuiFlexItem>
                {scoreItems.map(i => {
                    return (
                        <EuiFlexItem grow={true} key={i.title}>
                            <EuiText textAlign='center' style={{ height: "4rem" }} size='xs'><strong>{i.title}</strong></EuiText>
                            <EuiText textAlign='center'><strong>{i.value}</strong></EuiText>
                        </EuiFlexItem>
                    )
                })}
            </EuiFlexGroup>

        </Fragment>
    )
}